import  { FC, ReactNode  } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import './SuperResponsiveTableStyle.scss';

import './ResponsiveTable.scss';


export interface ResponsiveTableProps  {

    Head?: { title?: string, columns?: {[key: number]: string } };
    Rows?: { [key: string]: {
        title: string, 
        columns: {[key: number]: string }} };
    EmptyCellContent?: string;
  }


const ResponsiveTable: FC<ResponsiveTableProps> = ({ Head, Rows, EmptyCellContent=' ' }) => {
    if (!(Head?.title && Head?.columns && Rows && Object.keys(Rows).length)) return <></>;

    //console.log('ResponsiveTable ', Head?.title, Head, Rows);
  
    const columnLengths = [
      Head.columns ? Object.values(Head.columns).length : 0,
      ...(Rows
        ? Object.values(Rows).map(Row => {
            return Row.columns ? Object.values(Row.columns).length : 0;
          })
        : [0]),
    ];
    const numberOfColumns = Math.max(...columnLengths);

    return (
        <div className="responsive-table-section">
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: Head?.title,
                    }}
                  />
                </Th>
                {[...Array(numberOfColumns)].map((x, i) => (
                  <Th key={i}>{Head?.columns?.[i + 1] ?? EmptyCellContent}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {Object.values(Rows).map((Row, rowIndex) => (
                <Tr key={rowIndex}>
                  <Td>{Row?.title}</Td>
                  {[...Array(numberOfColumns)].map((x, i) => (
                    <Td key={i}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: Row?.columns?.[i + 1] ?? EmptyCellContent,
                        }}
                      />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      );
};

export default ResponsiveTable;
