import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'ecomponents/layout/Footer/Footer';
import HomePage from 'ecomponents/pages/HomePage/HomePage';

import Page404 from '_unused-components-delete/containers/Page404/Page404';
import SiteHeader from '_unused-components-delete/containers/SiteHeader';
import CheckOutPage from '_unused-components-delete/containers/CheckOutPage/CheckOutPage';
import PayPage from '_unused-components-delete/containers/PayPage/PayPage';
import AuthorPage from '_unused-components-delete/containers/AuthorPage/AuthorPage';
import AccountPage from '_unused-components-delete/containers/AccountPage/AccountPage';
import AccountPass from '_unused-components-delete/containers/AccountPage/AccountPass';
import AccountSavelists from '_unused-components-delete/containers/AccountPage/AccountSavelists';
import AccountBilling from '_unused-components-delete/containers/AccountPage/AccountBilling';
import PageContact from '_unused-components-delete/containers/PageContact/PageContact';
import PageAbout from '_unused-components-delete/containers/PageAbout/PageAbout';
import PageSignUp from '_unused-components-delete/containers/PageSignUp/PageSignUp';
import PageLogin from '_unused-components-delete/containers/PageLogin/PageLogin';

import HotelPage from 'ecomponents/pages/HotelPage/HotelPage';
import HotelPageRooms from 'ecomponents/pages/HotelPage/HotelPageRooms';
import HotelPageRoomDetails from 'ecomponents/pages/HotelPage/HotelPageRoomDetails';
import HotelPageFacilities from 'ecomponents/pages/HotelPage/HotelPageFacilities';
import HotelPagePrices from 'ecomponents/pages/HotelPage/HotelPagePrices';
import HotelPageLocation from 'ecomponents/pages/HotelPage/HotelPageLocation';
import HotelPageGallery from 'ecomponents/pages/HotelPage/HotelPageGallery';
import HotelPageReviews from 'ecomponents/pages/HotelPage/HotelPageReviews';
import HotelPageFootAndDining from 'ecomponents/pages/HotelPage/HotelPageFootAndDining';
import HotelPageFBusiness from 'ecomponents/pages/HotelPage/HotelPageFBusiness';
import HotelPageFRecreation from 'ecomponents/pages/HotelPage/HotelPageFRecreation';
import HotelPageFEntertainment from 'ecomponents/pages/HotelPage/HotelPageFEntertainment';
import HotelPageFNightLife from 'ecomponents/pages/HotelPage/HotelPageFNightLife';

import ResortPage from 'ecomponents/pages/ResortPage/ResortPage';
import ResortTransfersPage from 'ecomponents/pages/ResortPage/ResortTransfersPage';
import ResortGalleryPage from 'ecomponents/pages/ResortPage/ResortGalleryPage';
import ResortDealsPage from 'ecomponents/pages/ResortPage/ResortDealsPage';
import ResortHotelsPage from 'ecomponents/pages/ResortPage/ResortHotelsPage';
import ResortAllInclusivePage from 'ecomponents/pages/ResortPage/ResortAllInclusivePage';
import ResortApartmentsPage from 'ecomponents/pages/ResortPage/ResortApartmentsPage';
import ResortEarlyBookingPage from 'ecomponents/pages/ResortPage/ResortEarlyBookingPage';
import ResortLastMinutePage from 'ecomponents/pages/ResortPage/ResortLastMinutePage';

export const pages: Page[] = [
  { path: '/', exact: true, component: HomePage },
  { path: '/#', exact: true, component: HomePage },
  { path: '/home-1-header-2', exact: true, component: HomePage },
  //

  { path: '/hotel-page', component: HotelPage },
  { path: '/hotel-page-rooms', component: HotelPageRooms },
  { path: '/hotel-page-rooms-room', component: HotelPageRoomDetails },
  { path: '/hotel-page-facilities', component: HotelPageFacilities },
  { path: '/hotel-page-location', component: HotelPageLocation },
  { path: '/hotel-page-prices', component: HotelPagePrices },
  { path: '/hotel-page-reviews', component: HotelPageReviews },
  { path: '/hotel-page-photos', component: HotelPageGallery },
  { path: '/hotel-page-facilities-food-dining', component: HotelPageFootAndDining },
  { path: '/hotel-page-facilities-business', component: HotelPageFBusiness },
  { path: '/hotel-page-facilities-recreation', component: HotelPageFRecreation },
  { path: '/hotel-page-facilities-entertainment', component: HotelPageFEntertainment },
  { path: '/hotel-page-facilities-night-life', component: HotelPageFNightLife },
  //

  { path: '/resort-page', component: ResortPage },
  { path: '/resort-page-transfers', component: ResortTransfersPage },
  { path: '/resort-page-deals', component: ResortDealsPage },
  { path: '/resort-page-hotels', component: ResortHotelsPage },
  { path: '/resort-page-all-inclusive', component: ResortAllInclusivePage },
  { path: '/resort-page-apartments', component: ResortApartmentsPage },
  { path: '/resort-page-early-booking', component: ResortEarlyBookingPage },
  { path: '/resort-page-last-minute', component: ResortLastMinutePage },
  { path: '/resort-gallery', component: ResortGalleryPage },

  { path: '/resort-:resortUrl/hotel-:hotelUrl.html', component: HotelPage },

  //
  { path: '/checkout', component: CheckOutPage },
  { path: '/pay-done', component: PayPage },
  //
  { path: '/author', component: AuthorPage },
  { path: '/account', component: AccountPage },
  { path: '/account-password', component: AccountPass },
  { path: '/account-savelists', component: AccountSavelists },
  { path: '/account-billing', component: AccountBilling },

  //
  { path: '/contact', component: PageContact },
  { path: '/about', component: PageAbout },
  { path: '/signup', component: PageSignUp },
  { path: '/login', component: PageLogin },

  //
];

const Routes = () => {
  return (
    <BrowserRouter basename="/bghotels">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return <Route key={path} component={component} exact={!!exact} path={path} />;
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
