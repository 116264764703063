import { FC, ReactNode } from 'react';
import useWindowSize from 'hooks/useWindowResize';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { Link } from 'react-router-dom';

export interface RightSectionProps {
  className?: string;
  children?: ReactNode;
}

const RightSection: FC<RightSectionProps> = ({ className = '', children }) => {
  const windowSize = useWindowSize();
  const translationWords = ['FreeReservationCancellation'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  return (
    <div className="flex justify-center">
      <div className="flex flex-col  w-full sm:w-[400px] block mt-14 lg:mt-0">
        <div>
          <span className="block flex  flex-col items-end text-right ">
            <Link
              to={'/resort-page-transfers'}
              className=" nc-Button relative h-auto w-full inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  !leading-none  text-blue-800 bg-blue-100"
            >
              <span>Bansko Transfers</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </Link>
            <Link
              to={'/resort-page-ski-packs'}
              className="mt-3 nc-Button relative h-auto w-full inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  !leading-none  text-blue-800 bg-blue-100"
            >
              <span>Bansko Ski Packages</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </Link>
            <Link
              to={'/resort-page-hotels'}
              className="mt-3 nc-Button relative h-auto w-full inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  !leading-none  text-blue-800 bg-blue-100"
            >
              <span>Bansko All Hotels</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </Link>
          </span>
          <br />
        </div>

        <div className="sticky top-24">
          {children}
          <br />
          <div className="listingSection__wrap font-semibold bg-neutral-100 first-line:text-red-600">
            <p className="p-2">{translations?.FreeReservationCancellation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSection;
