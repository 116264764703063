import React, { FC, ReactNode, useState } from 'react';
import { DEMO_HOTEL_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import ButtonPrimary from 'ecomponents/ui/Button/ButtonPrimary';
import HeaderFilter from './HeaderFilter';
import StayCardNoUse from 'ecomponents/common/StayCardNoUse/StayCardNoUse';

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_HOTEL_LISTINGS.filter((_, i) => i < 100);

//
export interface SectionGridFeaturedHotelsProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  activeTab?: string;
}

const SectionGridFeaturedHotels: FC<SectionGridFeaturedHotelsProps> = ({
  stayListings = DEMO_DATA,
  gridClass = '',
  heading = 'Featured places to stay',
  subHeading = 'Popular places to stay that Chisfis recommends for you',
  headingIsCenter,
  tabs = ['Bansko', 'Borovets', 'Pamporovo', 'Chepelare'],
  activeTab = 'Bansko',
}) => {
  const [tabActive, setTabActive] = useState<string>(activeTab);

  const renderCard = (stay: StayDataType) => {
    return <StayCardNoUse key={stay.id} data={stay} />;
  };

  const filterByResort = (stayListings: StayDataType[], selectedResort: string) => {
    return stayListings.filter(stayListing => stayListing.resort === selectedResort);
  };

  const onClickTab = (item: string) => {
    setTabActive(item);
  };

  return (
    <div className="nc-SectionGridFeaturedHotels relative">
      <HeaderFilter tabActive={tabActive} tabs={tabs} heading={heading} onClickTab={onClickTab} />

      <div className={`grid gap-6 md:gap-8 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
        {filterByResort(DEMO_DATA, tabActive).map(stay => renderCard(stay))}
      </div>
    </div>
  );
};

export default SectionGridFeaturedHotels;
