import React, { FC, Fragment, useState, useEffect } from 'react';
import NcImage from 'ecomponents/ui/NcImage/NcImage';
import ModalPhotos from '../ModalPhotos';

interface hotelPhotosProps {
  hotelPhotos?: object;
  glalleryGridMode?: boolean;
  className?: string;
}

const GallerySection: FC<hotelPhotosProps> = ({ hotelPhotos, glalleryGridMode, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [numberofPictures, setnumberofPictures] = useState(5);

  useEffect(() => {
    if (glalleryGridMode) {
      setnumberofPictures(24);
    }
  }, []);
  console.log('numberofPictures', numberofPictures);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);
  console.log('hotel photos props', hotelPhotos);
  let myPropArr = hotelPhotos;
  let result = [];
  if (myPropArr instanceof Array) {
    result = myPropArr.map(a => a.url);
  }
  console.log('result.length', result.length);
  if (result.length)
    return (
      <div className={className}>
        <header className=" rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1 sm:gap-2">
            <div
              className={`${
                glalleryGridMode ? 'col-span-1 row-span-1 sm:row-span-1' : 'col-span-2 row-span-3 sm:row-span-2'
              }  relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer`}
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={result[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {result
              .filter((_, i) => i >= 1 && i < numberofPictures)
              .map((item, index) => (
                <div key={index} className={`relative rounded-md sm:rounded-xl overflow-hidden `}>
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item || ''}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            {glalleryGridMode ? (
              <></>
            ) : (
              <div
                className={`${
                  glalleryGridMode ? '' : 'absolute hidden md:flex md:items-center md:justify-center'
                }   left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10`}
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">Show all photos</span>
              </div>
            )}
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={result}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-HotelPage-modalPhotos"
        />
      </div>
    );
};

export default GallerySection;
