import { StarIcon } from '@heroicons/react/solid';
import { FaStop } from 'react-icons/fa';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
export interface IconForRatingProps {
  className?: string;
  iconClass?: string;
  defaultPoint?: number;
  reviewsNumber?: number;
  boldClass?: string;
  readOnly?: boolean;
  viewText?: boolean;
}

const IconForRating: FC<IconForRatingProps> = ({
  className = '',
  iconClass = 'w-4 h-4',
  defaultPoint,
  reviewsNumber,
  boldClass = '',
  readOnly = false,
  viewText = true,
}) => {
  const [point, setPoint] = useState(defaultPoint);
  const [currentHover, setCurrentHover] = useState(0);

  useEffect(() => {
    setPoint(defaultPoint);
    console.log('raring points:', point);
  }, [defaultPoint]);

  const translationWords = ['VarComments', 'VarBasedOn'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  return (
    <>
      <div
        className={`nc-FiveStartIconForRate flex items-center text-neutral-300 ${className}`}
        data-nc-id="FiveStartIconForRate"
      >
        {[1, 2, 3, 4, 5].map(item => {
          return readOnly ? (
            <FaStop
              key={item}
              className={`${
                Math.round(point) >= item ? `${className}` : 'text-neutral-300'
              } ${iconClass} pointer-events-none`}
            />
          ) : (
            <FaStop
              key={item}
              className={`${
                Math.round(point) >= item || currentHover >= item ? `${className}` : 'text-neutral-300'
              } ${iconClass} `}
              onMouseEnter={() => setCurrentHover(() => item)}
              onMouseLeave={() => setCurrentHover(() => 0)}
              onClick={() => setPoint(() => item)}
              title="fullshit"
            />
          );
        })}
      </div>

      <div className="leading-4">
        <span className={`leading-4 inline-block text-base pl-1 ${boldClass}`}>
          {point} {viewText ? 'out of 5' : ''}
        </span>
        {reviewsNumber ? (
          <span className="leading-4 inline-block text-base">
            &nbsp;{translations?.VarBasedOn} {reviewsNumber}{' '}
            <span className="lowercase">{translations?.VarComments}</span>
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default IconForRating;
