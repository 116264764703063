import SectionHero from "ecomponents/partials/SectionHero/SectionHero";
import SectionSubscribe from "ecomponents/partials/SectionSubscribe/SectionSubscribe";
import SectionGridFeaturedHotels from "_unused-components-delete/containers/PageHome/SectionGridFeaturedHotels";
import BackgroundSection from "ecomponents/common/BackgroundSection/BackgroundSection";
import BackgroundGlassmorphism from "ecomponents/common/BackgroundGlassmorphism/BackgroundGlassmorphism";
import SectionGridResortsBox from "ecomponents/partials/SectionGridResortsBox/SectionGridResortsBox";
import SectionAbout from "ecomponents/pages/HomePage/SectionAbout";
import SectionBanners from "ecomponents/pages/HomePage/SectionBanners"
import SectionWebcams2Main from "ecomponents/partials/SectionWebcams2Main/SectionWebcams2Main"
import SectionQuickFacts from "ecomponents/partials/SectionQuickFacts/SectionQuickFacts";
import { Helmet } from "react-helmet";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden px-3  md:px-5  lg:px-7 ">
      <Helmet>
        <title>Bulgaria-Hotels.Com | Home</title>
      </Helmet>
      {/* GLASSMORPHIN */}
      <BackgroundGlassmorphism className="md:top-10 xl:top-30"/>

      <div className="container relative  space-y-5 md:space-y-16 lg:space-y-16 xl:space-y-16 ">
        {/* hero section */}
        <SectionHero className="pt-6 lg:pt-12 pb-1" />

        {/* SECTION BANNERS HOMEPAGE*/}
        <SectionBanners /> 
        {/* END SECTION  BANNERS HOMEPAGE*/}

        {/* SECTION HOTELS OFFERS*/}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturedHotels />
        </div>

        {/* SECTION WEBCAMS */}
        <SectionWebcams2Main title="Bulgaria ski webcams" subtitle="Live webcams in Bansko, Borovets, Pamporovo, Chepelare" />

        {/* SECTION RESORTS new*/}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridResortsBox />
        </div>

        {/* SECTION ABOUT */}
          <SectionAbout />
        {/* SECTION QUICK FACTS */}
          <SectionQuickFacts />    
        



      




       

       
      </div>
    </div>
  );
}

export default PageHome;
