import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { GlobeAltIcon } from '@heroicons/react/outline';
import { AppLanguages, AppFlags } from 'const';
import { Fragment } from 'react';
import { useLanguageStore } from 'store/languageStore/useLanguageStore';

export default function LangDropdown() {
  // const [activeLanguage, setLanguage] = useState('en');
  //  [languageIcon, setLanguageIcon] = useState(AppFlags['en']);

  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const { setLanguage } = useLanguageStore();

  return (
    <div className="LangDropdown">
      <Popover className="relative">
        {({ open, close }) => {
          const clickLangBtn = (lang: string) => {
            setLanguage(lang);
            // setLanguageIcon(AppFlags[lang]);
            close();
          };

          return (
            <>
              <Popover.Button
                className={`
                ${open ? '' : 'text-opacity-80'}
             group p-3.5 bg-primary-6000  border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <img src={AppFlags[activeLanguage]} className={`h-3  mr-1`} />
                <span className="ml-2 text-white">{AppLanguages[activeLanguage]}</span>
                <ChevronDownIcon
                  className={`${open ? '-rotate-180' : 'text-opacity-70'} text-white
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-50 w-screen max-w-[200px] px-4 mt-3 right-0 sm:px-0">
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {Object.entries(AppLanguages).map(([languageCode, languageTitle], index) => (
                        <a
                          key={index}
                          href={'#'}
                          onClick={() => clickLangBtn(languageCode)}
                          className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                            languageCode === activeLanguage ? 'bg-gray-100 dark:bg-neutral-700' : 'opacity-80'
                          }`}
                        >
                          <div className={`flex`}>
                            <img src={AppFlags[languageCode]} className={`h-3 mt-1 mr-2`} />
                            <p className="text-sm font-medium ">{languageTitle}</p>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </div>
  );
}
