import React, { FC } from 'react';
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import { IoMdCheckmark } from 'react-icons/io';
import { FacilityModel } from 'features/hotels/facility';
import { Link } from 'react-router-dom';

export interface FacilityListProps {
  className?: string;
  facilities?: FacilityModel[];
  caption?: string;
  url?: string;
}
const FacilityList: FC<FacilityListProps> = ({ className = '', facilities = [], caption, url }) => {
  console.log('caption', caption);
  return (
    <>
      {facilities?.length ? (
        <>
          {caption ? (
            <h2 className="text-2xl font-semibold mb-4">{url ? <Link to={url}>{caption}</Link> : <>{caption}</>}</h2>
          ) : (
            ''
          )}

          {facilities?.map(f => (
            <p className="facility-list" key={f.id}>
              <IoMdCheckmark className="inline" />
              &nbsp;
              <b className="capitalize">
                {uppercaseFirstLetter(f.caption)}
                {f.wherein_caption ? <>, {f.wherein_caption}</> : ''}
              </b>
              {f.type_caption ? <>, {f.type_caption}</> : ''}
              {f.details ? <>, {f.details}</> : ''}
            </p>
          ))}
          <br />
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default FacilityList;
