import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchVillageSelective from 'features/villages/hooks/useFetchVillageSelective';
import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';

const ResortInfoSection = () => {
  const [className, setClassName] = useState('');

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="container relative ">
      <BackgroundSection />
      <br />
      <br />
      <h2 className="text-4xl font-semibold relative z-10">Bansko info</h2>
      <span className="inline-block text-neutral-500  dark:text-neutral-400 mt-3 relative z-10">
        <Link className="inline-block hover:text-primary-6000" to="/resort-page-photos">
          <i className="las la-image text-xl" />
          &nbsp;<span className="underline">45 photos</span>
        </Link>
        &nbsp;&nbsp;•&nbsp;&nbsp;
        <Link className="inline-block hover:text-primary-6000" to="/resort-page-videos">
          <i className="las la-play-circle text-xl" />
          &nbsp;
          <span className="underline">videos</span>
        </Link>
      </span>
      <br />
      <br />

      <div className="read-more flex items-center relative z-10">
        <div className={isReadMore ? 'not-expanded-text w-[calc(100%-130px)]' : 'expanded-text w-[calc(100%-130px)]'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id velit et urna accumsan euismod. Donec sed
          feugiat enim. Aenean euismod dapibus lectus et ultricies. Fusce varius aliquet ultricies. Sed eu tincidunt
          sem. Curabitur finibus lacinia consectetur. Vestibulum accumsan lacinia metus, congue condimentum risus
          lacinia efficitur. Sed sit amet efficitur tellus. Nam ac neque in ligula efficitur cursus. Curabitur aliquam
          eu nisl sed consectetur. Ut posuere varius orci eget consectetur. Sed lacinia orci mauris, quis semper ipsum
          sollicitudin nec. Nam convallis vestibulum erat, vitae vulputate urna ullamcorper et. Pellentesque sed dolor
          mollis, rhoncus eros id, dignissim risus. Nam congue metus arcu, vitae consectetur arcu elementum et. Nullam
          egestas sapien at massa eleifend, ac placerat sapien finibus. Nullam vitae imperdiet elit. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer vestibulum erat nibh, in hendrerit
          purus consequat ut. Nunc placerat, quam in posuere eleifend, augue odio interdum nibh, a feugiat erat orci non
          tellus. Nullam tristique mauris in eros sagittis euismod. Ut rhoncus, ipsum sed luctus lobortis, velit libero
          ultricies turpis, ac scelerisque mauris turpis in felis. Suspendisse bibendum sapien nunc, a volutpat sem
          gravida at. Cras non commodo neque. Nullam arcu eros, condimentum quis tellus porta, consequat rhoncus turpis.
        </div>
        <p className="read-more-text grow ">
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? (
              <span className="block read-more-button">
                <button
                  className={
                    className === 'redbutton'
                      ? 'my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4'
                      : 'mt-2 inline-flex items-center justify-center rounded-lg py-0.5 px-1.5  text-sm   bg-primary-6000  text-neutral-50'
                  }
                >
                  Read More
                </button>
              </span>
            ) : (
              <span className="block  read-more-button">
                <button
                  className={
                    className === 'redbutton'
                      ? 'my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4'
                      : 'mt-2 inline-flex items-center justify-center rounded-lg py-0.5 px-1.5  text-sm   bg-primary-6000  text-neutral-50'
                  }
                >
                  Read Less
                </button>
              </span>
            )}
          </span>
        </p>
      </div>

      <br />
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6 md:gap-8">
        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Places to go</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">72 places in Bansko</span>
          </div>
        </a>
        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Restaurants & bars</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
              43 restaurants & bars in Bansko
            </span>
          </div>
        </a>

        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Bansko News</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">112 news</span>
          </div>
        </a>
        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Visitor reviews</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">87 visitor reviews</span>
          </div>
        </a>
        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Village Map</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">38 suggestions</span>
          </div>
        </a>
        <a
          className="nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] "
          data-nc-id="CardCategoryBox1"
          href="/bghotels/resort-page"
        >
          <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
              <img
                src="https://misha.krusharski.com/bhpics/bansko.jpg"
                className="object-cover w-full h-full"
                alt="nc-imgs"
              />
            </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium !leading-5">
              <span className="">Car Rental</span>
            </h2>
            <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">52 suggestions</span>
          </div>
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ResortInfoSection;
