import Badge from 'ecomponents/ui/Badge/Badge';
import LikeSaveBtns from '../LikeSaveBtns';
import { FaSkiing } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import StarRating from 'ecomponents/ui/StarRating/StarRating';
import IconForRating from 'ecomponents/common/IconForRating/IconForRating';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import { StarIcon } from '@heroicons/react/solid';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { extractFacility } from 'utils';
import { Distance } from 'const';

interface hotelProps {
  hotelName?: string;
  resort?: string;
  rating?: number;
  facilities?: object;
  ratingNumrates?: number;
}

const HotelNameSection: React.FC<hotelProps> = (props: hotelProps) => {
  const translationWords = ['FreeReservationCancellation', 'VarOutOf', 'VarBasedOn', 'VarComments'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['reviews_data', 'rating_data', 'rating_general', 'rating_numrates', 'general', 'location'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);

  const mainDistance = extractFacility(props.facilities, Distance.SKI_LIFT);

  console.log('distance to ski lift:', mainDistance);

  return (
    <div className="listingSection__wrap2 !space-y-4 !pb-4">
      {/* 1 */}
      <div className="flex justify-between items-center">
        <Badge name="top hotel" />
        <LikeSaveBtns />
      </div>

      {/* 2 */}
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">{props.hotelName}</h2>

      {/* 3 */}
      <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-4">
        <span>
          <i className="las la-map-marker-alt text-xl text-blue-800"></i>
          <span className="ml-1">{props.resort}</span>
        </span>
        <span className="hidden sm:block">·</span>
        <div className={`nc-StarRating flex items-center space-x-1 text-sm`} data-nc-id="StarRating">
          <IconForRating
            readOnly={true}
            iconClass="w-5 h-5"
            boldClass="font-bold"
            className="space-x-0.2  text-yellow-500"
            defaultPoint={hotel?.rating_general}
            reviewsNumber={hotel?.rating_numrates}
          />
        </div>
      </div>

      {/* 4 */}
      <div className="flex ">
        {mainDistance ? (
          <>
            <FaSkiing className="pt-1 text-2xl text-blue-800" />
            <span className="ml-1">
              {' '}
              {mainDistance.caption}: {mainDistance.details}
            </span>
          </>
        ) : (
          ''
        )}
      </div>

      <div className="border-t border-neutral-100 pt-4 ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400 ">
        <div className="flex items-center flex-col sm:flex-row">
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: hotel?.general,
            }}
          />{' '}
          <Link
            className="mt-4 sm:mt-0 text-sm py-1 px-3 text-white bg-primary-6000 font-semibold cursor-pointer rounded-full min-w-max h-[30px] flex items-center"
            to={'/hotel-page-facilities'}
          >
            Hotel Info
          </Link>
        </div>
        <br />
        <div className="flex items-center flex-col sm:flex-row">
          <p
            className=""
            dangerouslySetInnerHTML={{
              __html: hotel?.location,
            }}
          />

          <Link
            className="mt-4 sm:mt-0  text-sm py-1 px-3 text-white bg-primary-6000 font-semibold cursor-pointer rounded-full min-w-max h-[30px] flex items-center"
            to={'/hotel-page-location'}
          >
            Hotel Location
          </Link>
        </div>
      </div>

      {/* 5 */}
      <div className="w-full " />
    </div>
  );
};

export default HotelNameSection;
