import { FC, ReactNode, useState, useEffect } from 'react';
import HeroSearchForm, { SearchTab } from 'ecomponents/forms/HeroSearchForm/HeroSearchForm';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';

import { DealToHotelListing } from 'data/dataTypes/adapters/DealToHotelListing';
import useFetchVillageSelective from 'features/villages/hooks/useFetchVillageSelective';
import HotelListGridCards from 'ecomponents/common/HotelListGridCards/HotelListGridCards';
import ebNanoId from 'utils/ebNanoId';

import { dealGroup } from 'utils/datahelpers/dealGroup';
import { Deal } from 'const';

export interface ResortLastMinutePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: 'Hotels' | 'Ski Packages' | 'Transfers' | 'Flights';
  currentTab: SearchTab;
  rightImage?: string;
}

const ResortLastMinutePage: FC<ResortLastMinutePageProps> = ({
  className = '',
  listingType,
  currentPage,
  currentTab,
}) => {
  const translationWords = ['VarTransfer'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['name'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);

  const villageFields = ['hotels_data', 'deals_data', 'url'];
  const { data: village, status: villageStatus } = useFetchVillageSelective(7, villageFields);

  /*
  const dealsForListing = village?.deals_data
    ? Object.values(village.deals_data)
        .filter((_, i) => i < 12)
        .map(hotel => DealToHotelListing(hotel))
    : [];
    */

  const dealsForListing = village?.deals_data
    ? dealGroup(Object.values(village.deals_data), Deal.DealGroup.DEAL_LAST_MINUTE).map(hotel =>
        DealToHotelListing(hotel),
      )
    : [];

  return (
    <div className={`nc-ListingStayPage relative ${className}`} data-nc-id="ListingStayMapPage">
      {/*<BackgroundGlassmorphism className="md:top-[-10rem] xl:top-[-10rem]"/>*/}
      <div className=" px-3  md:px-5  lg:px-7">
        <div className="container">
          {/* SECTION HERO */}

          <div className="flow-root w-full">
            <div className="z-10  w-full">
              <HeroSearchForm currentPage={currentPage} currentTab={currentTab} className="nomarginright" />
            </div>
            <br />
          </div>
        </div>
      </div>
      <br />
      <br />
      {/* SECTION */}
      <div className="container">
        <HotelListGridCards
          className="pb-24 lg:pb-32"
          headingData={{
            heading: {
              caption: 'Pamporovo Last Minutes',
              toUrl: '/resort-page-deals',
            },
            links: [
              {
                id: ebNanoId(),
                caption: 'Deals & discounts',
                toUrl: '/resort-page-deals',
              },
              {
                id: ebNanoId(),
                caption: 'Hotels',
                toUrl: '/resort-page-hotels',
              },
              {
                id: ebNanoId(),
                caption: 'All Inclusive',
                toUrl: '/resort-page-all-inclusive',
              },
              {
                id: ebNanoId(),
                caption: 'Apartments',
                toUrl: '/resort-page-apartments',
              },
              {
                id: ebNanoId(),
                caption: 'Early booking',
                toUrl: '/resort-page-early-booking',
              },
              {
                id: ebNanoId(),
                caption: 'Last minute',
                toUrl: '/resort-page-last-minute',
              },
              {
                id: ebNanoId(),
                caption: 'Resort Gallery',
                toUrl: '/resort-gallery',
              },
            ],
            accentLinks: [
              {
                id: ebNanoId(),
                caption: 'Ski packages',
                toUrl: '/resort-page-ski-packs',
              },
              {
                id: ebNanoId(),
                caption: 'Transfers',
                toUrl: '/resort-page-transfers',
              },
            ],
          }}
          hotelsData={dealsForListing}
        />
      </div>

      <br />
      <br />
    </div>
  );
};

export default ResortLastMinutePage;
