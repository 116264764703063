import { useMemo } from "react";
import useFetchVillage from "./useFetchVillage";
import { useLanguageStore } from "store/languageStore/useLanguageStore";
import { pickFromObject } from "utils";

/**
 * Helper hook that loads only specific fields from village data
 * so that we don't store entire village object in every component's state
 * @returns something
 */
export default function useFetchVillageSelective(id: number = -1, selectedFields = []) {
  const activeLanguage = useLanguageStore((state) => state.language.activeLanguage);
  const { data, status } = useFetchVillage(id);

  // useMemo to avoid infinite recreation of village object
  const dataPicked = useMemo(() => {
    return pickFromObject(data, selectedFields);
  }, [status, activeLanguage]);

  return { data: dataPicked, status };
}
