const imgs = [
    'https://misha.krusharski.com/bhpics/ski-season-banner.jpg',
    'https://misha.krusharski.com/bhpics/ski-packs.jpg',
    'https://misha.krusharski.com/bhpics/baner-ski-hotels.jpg',
    'https://misha.krusharski.com/bhpics/ski-appartments.jpg',
    'https://misha.krusharski.com/bhpics/summer-banner.jpg',
    'https://misha.krusharski.com/bhpics/yastrebets.jpg',
];

function SectionBanners() {
    return (
        <div  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 pb-15  mb-20 ">
    <div className="nc-Card8 group relative [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] overflow-hidden z-0 sm:col-span-2  md:-mt-12 lg:-mt-0 " data-nc-id="Card8">

        <a className="block w-full h-0 pt-[100%] sm:pt-[55%] rounded-xl overflow-hidden" href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage"><img src={imgs[0]} className="object-cover w-full h-full" alt=""/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-5 right-5 " data-nc-id="PostTypeFeaturedIcon">
                <span className=" rounded-full flex  items-center justify-center text-md bg-red-500 text-red-50  w-14 h-14">
                    -<b className=" text-xl  font-bold">20</b>%
                    </span>
                 </div>
            
            <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
        </a>
        
        <a className="absolute inset-x-0 bottom-0 top-1/3 bg-gradient-to-t from-[#04103b] opacity-60" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6 flex flex-col">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-yellow-800 bg-yellow-100 hover:bg-yellow-800" href="/ncmaz/archive/the-demo-archive-slug">Ski season 2022/23</a></div>
            <h2 className="mt-3 relative block font-semibold text-neutral-50 text-2xl sm:text-4xl"><a className="line-clamp-3" title="" href="/ncmaz/single/this-is-single-slug">Ski early bookings -20%</a></h2>
            <div className="hidden sm:block mt-2"><span className="text-neutral-300 text-sm line-clamp-1">Book early in Bansko, Borovets & Pamporovo and get up to -20% off</span></div>
        </div>
    </div>
    <div className="nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0  h-full  " data-nc-id="Card9">
        
        <div className="flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4"></div>
        <a href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0 rounded-3xl" data-nc-id="NcImage"><img src={imgs[1]} className="object-cover w-full h-full rounded-3xl" alt="nc-imgs"/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-5 right-5 " data-nc-id="PostTypeFeaturedIcon">
                <span className=" rounded-full flex  items-center justify-center text-md bg-primary-6000  text-white  w-14 h-14">
                    -<b className=" text-xl  font-bold">10</b>%
                    </span>
                 </div>
            <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
        </a>
        <a className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-[#04103b] opacity-50" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="mb-3">
                <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Ski Season 2023</a></div>
            </div>
            <div className="inline-flex items-center text-xs text-neutral-300">
                <div className="block ">
                    <h2 className="block text-2xl leading-6 font-semibold text-white "><span className="line-clamp-2" title="">Ski Packages -10% online</span></h2>
                    <a className="flex mt-2.5 relative" href="/ncmaz/author/the-demo-author-slug"><span className="block text-neutral-200 hover:text-white font-medium truncate">Save money and time and booking online</span></a>
                </div>
            </div>
        </div>
    </div>
    <div className="nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0  h-full" data-nc-id="Card9">
        
        <div className="flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4"></div>
        <a href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0 rounded-3xl" data-nc-id="NcImage"><img src={imgs[2]} className="object-cover w-full h-full rounded-3xl" alt="nc-imgs"/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-3 left-3 group-hover:hidden" data-nc-id="PostTypeFeaturedIcon"></div><span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span></a>
        <a className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-[#04103b] opacity-50" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="mb-3">
                <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Ski Season 2023</a></div>
            </div>
            <div className="inline-flex items-center text-xs text-neutral-300">
                <div className="block ">
                    <h2 className="block text-2xl leading-6 font-semibold text-white "><span className="line-clamp-2" title="">Recomended Ski Hotels</span></h2>
                    <a className="flex mt-2.5 relative" href="/ncmaz/author/the-demo-author-slug"><span className="block text-neutral-200 hover:text-white font-medium truncate">A curated list of hotels for your holiday</span></a>
                </div>
            </div>
        </div>
    </div>
    <div className="nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0  h-full" data-nc-id="Card9">
        
        <div className="flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4"></div>
        <a href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0 rounded-3xl" data-nc-id="NcImage"><img src={imgs[3]} className="object-cover w-full h-full rounded-3xl" alt="nc-imgs"/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-5 right-5 " data-nc-id="PostTypeFeaturedIcon">
                <span className=" rounded-full flex flex-col items-center justify-center text-md bg-red-500 text-red-50  w-14 h-14">
                    <b className=" text-xl leading-4 font-bold pt-2">30€</b><span className=" text-xs leading-4">day</span>
                    </span>
                 </div>
            <div className="nc-PostTypeFeaturedIcon absolute top-3 left-3 group-hover:hidden" data-nc-id="PostTypeFeaturedIcon"></div><span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span></a>
        <a className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-[#04103b] opacity-50" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="mb-3">
                <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Ski Season 2023</a></div>
            </div>
            <div className="inline-flex items-center text-xs text-neutral-300">
                <div className="block ">
                    <h2 className="block text-2xl leading-6 font-semibold text-white "><span className="line-clamp-2" title="">Ski apartments to rent</span></h2>
                    <a className="flex mt-2.5 relative" href="/ncmaz/author/the-demo-author-slug"><span className="block text-neutral-200 hover:text-white font-medium truncate">Appartments with fully equipped kitchen</span></a>
                </div>
            </div>
        </div>
    </div>
    <div className="nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0  h-full" data-nc-id="Card9">
        
        <div className="flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4"></div>
        <a href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0 rounded-3xl" data-nc-id="NcImage"><img src={imgs[5]} className="object-cover w-full h-full rounded-3xl" alt="nc-imgs"/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-5 right-5 " data-nc-id="PostTypeFeaturedIcon">
                <span className=" rounded-full flex flex-col items-center justify-center text-md bg-red-500 text-red-50  w-14 h-14">
                <span className=" text-xs leading-4">from</span><b className=" text-xl leading-4 font-bold">148€</b>
                    </span>
                 </div>
            <div className="nc-PostTypeFeaturedIcon absolute top-3 left-3 group-hover:hidden" data-nc-id="PostTypeFeaturedIcon"></div><span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span></a>
        <a className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-[#04103b] opacity-50" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="mb-3">
                <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Holidays</a></div>
            </div>
            <div className="inline-flex items-center text-xs text-neutral-300">
                <div className="block ">
                    <h2 className="block text-2xl leading-6 font-semibold text-white "><span className="line-clamp-2" title="">New Year 2023</span></h2>
                    <a className="flex mt-2.5 relative" href="/ncmaz/author/the-demo-author-slug"><span className="block text-neutral-200 hover:text-white font-medium truncate">100+ offers for New Year</span></a>
                </div>
            </div>
        </div>
    </div>
    <div className="nc-Card8 group relative [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] overflow-hidden z-0 sm:col-span-2" data-nc-id="Card8">

        <a className="block w-full h-0 pt-[100%] sm:pt-[55%] rounded-xl overflow-hidden" href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage"><img src={imgs[4]} className="object-cover w-full h-full" alt=""/></div>
            <div className="nc-PostTypeFeaturedIcon absolute top-5 right-5 " data-nc-id="PostTypeFeaturedIcon">
                <span className=" rounded-full flex  items-center justify-center text-md bg-red-500 text-red-50  w-14 h-14">
                    -<b className=" text-xl  font-bold">35</b>%
                    </span>
                 </div>
            
            <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
        </a>
        <a className="absolute inset-x-0 bottom-0 top-1/3 bg-gradient-to-t from-[#04103b] opacity-60" href="/ncmaz/single/this-is-single-slug"></a>
        <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6 flex flex-col">
            <a className="absolute inset-0" href="/ncmaz/single/this-is-single-slug"></a>
            <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-yellow-800 bg-yellow-100 hover:bg-yellow-800" href="/ncmaz/archive/the-demo-archive-slug">Ski season 2022/23</a></div>
            <h2 className="mt-3 relative block font-semibold text-neutral-50 text-2xl sm:text-4xl"><a className="line-clamp-3" title="" href="/ncmaz/single/this-is-single-slug">Beach early booking summer 2023</a></h2>
            <div className="hidden sm:block mt-2"><span className="text-neutral-300 text-sm line-clamp-1">Book 100+ hotels in Sunny Beach, Nessebar, Golden Sands, Albena...</span></div>
        </div>
    </div>

</div>
);
    }
export default SectionBanners;
