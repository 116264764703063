import { FC } from 'react';
import IconForRating from 'ecomponents/common/IconForRating/IconForRating';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import { ReviewListingDataType } from 'data/dataTypes/ReviewListingDataType';
import FiveStartIconForRate from '_unused-components-delete/components/BookmarkIconForRate/BookmarkIconForRate';
export interface ReviewListingProps {
  className?: string;
  reviewData?: ReviewListingDataType;
  hasListingTitle?: boolean;
}

const ReviewListing: FC<ReviewListingProps> = ({ className = '', reviewData, hasListingTitle }) => {
  console.log('reviewData.rating:', reviewData.rating);

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'short' });
  }

  return (
    <div className={`nc-ReviewListing flex space-x-4  ${className}`} data-nc-id="ReviewListing">
      <div className="flex-grow">
        <div className="flex justify-between space-x-3 mb-2">
          <div className="flex flex-col">
            <div className="flex flex-wrap pb-2">
              <div className="text-md font-semibold pr-2">
                <span>{reviewData.reviewerName}</span>
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">{reviewData.date}</span>
            </div>
            <div>
              <span className="inline-flex px-2.5 py-1 rounded-full font-medium text-sm relative text-green-800 bg-green-100  relative">
                <i className="las la-thumbs-up !text-lg !leading-5"></i> I would recommend to a friend
              </span>
            </div>
          </div>
          <div className="flex items-start ">
            <IconForRating
              readOnly={true}
              viewText={false}
              iconClass="w-4 h-4"
              className="space-x-0.2 text-yellow-500"
              defaultPoint={reviewData.rating}
            />
          </div>
        </div>
        <div className="flex text-neutral-6000">
          {reviewData.visitedMonth ? (
            <>
              <span className="text-sm pr-2 ">Date of stay:</span>
              <span className="text-sm">
                {getMonthName(reviewData.visitedMonth)} {reviewData.visitedYear}
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex text-neutral-6000">
          {reviewData.travellerType ? (
            <>
              <span className="text-sm pr-2">Type of traveller:</span>
              <span className="text-sm">{reviewData.travellerType}</span>
            </>
          ) : (
            <></>
          )}
        </div>
        {reviewData.reviewTitle ? (
          <h3 className="block mt-3 text-black font-semibold">{reviewData.reviewTitle}</h3>
        ) : (
          <></>
        )}
        <span className="block mt-3 text-black">{reviewData.reviewContent}</span>
      </div>
    </div>
  );
};

export default ReviewListing;
