import React, { FC } from "react";

export interface BackgroundGlassmorphismProps {
  className?: string;
}

const BackgroundGlassmorphism: FC<BackgroundGlassmorphismProps> = ({
  className
 
}) => {
  return (
    <div
      className={`nc-BackgroundGlassmorphism absolute inset-x-0  top-0  min-h-0 pl-20 py-24 flex overflow-hidden  ${className}`}
      data-nc-id="BackgroundGlassmorphism"
    >
      <span className="block bg-[#e87213] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96"></span>
      <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96 nc-animation-delay-2000"></span>
    </div>
  );
};

export default BackgroundGlassmorphism;
