import React, { useState } from "react";
import useWindowSize from "hooks/useWindowResize";
import { DateRage } from "ecomponents/forms/HeroSearchForm/StaySearchForm";
import StayDatesRangeInput from "ecomponents/forms/HeroSearchForm/StayDatesRangeInput";
import GuestsInput from "ecomponents/forms/HeroSearchForm/GuestsInput";
import ButtonPrimary from "ecomponents/ui/Button/ButtonPrimary";
import moment from "moment";

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { CurrencySign } from 'const';



const RightSearchFormTransfers = () => {
  
    const translationWords = [
        'VarFromMinPrice',
        'VarPerPerson',
        'VarPerNight',
    ];
    const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
    const hotelFields = [

        'min_price_data',
      ];
      const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
      if (hotel?.min_price_data) console.log('hotel min price: ', hotel?.min_price_data);


    const [selectedDate, setSelectedDate] = useState<DateRage>({
        startDate: moment(),
        endDate: moment().add(4, "days"),
      });
      const windowSize = useWindowSize();



      if (hotelStatus !== 'success') return <></>;  
    return (

      <div className="shadow-xl listingSectionSidebar__wrap ">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
          Search Transfers
            
          </span>
          
        </div>

        <div className="grid grid-cols-2 gap-0  ">
           <div>
           <select id="countries" className="bg-transparent border border-r-0 border-neutral-200 text-gray-900 text-sm rounded-l-3xl focus:outline-4 focus:ring-0 focus:border-neutral-200  block w-full p-2.5 pl-4 ">
  <option selected>From</option>
  <option value="US">Sofia</option>
  <option value="CA">Plovdiv</option>
  <option value="FR">Varna</option>
  <option value="DE">Burgas</option>
</select>
           </div>
           <div>
           <select id="countries" className="bg-transparent border border-neutral-200 text-gray-900 text-sm rounded-r-3xl focus:outline-4 focus:ring-0 focus:border-neutral-200  block w-full p-2.5 pl-4 ">
  <option selected>From</option>
  <option value="US">Sofia</option>
  <option value="CA">Plovdiv</option>
  <option value="FR">Varna</option>
  <option value="DE">Burgas</option>
  </select>
           </div>
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">


          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          />
        </form>

       

        {/* SUBMIT */}
        <ButtonPrimary >View Prices</ButtonPrimary>
      </div>
     
    );
};

export default RightSearchFormTransfers;
