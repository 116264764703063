import React from "react";

import Logo from "ecomponents/layout/Logo/Logo";
import FooterMenu from "./FooterMenu";
import SocialsList1 from "_unused-components-delete/shared/SocialsList1/SocialsList1";
import BackgroundSection from "ecomponents/common/BackgroundSection/BackgroundSection";
import SectionSubscribe from 'ecomponents/partials/SectionSubscribe/SectionSubscribe';


const Footer: React.FC = () => {

  return (
    <>
    <div className="container relative  space-y-5 md:space-y-16 lg:space-y-16 xl:space-y-16 ">
      <div className="relative py-16">
          <BackgroundSection />
          <SectionSubscribe />
      </div>
    </div>
    <div className="relative py-24 border-t nc-Footer lg:py-32 border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 col-span-2 gap-5 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="flex items-center col-span-2 md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        <FooterMenu/>
      </div>
    </div>
    </>
  );
};

export default Footer;
