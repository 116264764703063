import React, { FC } from "react";
import ButtonPrimary from "ecomponents/ui/Button/ButtonPrimary";
import imagePng from "images/BH-hero-zima.png";
import HeroSearchForm from "ecomponents/forms/HeroSearchForm/HeroSearchForm";
import { reduceEachLeadingCommentRange } from "typescript";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div className={`proba nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}  data-nc-id="SectionHero">
      <div className="flex flex-col lg:flex-row">
        <div   className="lg:pt-10 sm:pt-0  flex-shrink-0 lg:w-5/12 xl:w-7/12 flex flex-col items-start space-y-7 sm:space-y-5 pb-10 lg:pb-30 xl:pr-10 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-[#111d4d] text-3xl md:text-4xl xl:text-5xl !leading-[114%] ">
          Book a hotel in Bulgaria online
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          More than 700 ski and beach hotels, spa and city hotels, countryside hotels. Ski packages, transfers, rentacar, guided tours.
          </span>
          <ButtonPrimary >View our offers</ButtonPrimary>
        </div>
        <div className="flex-grow hidden lg:block">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
