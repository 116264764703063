import { Transition } from '@headlessui/react';

import ExperiencesCard from '_unused-components-delete/components/ExperiencesCard/ExperiencesCard';
import StayCardNoUse from 'ecomponents/common/StayCardNoUse/StayCardNoUse';
import { CarDataType, ExperiencesDataType, StayDataType } from 'data/types';
import React, { FC, Fragment } from 'react';
import { useState } from 'react';
import { HotelListingDataType } from 'data/dataTypes/HotelListingDataType';
import HotelCard from 'ecomponents/common/HotelCard/HotelCard';

export interface MapItemProps {
  className?: string;
  listing?: StayDataType;
  hotel?: HotelListingDataType;
  experiences?: ExperiencesDataType;
  car?: CarDataType;
  isSelected?: boolean;
  lat: number;
  lng: number;
}

const MapItem: FC<MapItemProps> = ({ className = '', listing, hotel, car, experiences, isSelected }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`nc-MapItem relative  ${className}`}
      data-nc-id="MapItem"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <span
        className={`flex px-2 py-1 rounded-lg bg-white dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-neutral-900 hover:text-white dark:hover:bg-white dark:hover:text-neutral-900 transition-colors ${
          isSelected ? 'bg-neutral-900 text-white dark:bg-white dark:text-neutral-900' : ''
        }`}
      >
        {hotel?.displayPrice || experiences?.price || car?.price}
      </span>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
          {hotel && <HotelCard size="small" hotelData={hotel} className="shadow-2xl" />}
          {experiences && (
            <ExperiencesCard
              size="small"
              data={experiences}
              className="shadow-2xl bg-white dark:bg-neutral-900 pt-3 px-3 rounded-3xl"
            />
          )}
        </div>
      </Transition>
    </div>
  );
};

export default MapItem;
