import React, { FC, Fragment, useState } from 'react';
import { LinkType } from 'data/types';

import { hotelTabs } from "./hotelTabs";

import RightBookingForm from "./partials/RightBookingForm";
import RightSection from "ecomponents/layout/RightSection/RightSection";
import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from '_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe from 'ecomponents/partials/SectionSubscribe/SectionSubscribe';
import ExpandableText from "ecomponents/ui/ExpandableText/ExpandableText";
import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import DealsSectionNew from "./partials/DealsSectionNew";
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import StickyFooterMobileHotel from "ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel";

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { DealModel } from 'features/deals/deal';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import EarlyBookingDiscount from './partials/EarlyBookingDiscount';
import RelatedOffersSection from './partials/RelatedOffersSection';

export interface HotelPagePricesProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
  deals_data?: DealModel[];
}

const HotelPagePrices: FC<HotelPagePricesProps> = ({
     className = '',
     activeTab = "Prices",
     isPreviewMode,
     tabs = hotelTabs,

    }) => {

        interface HotelLocal {
            name: string;
            deals_data?: DealModel[];
            photos?: any; 
            price_types_data?: any;
          }

  const hotelFields = ['name', 'deals_data', 'photos', 'price_types_data'];
  const { data: hotel, status: hotelStatus } : { data: HotelLocal; status: string } = useFetchHotelSelective(819, hotelFields);


  const translationWords = [
    'HotelSitePricesDiscounts',
    'FreeReservationCancellation',
    'VarSpecialOffers',
    'VarMostPopularOffers',
    'VarBookNow',
    'VarTransfers',
    'VarSkiPackages',
    'ModeButtonPricesDiscounts',
    'VarPriceNotes',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

const [tabActive, setTabActive] = useState<string>(activeTab);
const onClickTab = (item: string) => {
    setTabActive(item);
  };

  const size = "default";

  return (
    <div
      className={`nc-HotelPageRooms  ${className}`}
      data-nc-id='HotelPageRooms'
    >
      <div className='container relative z-10 mt-11'>
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className='container relative z-10 flex flex-col mt-6 lg:flex-row '>
        {/* CONTENT */}
        <div className='w-full  lg:w-3/5 xl:w-2/3  lg:pr-10'>
        <div className="listingSection__wrap">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">{hotel?.name} - {uppercaseFirstLetter(translations?.ModeButtonPricesDiscounts)}</h2>
           <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>


           <div className="grid grid-cols-1 sm:grid-cols-2 ">
           
           <EarlyBookingDiscount />
                  <div className=" mt-6 ml-0 sm:ml-4 sm:mt-0 flex justify-center  flex-col">
                 
                  <h2 className="text-2xl font-semibold mb-4">Booking on Best Price</h2>
                  <p className="text-lg font-semibold">{translations?.FreeReservationCancellation}</p>
                 
                  </div>
           </div>


           <div className="">
                 <h2 className="text-2xl font-semibold mb-4">{uppercaseFirstLetter(translations?.VarPriceNotes)}</h2>
                 <p className="text-neutral-700 font-semibold">
           <ExpandableText className="redbutton">
<p>Price includes free use of: Wi-Fi internet in the hotel, Wi-Fi internet in the rooms, Parking Indoor parking on payment, Shuttle bus to the ski slopes, Storage of ski equipment, Lounge, Baby cot, Child chair, Children playground, Indoor swimming pool, Pool towels, Fitness, Finnish sauna, Steam bath, Infrared sauna, Jacuzzi.
</p><br/>
<p>All prices are per room/apartment per day, on Bed only basis.<br/>
Bed & breakfast available on additional payment.<br/>
Additional payment for New Year dinner is obligatory.<br/>
The additional person discounts are from the regular price per person.<br/>
Please, use the booking form, to get a price for your period of stay, number of people and meals included.
</p><br/>
<p>The regular prices are NOT valid during national holidays, Christmas and New Year periods!<br/>
Body and Soul Spa Lounge package is included in the price of the accommodation to include: Swimming pool ; Fitness; Steam bath , Aroma herbal bath; Ice room; Infrared sauna , Cedar sauna, Herbal sauna, Energy steam sauna, Finland sauna; Jacuzzi/suitable for 11 pax/Tropical showers, Chillout lounge , Hammam
Children up to 5,99 yo stay free with two full price paying adults on extra bed<br/>
In case of an accommodation of one adult with child up to 5,99 yo, the room will be charged as Single room<br/>
NO discount for children on regular bed for the New Year Period The automatic price will be manually changed by our agent!
</p><br/>
<p>Body and Soul Spa Lounge package is included in the price of the accommodation to include: Swimming pool ; Fitness; Steam bath , Aroma herbal bath; Ice room; Infrared sauna , Cedar sauna, Herbal sauna, Energy steam sauna, Finland sauna; Jacuzzi/large/Tropical showers, Chillout lounge , Hammam<br/>
Children up to 5,99 yo stay free with two full price paying adults on extra bed<br/>
In case of an accommodation of one adult with child up to 5,99 yo, the room will be charged as Single room<br/>
NO discount for children on regular bed for the New Year Period The automatic price will be manually changed by our agent!<br/>
</p>
          </ExpandableText>
                 </p>
<br />
<h2 className="text-2xl font-semibold mb-4">{translations?.VarSpecialOffers}</h2>


{hotel?.deals_data ? <DealsSectionNew hotelDeals={Object.values(hotel.deals_data).filter(deal => deal.deal_type === 2 || deal.deal_type === 1)}/>: ''}
<br /><br />
<h2 className="text-2xl font-semibold mb-4">More Hotel Offers</h2>
<div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
    <div
      className={`nc-DealCard group relative border border-neutral-200 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow  will-change-transform  bg-blue-100`}
      data-nc-id="DealCard"
    >
      <a href="" className="flex flex-col">
   
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
      
          <div className="flex items-center justify-center space-x-2">
          
            <h2
              className={` pt-4  capitalize text-blue-800 text-center ${
                size === "default"
                  ? "text-2xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className=" text-blue-800">7 Nights = 5 Nights</span>
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center text-neutral-500 text-md space-x-2">
            <span className="text-blue-800 text-center">7+ overnights in the period 04.03-01.04</span>
            <span className="text-blue-800 text-center" dangerouslySetInnerHTML={{ __html: 'from 385 lv per person'}}></span>
            
          </div>
        </div>
        
        <div className="flex justify-center items-center ">
          
          <a href="#" className="my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4 uppercase" >{translations?.VarBookNow}</a>
          
        </div>
      </div>
      </a>
    </div>
    <div
      className={`nc-DealCard group relative border border-neutral-200 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow  will-change-transform  bg-blue-100`}
      data-nc-id="DealCard"
    >
      <a href="" className="flex flex-col">
   
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
      
          <div className="flex items-center justify-center space-x-2">
          
            <h2
              className={` pt-4  capitalize text-blue-800 text-center ${
                size === "default"
                  ? "text-2xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className=" text-blue-800">7 Nights = 5 Nights</span>
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center text-neutral-500 text-md space-x-2">
            <span className="text-blue-800 text-center">7+ overnights in the period 04.03-01.04</span>
            <span className="text-blue-800 text-center" dangerouslySetInnerHTML={{ __html: 'from 385 lv per person'}}></span>
            
          </div>
        </div>
        
        <div className="flex justify-center items-center ">
          
          <a href="#" className="my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4 uppercase" >{translations?.VarBookNow}</a>
          
        </div>
      </div>
      </a>
    </div>
</div>
<br />
           </div>

           <div className="overflow-x-auto responsive-table2">
           <h2 className="text-2xl font-semibold mb-4">Responsive Price Table</h2>
<Table>
    <Thead>
        <Tr>
            <Th>Season</Th>
            <Th>High season</Th>
            <Th>Middle seson</Th>
            <Th>Out of season</Th>
            <Th>Out of season 1</Th>
        </Tr>
    </Thead>
    <Tbody>
        {/* <Tr>
            <Td>Period</Td>
            <Td>01.12.2022 - 18.12.2022; 04.03.2023 - 31.03.2023
            </Td>
            <Td>19.12.2022 - 27.12.2022; 25.02.2023 - 03.03.2023
            </Td>
            <Td>02.01.2023 - 24.02.2023
            </Td>
            <Td>01.04.2023 - 10.04.2023
            </Td>
        </Tr>*/}
        <Tr>
            <Td>Twin room (SGL use / price for 1 adults)</Td>
            <Td>100 lv</Td>
            <Td>111 lv</Td>
            <Td>121 lv</Td>
            <Td>77 lv</Td>
        </Tr>
        <Tr>
            <Td>Twin room (price for 2 adults</Td>
            <Td>134 lv</Td>
            <Td>156 lv</Td>
            <Td>174 lv</Td>
            <Td>114 lv</Td>
        </Tr>
    </Tbody>
</Table>
<br/>
	       </div>
        </div>
        </div>

        {/* SIDEBAR */}
        <RightSection >
            <RightBookingForm />
        </RightSection>
        
      </main>


      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819}/>

      {/* OTHER SECTION */}
      <RelatedOffersSection/>
    </div>
  );
};

export default HotelPagePrices;
