export const ReviewToReviewListing = reviewData => {
  if (!reviewData) return null;

  return {
    id: reviewData.id,
    date: reviewData.ondate, ////
    subjectId: reviewData.subject_id,
    subjectType: reviewData.subject_type,
    reviewerName: reviewData.reviewer_name,
    reviewerCountry: reviewData.reviewer_country,
    recommend: reviewData.recommend,
    rating: reviewData.rating_overall,
    reviewTitle: reviewData.comment_title ?? '',
    reviewContent: reviewData.comment_content ?? '',
    priority: reviewData.priority,
    visitedMonth: reviewData.visited_month,
    visitedYear: reviewData.visited_year,
    travellerType: reviewData.traveller_type,
    travellerTypeCaption: reviewData.traveller_type_caption,
  };
};
