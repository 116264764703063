import React, { FC, Fragment, useState } from 'react';
import { LinkType } from 'data/types';

import { hotelTabs } from "./hotelTabs";

import RightBookingForm from "./partials/RightBookingForm";
import RightSection from "ecomponents/layout/RightSection/RightSection";
import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from '_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe from 'ecomponents/partials/SectionSubscribe/SectionSubscribe';
import HotelDistanses from "./partials/HotelDistanses";
import GoogleMapReact from "google-map-react";
import MapLocationMarker from "ecomponents/common/MapLocationMarker/MapLocationMarker";
import StickyFooterMobileHotel from "ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel";

import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import { uppercaseFirstLetter } from 'utils';

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import RelatedOffersSection from './partials/RelatedOffersSection';

export interface HotelPageLocationProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
}

const HotelPageLocation: FC<HotelPageLocationProps> = ({
     className = '',
     activeTab = "Location",
     isPreviewMode,
     tabs = hotelTabs,
    }) => {
        const translationWords = [
            'ModeButtonAddress',
            'VarAddress',
            'VarOfKatoNa',
            'VarHotelLocation',
            'ReservationPhone',
            'VarPricesOnlyWithProfiTours',
            'MapOnTheMapOf',
          ];
          const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
        
          const hotelFields = [
            'name',
            'address_full',
            'address_full_bg',
            'location',
            'hotel_facilities_data',
            { village_data: ['caption'] },
          ];

          const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
          
const [tabActive, setTabActive] = useState<string>(activeTab);
const onClickTab = (item: string) => {
    setTabActive(item);
  };


  return (
    
    <div
      className={`nc-HotelPageRooms style-location-page ${className}`}
      data-nc-id='HotelPageRooms'
    >
      <div className='container relative z-10 mt-11'>
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className='container relative z-10 flex flex-col mt-6 lg:flex-row '>
        {/* CONTENT */}
        <div className='w-full  lg:w-3/5 xl:w-2/3  lg:pr-10'>
        <div className="listingSection__wrap">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Lion Bansko - Location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

       {hotel?.address_full ? (
         <p>
           <b>{uppercaseFirstLetter(translations?.VarAddress)}: </b>
           {hotel?.address_full}
         </p>
       ) : (
         ''
       )}
       <p>
         <b>{translations?.ReservationPhone} *:</b> 0888 398 270, 0884 88 77 50, 0885 706 771 - We speak English
       </p>
       <p dangerouslySetInnerHTML={{ __html: '* ' + translations?.VarPricesOnlyWithProfiTours }} />
       <p>
           <b>{uppercaseFirstLetter(translations?.VarHotelLocation)}:</b>
           <span dangerouslySetInnerHTML={{ __html: hotel?.location }}></span>
           
           </p>
      


    <p className="distances-p text-neutral-900 !text-base leading-10">
            <HotelDistanses  facilities={hotel?.hotel_facilities_data}/>
    </p>

<br />
       <h2 className="text-2xl font-semibold mb-4">
         {hotel?.name} {translations?.MapOnTheMapOf} {hotel?.village_data?.caption}
       </h2>

       <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
      <div className="rounded-xl overflow-hidden">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={15}
          defaultCenter={{
            lat: 41.8252153,
            lng: 23.4809107,
          }}
        >
          <MapLocationMarker lat={41.8252153} lng={23.4809107} />

        </GoogleMapReact>
      </div>
    </div>
   
        



        </div>
        </div>

       {/* SIDEBAR */}
       <RightSection >
            <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819}/>

      {/* OTHER SECTION */}
      <RelatedOffersSection/>
    </div>
  );
};

export default HotelPageLocation;
