import { FC, useState } from "react";
import ExpandableText from "ecomponents/ui/ExpandableText/ExpandableText";
import { Link } from "react-router-dom";
import useFetchVillageSelective from 'features/villages/hooks/useFetchVillageSelective';
import BackgroundSection from "ecomponents/common/BackgroundSection/BackgroundSection";

const SkiInfoSection = () => {

  const imgs = [
    'https://www.bulgaria-hotels.com/villages/046_00_119.jpg',
    'https://www.bulgaria-hotels.com/accents/024_00_143.jpg',
    'https://www.bulgaria-hotels.com/villages/007_00_119.jpg',
    'https://www.bulgaria-hotels.com/villages/pano/6.jpg',
    'https://www.bulgaria-hotels.com/accents/018_00_143.jpg',
    'https://www.bulgaria-hotels.com/accents/023_00_143.jpg',
  ];

  const [className, setClassName] = useState('');

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
<div className="container relative">
            <h2 className="text-4xl font-semibold">Ski info section</h2>
            <div className="block mt-5 text-neutral-500 dark:text-neutral-400 space-y-4">
                <ExpandableText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id velit et urna accumsan euismod. Donec sed feugiat enim. Aenean euismod dapibus lectus et ultricies. Fusce varius aliquet ultricies. Sed eu tincidunt sem. Curabitur finibus lacinia consectetur. Vestibulum accumsan lacinia metus, congue condimentum risus lacinia efficitur. Sed sit amet efficitur tellus. Nam ac neque in ligula efficitur cursus.
            <br />
            Curabitur aliquam eu nisl sed consectetur. Ut posuere varius orci eget consectetur. Sed lacinia orci mauris, quis semper ipsum sollicitudin nec. Nam convallis vestibulum erat, vitae vulputate urna ullamcorper et. Pellentesque sed dolor mollis, rhoncus eros id, dignissim risus. Nam congue metus arcu, vitae consectetur arcu elementum et. Nullam egestas sapien at massa eleifend, ac placerat sapien finibus. Nullam vitae imperdiet elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer vestibulum erat nibh, in hendrerit purus consequat ut. Nunc placerat, quam in posuere eleifend, augue odio interdum nibh, a feugiat erat orci non tellus. Nullam tristique mauris in eros sagittis euismod. Ut rhoncus, ipsum sed luctus lobortis, velit libero ultricies turpis, ac scelerisque mauris turpis in felis. Suspendisse bibendum sapien nunc, a volutpat sem gravida at. Cras non commodo neque. Nullam arcu eros, condimentum quis tellus porta, consequat rhoncus turpis.  
            
            </ExpandableText>
            </div>


<br />

<br />
      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[0]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski condition</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[1]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski map</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[2]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski weather</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[3]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski reports</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[4]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski packages</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[5]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Ski transfers</span></h3>

        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
      </div>
      </div>
  );

}

export default SkiInfoSection;