import { MegamenuItem, NavItemType } from 'ecomponents/layout/Navigation/NavigationItem';
import ebNanoId from 'utils/ebNanoId';
import __megamenu from './jsons/__megamenu.json';

const devChildMenus: NavItemType[] = [
  {
    id: ebNanoId(),
    href: '/',
    name: 'Home',
  },
  {
    id: ebNanoId(),
    href: '/resort-page',
    name: 'Resort page',
    isNew: true,
    type: 'dropdown',
    children: [
      { id: ebNanoId(), href: '/resort-page', name: 'Resort Home' },
      { id: ebNanoId(), href: '/resort-page-photos', name: 'Resort Gallery' },
      { id: ebNanoId(), href: '/resort-page-videos', name: 'Resort Videos' },
    ],
  },

  {
    id: ebNanoId(),
    href: '/hotel-page',
    name: 'Hotel page',
    type: 'dropdown',
    children: [
      { id: ebNanoId(), href: '/hotel-page', name: 'Hotel Home' },
      { id: ebNanoId(), href: '/hotel-page-rooms', name: 'Hotel Rooms' },
      { id: ebNanoId(), href: '/hotel-page-facilities', name: 'Hotel Facilities' },
      { id: ebNanoId(), href: '/hotel-page-location', name: 'Hotel Location' },
      { id: ebNanoId(), href: '/hotel-page-prices', name: 'Hotel Prices' },
      { id: ebNanoId(), href: '/hotel-page-reviews', name: 'Hotel Reviews' },
      { id: ebNanoId(), href: '/hotel-page-photos', name: 'Hotel Gallery' },
      { id: ebNanoId(), href: '/hotel-page-facilities-food-dining', name: 'Food & dining F' },
      { id: ebNanoId(), href: '/hotel-page-facilities-business', name: 'Business F' },
      { id: ebNanoId(), href: '/hotel-page-facilities-recreation', name: 'Recreation F' },
      { id: ebNanoId(), href: '/hotel-page-facilities-entertainment', name: 'Entertainment F' },
      { id: ebNanoId(), href: '/hotel-page-facilities-night-life', name: 'Night life F' },
    ],
  },
];

const pagesChildMenus: NavItemType[] = [
  {
    id: ebNanoId(),
    href: '/resort-page-transfers',
    name: 'Transfers',
  },
  {
    id: ebNanoId(),
    href: '/home-2',
    name: 'Real Estate',
    isNew: true,
  },
  {
    id: ebNanoId(),
    href: '/home-1-header-2',
    name: 'Home - Header 2',
    isNew: true,
  },
];

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ebNanoId(),
    image: 'https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Company',
    items: __megamenu.map(i => ({
      id: ebNanoId(),
      href: '#',
      name: i.Company,
    })),
  },
  {
    id: ebNanoId(),
    image: 'https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'App Name',
    items: __megamenu.map(i => ({
      id: ebNanoId(),
      href: '#',
      name: i.AppName,
    })),
  },
  {
    id: ebNanoId(),
    image: 'https://images.pexels.com/photos/5059013/pexels-photo-5059013.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'City',
    items: __megamenu.map(i => ({
      id: ebNanoId(),
      href: '#',
      name: i.City,
    })),
  },
  {
    id: ebNanoId(),
    image: 'https://images.pexels.com/photos/5159141/pexels-photo-5159141.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Contruction',
    items: __megamenu.map(i => ({
      id: ebNanoId(),
      href: '#',
      name: i.Contruction,
    })),
  },
  {
    id: ebNanoId(),
    image: 'https://images.pexels.com/photos/7473041/pexels-photo-7473041.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Country',
    items: __megamenu.map(i => ({
      id: ebNanoId(),
      href: '#',
      name: i.Country,
    })),
  },
];

const otherPageChildMenus: NavItemType[] = [
  {
    id: ebNanoId(),
    href: '/blog',
    name: 'Blog Page',
  },
  {
    id: ebNanoId(),
    href: '/blog-single',
    name: 'Blog Single',
  },
  {
    id: ebNanoId(),
    href: '/about',
    name: 'About',
  },
  {
    id: ebNanoId(),
    href: '/contact',
    name: 'Contact us',
  },
  {
    id: ebNanoId(),
    href: '/login',
    name: 'Login',
  },
  {
    id: ebNanoId(),
    href: '/signup',
    name: 'Signup',
  },
  {
    id: ebNanoId(),
    href: '/subscription',
    name: 'Subscription',
  },
];

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ebNanoId(),
    href: '/add-listing-1',
    name: 'Add Listings',
    type: 'dropdown',
    children: [
      {
        id: ebNanoId(),
        href: '/add-listing-1',
        name: 'Add Listings 1',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-2',
        name: 'Add Listings 2',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-3',
        name: 'Add Listings 3',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-4',
        name: 'Add Listings 4',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-5',
        name: 'Add Listings 5',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-6',
        name: 'Add Listings 6',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-7',
        name: 'Add Listings 7',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-8',
        name: 'Add Listings 8',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-9',
        name: 'Add Listings 9',
      },
      {
        id: ebNanoId(),
        href: '/add-listing-10',
        name: 'Add Listings 10',
      },
    ],
  },
  //
  { id: ebNanoId(), href: '/checkout', name: 'Checkout' },
  { id: ebNanoId(), href: '/pay-done', name: 'Pay done' },
  //
  { id: ebNanoId(), href: '/author', name: 'Author Page' },
  { id: ebNanoId(), href: '/account', name: 'Account Page' },
];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ebNanoId(),
    href: '/',
    name: 'DEV',
    type: 'dropdown',
    children: devChildMenus,
  },
  /*
  {
    id: ebNanoId(),
    href: "#",
    name: "PAGES",
    type: "dropdown",
    children: pagesChildMenus,
  },
  {
    id: ebNanoId(),
    href: "#",
    name: "DEALS",
    type: "megaMenu",
    megaMenu: megaMenuDemo,
  },
  */
  /*
  {
    id: ebNanoId(),
    href: "#",
    name: "SKI",
    type: "dropdown",
    children: templatesChildrenMenus,
    isNew: true,
  },
  */
  /*
  {
    id: ebNanoId(),
    href: "#",
    name: "NEW YEAR",
    type: "dropdown",
    isNew: true,
    children: [
      {
        id: ebNanoId(),
        href: "/resort-page",
        name: "SUMMER",

        type: "dropdown",
        children: [
          { id: ebNanoId(), href: "/resort-page", name: "Stay page" },
          {
            id: ebNanoId(),
            href: "/resort-page-map",
            name: "Stay page (map)",
          },
          { id: ebNanoId(), href: "/hotel-page", name: "Stay Detail" },
        ],
      },

      //
      {
        id: ebNanoId(),
        href: "/listing-experiences",
        name: "Experiences listing",
        type: "dropdown",
        children: [
          {
            id: ebNanoId(),
            href: "/listing-experiences",
            name: "Experiences page",
          },
          {
            id: ebNanoId(),
            href: "/listing-experiences-map",
            name: "Experiences page (map)",
          },
          {
            id: ebNanoId(),
            href: "/listing-experiences-detail",
            name: "Experiences Detail",
          },
        ],
      },

      //
      {
        id: ebNanoId(),
        href: "/listing-car",
        name: "Cars listing",
        type: "dropdown",
        children: [
          { id: ebNanoId(), href: "/listing-car", name: "Cars page" },
          { id: ebNanoId(), href: "/listing-car-map", name: "Cars page (map)" },
          { id: ebNanoId(), href: "/listing-car-detail", name: "Car Detail" },
        ],
      },

      //
      {
        id: ebNanoId(),
        href: "/listing-real-estate",
        name: "Real Estate Listings",
        isNew: true,
        type: "dropdown",
        children: [
          {
            id: ebNanoId(),
            href: "/listing-real-estate",
            name: "Real Estate Listings",
            isNew: true,
          },
          {
            id: ebNanoId(),
            href: "/listing-real-estate-map",
            name: "Real Estate Maps",
            isNew: true,
          },
        ],
      },
      //
      {
        id: ebNanoId(),
        href: "/listing-flights",
        name: "Flights listing",
        isNew: true,
      },
    ],
  },

  {
    id: ebNanoId(),
    href: "#",
    name: "SUMMER",
    type: "dropdown",
    children: templatesChildrenMenus,
    isNew: true,
  },
  */
];
