import React, { useState } from "react";
import useWindowSize from "hooks/useWindowResize";
import { DateRage } from "ecomponents/forms/HeroSearchForm/StaySearchForm";
import StayDatesRangeInput from "ecomponents/forms/HeroSearchForm/StayDatesRangeInput";
import GuestsInput from "ecomponents/forms/HeroSearchForm/GuestsInput";
import ButtonPrimary from "ecomponents/ui/Button/ButtonPrimary";
import moment from "moment";

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { CurrencySign } from 'const';



const RightBookingForm = () => {


    const translationWords = [
        'VarFromMinPrice',
        'VarPerPerson',
        'VarPerNight',
    ];
    const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
    const hotelFields = [

        'min_price_data',
      ];
      const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
      if (hotel?.min_price_data) console.log('hotel min price: ', hotel?.min_price_data);


    const [selectedDate, setSelectedDate] = useState<DateRage>({
        startDate: moment(),
        endDate: moment().add(4, "days"),
      });
      const windowSize = useWindowSize();



      if (hotelStatus !== 'success') return <></>;  
    return (

      <div className="shadow-xl listingSectionSidebar__wrap">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">{translations?.VarFromMinPrice}</span> {hotel?.min_price_data?.display_price}{CurrencySign[hotel?.min_price_data?.display_currency]}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            /{translations?.VarPerPerson} {translations?.VarPerNight}
            </span>
          </span>
          
        </div>

       

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: 1,
              guestChildren: 2,
              guestInfants: 0,
            }}
          />
        </form>

       

        {/* SUBMIT */}
        <ButtonPrimary >View Prices</ButtonPrimary>
      </div>
     
    );
  };

  export default RightBookingForm;