import create from "zustand";
import produce, { Draft } from "immer";
// import { persist } from '../persist';
import { devtools, persist } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { PersonalDataType, defaultPersonalData } from "./personalDataType";

type personalDataStoreType = {
  personalData: PersonalDataType;
  setPersonalData: (search: PersonalDataType) => void;
  setName: (name: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setEmailAddress: (emailAddress: string) => void;
  setMyCountryCode: (myCountryCode: string) => void;
};

export const usePersonalDataStore = create<personalDataStoreType>()(
  devtools(
    persist(
      (set): personalDataStoreType => ({
        personalData: defaultPersonalData,
        setPersonalData: (personalData: PersonalDataType) =>
          set(
            produce((draft: Draft<personalDataStoreType>) => {
              draft.personalData = personalData;
            })
          ),
        setName: (name: string) =>
          set(
            produce((draft: Draft<personalDataStoreType>) => {
              draft.personalData.name = name;
            })
          ),
        setPhoneNumber: (phoneNumber: string) =>
          set(
            produce((draft: Draft<personalDataStoreType>) => {
              draft.personalData.phoneNumber = phoneNumber;
            })
          ),
        setEmailAddress: (emailAddress: string) =>
          set(
            produce((draft: Draft<personalDataStoreType>) => {
              draft.personalData.emailAddress = emailAddress;
            })
          ),
        setMyCountryCode: (myCountryCode: string) =>
          set(
            produce((draft: Draft<personalDataStoreType>) => {
              draft.personalData.myCountryCode = myCountryCode;
            })
          ),
      }),
      {
        name: "personalDataStore",
      }
    )
  )
);

mountStoreDevtool("personalDataStore", usePersonalDataStore as any);
