import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import ImageSlider from "ecomponents/ui/ImageSlider/ImageSlider";
import { Facility, facilityIcons } from 'const/facilities';
import { extractFacility } from 'utils/datahelpers/extractFacility';
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import { RoomModel } from "features/rooms/room";
import { singleOrPlural, lowercaseFirstLetter } from 'utils';
import { CurrencySign } from 'const';

import {  GiFlowerPot } from 'react-icons/gi';
import {  IoWifiOutline } from 'react-icons/io5';
import {  IoMdCheckmark } from 'react-icons/io';
import { RiParkingBoxLine } from "react-icons/ri";

const RoomMainInfo = () => {

    const translationWords = ['VarHotelDetails', 'VarChild', 'VarChildren', 'VarAdult', 'VarAdults', 'VarOr', 'VarPerPerson', 'VarFromMinPrice'];
    const { data: translations, status: translationsStatus } =
      useFetchTranslationsSelective(translationWords);

      interface VillageLocal {
        caption: string;
      }
    interface HotelLocal {
        name: string;
        general: string;
        food_dining: string;
        business_facilities: string;
        children: string;
        accomodation: string;
        hotel_facilities_data: RoomModel[]; 
        rooms_data: RoomModel[]; 
        village_data: VillageLocal;
      }
    
      const hotelId = 819;
      const roomId = 3;

    const hotelFields = [
        'name',
        'general',
        'photos',
        'hotel_facilities_data',
        'food_dining',
        'business_facilities',
        'children',
        'rooms_data',
        'accomodation',
        { village_data: ['caption'] },
      ];
    
      const { data: hotel, status: hotelStatus }: { data: HotelLocal; status: string } = useFetchHotelSelective(hotelId, hotelFields);
      let filterHotelRoom = [];
      if (hotel?.rooms_data)  
         filterHotelRoom = Object.values(hotel.rooms_data).filter(room => room.id === roomId);



         let galleryImgsNew = [];
         let roomFacilities = [];
         
         if (hotel?.rooms_data) {
          galleryImgsNew = filterHotelRoom?.[0].photos;
         roomFacilities = filterHotelRoom?.[0].facilities;
       }
       
       
       const galleryImgsNewUrl = galleryImgsNew.map(photo => photo.url);

       const viralFacilities = {
        size: null,
        balcony: null,
        airconditioning: null,
        television: null,
        minibar: null,
      };
    
      if (hotel?.rooms_data) {
        viralFacilities.size = extractFacility(filterHotelRoom?.[0].facilities, Facility.ROOM_SIZE);
        viralFacilities.balcony = extractFacility(filterHotelRoom?.[0].facilities, Facility.BALCONY);
        viralFacilities.airconditioning = extractFacility(filterHotelRoom?.[0].facilities, Facility.AIRCONDITIONING);
        viralFacilities.television = extractFacility(filterHotelRoom?.[0].facilities, Facility.TELEVISION);
        viralFacilities.minibar = extractFacility(filterHotelRoom?.[0].facilities, Facility.MINIBAR);
      }
    
  if (hotelStatus !== 'success') return <></>;
  return (
    <>
           <div className="grid grid-cols-1 sm:grid-cols-2">
              
              <div className="p-2.5 bg-primary-6000 text-white flex items-center space-x-3">
                  <i className="las la-user-friends text-2xl inline-block align-middle !leading-7"></i>
                  <span>
                  {filterHotelRoom?.[0].max_adults}{' '}
            {singleOrPlural(
              lowercaseFirstLetter(translations?.VarAdult),
              lowercaseFirstLetter(translations?.VarAdults),
              filterHotelRoom?.[0].max_adults,
            )}{' '}
            {filterHotelRoom?.[0].max_children > 0
              ? ' + ' +
              filterHotelRoom?.[0].max_children +
                ' ' +
                singleOrPlural(
                  lowercaseFirstLetter(translations?.VarChild),
                  lowercaseFirstLetter(translations?.VarChildren),
                  filterHotelRoom?.[0].max_children,
                ) +
                '  '
              : ' '}
            {filterHotelRoom?.[0].max_adults_only != filterHotelRoom?.[0].max_adults
              ? ' ' +
                translations?.VarOr +
                ' ' +
                filterHotelRoom?.[0].max_adults_only +
                ' ' +
                singleOrPlural(
                  lowercaseFirstLetter(translations?.VarAdult),
                  lowercaseFirstLetter(translations?.VarAdults),
                  filterHotelRoom?.[0].max_adults_only,
                )
              : ' '}
                  </span>
              </div>
              <div className="ml-0 mt-6 sm:ml-8 sm:mt-0">
              <div className="p-2.5 bg-neutral-100 flex items-center space-x-3">
                  <i className="las la-money-bill text-lg !hidden sm:!inline-block "></i>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  {translations?.VarFromMinPrice} <span className="hidden sm:inline" style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{filterHotelRoom?.[0].min_price.display_price != filterHotelRoom?.[0].min_price.display_nodiscount_price ? (
               ' ' +
                      filterHotelRoom?.[0].min_price.display_nodiscount_price +
                      CurrencySign[filterHotelRoom?.[0].min_price.display_currency] +
                      ' '
              ) : (
                ''
              )}</span> <strong className="text-lg font-semibold text-red-600">{filterHotelRoom?.[0].min_price.display_price}{CurrencySign[filterHotelRoom?.[0].min_price.display_currency]}</strong> / {translations?.VarPerPerson}
                  </span>
              </div>
              </div>
      </div>



       <div className="grid grid-cols-1 sm:grid-cols-2">
      
      
           <div>
           
            <ImageSlider
      ratioClass="aspect-w-6 aspect-h-5"
      galleryImgs={galleryImgsNewUrl}
      uniqueID={`RoomCardHorizontalOld`}
      />
      <br />
      <div className="room-facilities-icons">
      {viralFacilities.size?.details ? (
            <span className="inline-block mr-2">
              <i className="inline-block align-middle">{facilityIcons[viralFacilities.size.id]}</i> {uppercaseFirstLetter(viralFacilities.size?.details)}
              {'m²;'}
            </span>
          ) : (
            ''
          )}


{viralFacilities.airconditioning ? (
            <span className="inline-block mr-2">
              <i className="inline-block align-middle">{facilityIcons[viralFacilities.airconditioning.id]}</i>{' '}
              {uppercaseFirstLetter(viralFacilities.airconditioning?.caption)}
              {';'}
            </span>
          ) : (
            ''
          )}

{viralFacilities.television ? (
            <span className="inline-block mr-2">
               <i className="inline-block align-middle">{facilityIcons[viralFacilities.television.id]}</i>{' '}
              {uppercaseFirstLetter(viralFacilities.television?.caption)}
              {';'}
            </span>
          ) : (
            ''
          )}

{viralFacilities.minibar ? (
            <span className="inline-block mr-2">
              <i className="inline-block align-middle">{facilityIcons[viralFacilities.minibar.id]}</i> {uppercaseFirstLetter(viralFacilities.minibar?.caption)}
              {';'}
            </span>
          ) : (
            ''
          )}

<span className="inline-block mr-2">
<GiFlowerPot  className="inline-block align-middle"/> Garden view;
</span>

<span className="inline-block mr-2">
<IoWifiOutline  className="inline-block align-middle"/> Free WiFi;
</span>

<span className="inline-block mr-2">
<RiParkingBoxLine  className="inline-block align-middle"/> Free parking
</span>
</div>
<br />
<span className="inline-block room-beds bg-[#111d4d] text-white mb-6 px-4 py-2">
           
            <i className="las la-bed"></i> &nbsp;1 queen bed
            
          </span>
          
<br />
<p>{filterHotelRoom?.[0].description}</p>
         
      
<br />
      
      <span>
              <b>Size:</b> {uppercaseFirstLetter(viralFacilities.size?.details)}
              {'m²;'}
            </span>
       
<br />
          <span>
            <b>View:</b> Garden View
          </span>
          <br />
          <span>
            <b>Smoking:</b> ​No smoking
          </span>
          <br />
          <span>
            <b>Parking:</b> Free private parking is possible on site (reservation is not needed).
          </span>
         
           </div>
           <div className="ml-0 mt-6 sm:ml-8 sm:mt-0">        
            <h2 className="text-xl font-bold mb-4">Room facilities</h2>
            <p className="font-bold">Price includes free use of: Wi-Fi internet, breakfast, dinner, Indoor swimming pool, fitness, shuttle bus to the ski slopes, storage of ski equipment</p>
            <br />
       

            { roomFacilities?
                roomFacilities.map((facility, i) => {
                    if (facility.yesno || (!facility.has_yesno && facility.has_type && facility.type_caption !== 0)) {
                      return (
                        <p key={facility?.iid} className='leading-8 font-semibold'>
                          <IoMdCheckmark  className="inline-block"/>{' '}
                          <span className='capitalize'>
                            {facility?.caption}
                            <i className='lowercase'>
                              {facility.type_caption ? ' (' + facility.type_caption.toString().toLowerCase() + ')' : ''}
                            </i>
                          </span>
                          <br />
                        </p>
                      );
                    }
                  }):''
            }


           </div>
       </div>
    </>
  );
};

export default RoomMainInfo;