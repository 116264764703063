import React from 'react';
import { Link } from 'react-router-dom';
import ebNanoId from 'utils/ebNanoId';
import { HeadingWithLinksType } from 'data/dataTypes/HeadingWithLinksType';

const HeadingWithLinks: React.FC<HeadingWithLinksType> = ({
  className = '',
  heading = { caption: 'Hotels in Pamporovo' },
  subHeading,
  links = [],
  accentLinks = [],
}) => {
  return (
    <div className={`flex mb-12 mb-5 relative z-5 ${className} `}>
      <div className="sm:w-7/12">
        {heading?.caption ? <h1 className="text-4xl font-semibold">{heading?.caption}</h1> : ''}
        {subHeading ? subHeading : ''}
        {links && links.length ? (
          <span className="inline-block text-neutral-500  dark:text-neutral-400 mt-3">
            {links.map(link => {
              return (
                <>
                  <Link
                    key={link.id}
                    to={link.toUrl ?? ''}
                    className={'inline-block underline hover:text-primary-6000'}
                  >
                    {link.caption}
                  </Link>
                  <span className="mx-2">·</span>
                </>
              );
            })}
          </span>
        ) : (
          ''
        )}
      </div>

      {accentLinks && accentLinks.length ? (
        <span className="hidden sm:block flex items-end text-right  w-5/12 ">
          {accentLinks.map(link => {
            return (
              <>
                <button className="mt-3 nc-Button relative h-auto inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonSecondary font-medium border bg-primary-6000 border-neutral-200 text-white !leading-none focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary-6000 ">
                  <span>{link.caption}</span>
                  <i className="ml-3 las la-arrow-right text-xl"></i>
                </button>
                <div className="w-2" />
              </>
            );
          })}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default HeadingWithLinks;
