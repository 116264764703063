export const HotelToHotelListing = hotelData => {
  if (!hotelData) return null;

  return {
    id: hotelData.id,
    title: hotelData.name,
    locationTitle: 'TODO VILLAGE',
    mealsIncluded: 'TODO BOARD',
    distances: hotelData.distances_line_parts,
    galleryImgs: Array.isArray(hotelData?.photos) ? hotelData.photos.map(photo => photo.url) : [],
    accent: hotelData.special_tag_primary,
    description: hotelData.general,
    location: hotelData.location,
    displayPrice: 666,
    displayNodiscountPrice: 999,
    displayCurrency: 1,
    displayPricePer: '/night',
    displayPriceReplacement: '',
    recommended: hotelData.recommended,
    rating: hotelData.rating_general,
    ratingNumberOfVotes: hotelData.rating_numrates,
    map: {
      lat: +hotelData.latitude,
      lng: +hotelData.longitude,
    },
    address: hotelData.address_full,
    url: 'todo-url',
  };
};
