import React, { FC } from 'react';
import ImageSlider from 'ecomponents/ui/ImageSlider/ImageSlider';
import { DEMO_HOTEL_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'ecomponents/ui/BtnLikeIcon/BtnLikeIcon';
import SaleOffBadge from 'ecomponents/ui/SaleOffBadge/SaleOffBadge';
import './StayCardNoUse.scss';

export interface HotelCardProps {
  className?: string;
  data?: StayDataType;
  size?: 'default' | 'small';
}

const DEMO_DATA = DEMO_HOTEL_LISTINGS[0];

const StayCardNoUse: FC<HotelCardProps> = ({ size = 'default', className = '', data = DEMO_DATA }) => {
  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    bedrooms,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    numberOfGuests,
    hotelText,
    resort,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <ImageSlider uniqueID={`HotelCard_${id}`} ratioClass="aspect-w-4 aspect-h-3 " galleryImgs={galleryImgs} />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="hotel-list-content">
        <div className={size === 'default' ? 'p-4 space-y-4' : 'p-3 space-y-2'}>
          <div className="space-y-2 ">
            <i className="las la-bed text-lg mr-2"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">{numberOfGuests}</span>
            <div className="flex items-center space-x-2">
              <h2 className={` font-medium capitalize ${size === 'default' ? 'text-lg' : 'text-base'}`}>
                <span className="line-clamp-1">{title}</span>
              </h2>
            </div>
            <div className="flex flex-col items-start  text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <span className="hotel-text">{hotelText}</span>
              <span className="text-black pt-2 !ml-0">
                <i className="las la-map-marker-alt "></i> {resort}
              </span>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {price}
              {` `}
              {size === 'default' && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">/night</span>
              )}
            </span>
            <span className="text-sm text-red-500 font-medium dark:text-neutral-400 font-normal">{`view hotel >`}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardNoUse group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCardNoUse"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default StayCardNoUse;
