import React, { FC } from "react";
let imgs = [
    'https://misha.krusharski.com/bhpics/ski-season-banner.jpg',
    'https://misha.krusharski.com/bhpics/ski-packs.jpg',
    'https://misha.krusharski.com/bhpics/baner-ski-hotels.jpg',
    'https://misha.krusharski.com/bhpics/ski-appartments.jpg',
    'https://misha.krusharski.com/bhpics/summer-banner.jpg',
    'https://misha.krusharski.com/bhpics/yastrebets.jpg',
];
export interface SectionWebcams2MainProps {
    title?: string;
    subtitle?: string;
  }

  const SectionWebcams2Main: FC<SectionWebcams2MainProps> = ({  title,  subtitle }) => {
    return (
<>
<div className="nc-SectionMagazine8 relative ">
    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-14 text-neutral-900 dark:text-neutral-50">
        <div >
            <h2 className="text-3xl md:text-4xl font-semibold">{title}</h2>
            <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">{subtitle}</span></div>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-6 gap-6 md:gap-8">
    <div className="nc-Card16Podcast relative flex flex-col sm:col-span-3 lg:col-span-2" data-nc-id="Card16Podcast">
            <a className="block flex-shrink-0 relative w-full rounded-3xl overflow-hidden aspect-w-3 xl:aspect-w-4 aspect-h-3" href="/ncmaz/single-audio/this-is-single-slug">
                <div className="nc-NcImage " data-nc-id="NcImage"><img src={imgs[0]} className="object-cover w-full h-full" alt="nc-imgs"/>
                </div>
                <span className="bg-neutral-900 bg-opacity-30"
                />
                
            </a>
            <a className="absolute inset-0" href="/ncmaz/single-audio/this-is-single-slug"></a><span className="absolute top-3 inset-x-3"><div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Tools</a></div></span>
            <div className="w-11/12 transform -mt-32 ">
                <div className="px-5 flex items-center space-x-4 ">
                    <div className="flex-grow "><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMkAAAAdCAYAAAAAaUg8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADZSURBVHgB7drLDoIwFEXRhi/XL6+PCTNPSS8gYa2pid2TkyKxtUm990e7AJ21dG7wjujtAnTWulPn0oCfjAQCI4EgjmT2h89RP5x01tK57Uv6np9X0VlL58rjFgTL2e+RR8/XOUZnre/5e19XVdeyzprP/6XjSp0etyAwEgiMBAIjgcBIIDASCIwEAiOBwEggMBIIPiN5tnONnq+z9nydVecf9R+dWTpr6Vx53IJgZCSz191R16XOWjqrHHWtztJZ606dHrcgMBIIjASCipGc/R57lM5at+l8Ab6JZwjCaizRAAAAAElFTkSuQmCC"
                            alt="musicWave" /></div>
                    <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer">
                        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-neutral-50 text-primary-500 cursor-pointer"><svg className="ml-0.5 w-9 h-9" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></div>
                    </div>
                </div>
                <div className="p-5 mt-5 bg-white dark:bg-neutral-900 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none flex flex-col flex-grow ">
                    <h2 className="nc-card-title block text-xl font-semibold text-neutral-900 dark:text-neutral-100 "><a className="line-clamp-1" title="How AI and Teams " href="/ncmaz/single-audio/this-is-single-slug">How AI and Teams </a></h2><span className="block text-sm text-neutral-500 dark:text-neutral-400 mt-3 mb-5"><span className="line-clamp-2">In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus.</span></span>
                    <div className="flex items-end justify-between mt-auto">
                        <div className="nc-PostCardLikeAndComment flex items-center space-x-2 relative" data-nc-id="PostCardLikeAndComment"><button className="nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors px-3 h-8 text-xs focus:outline-none text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500"
                                title="Liked" data-nc-id="PostCardLikeAction"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z" clipRule="evenodd"></path></svg><span className="ml-1 text-neutral-900 dark:text-neutral-200">3.0k</span></button>
                            <a className="nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 hidden sm:flex  px-3 h-8 text-xs focus:outline-none"
                                title="Comments" data-nc-id="PostCardCommentBtn" href="/ncmaz/single-audio/this-is-single-slug#comments"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9.5 11C9.5 11.2761 9.27614 11.5 9 11.5C8.72386 11.5 8.5 11.2761 8.5 11C8.5 10.7239 8.72386 10.5 9 10.5C9.27614 10.5 9.5 10.7239 9.5 11Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M12.5 11C12.5 11.2761 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.2761 11.5 11C11.5 10.7239 11.7239 10.5 12 10.5C12.2761 10.5 12.5 10.7239 12.5 11Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.5 11C15.5 11.2761 15.2761 11.5 15 11.5C14.7239 11.5 14.5 11.2761 14.5 11C14.5 10.7239 14.7239 10.5 15 10.5C15.2761 10.5 15.5 10.7239 15.5 11Z"></path></svg>
                            <span
                                className="ml-1 text-neutral-900 dark:text-neutral-200">13</span>
                                </a>
                        </div>
                        <div className="nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 relative" data-nc-id="PostCardSaveAction"><button className="nc-NcBookmark relative rounded-full flex items-center justify-center focus:outline-none h-8 w-8 bg-neutral-50 hover:bg-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700" data-nc-id="NcBookmark" data-nc-bookmark-post-id="DEMO_POSTS_AUDIO_2"
                                title="Save to reading list"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"></path></svg></button></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="nc-Card16Podcast relative flex flex-col sm:col-span-3 lg:col-span-2" data-nc-id="Card16Podcast">
            <a className="block flex-shrink-0 relative w-full rounded-3xl overflow-hidden aspect-w-3 xl:aspect-w-4 aspect-h-3" href="/ncmaz/single-audio/this-is-single-slug">
                <div className="nc-NcImage " data-nc-id="NcImage"><img src={imgs[0]} className="object-cover w-full h-full" alt="nc-imgs"/>
                </div>
                <span className="bg-neutral-900 bg-opacity-30"
                />
                
            </a>
            <a className="absolute inset-0" href="/ncmaz/single-audio/this-is-single-slug"></a><span className="absolute top-3 inset-x-3"><div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList"><a className="transition-colors hover:text-white duration-300 nc-Badge  inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100 hover:bg-pink-800" href="/ncmaz/archive/the-demo-archive-slug">Tools</a></div></span>
            <div className="w-11/12 transform -mt-32 ">
                <div className="px-5 flex items-center space-x-4 ">
                    <div className="flex-grow "><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMkAAAAdCAYAAAAAaUg8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADZSURBVHgB7drLDoIwFEXRhi/XL6+PCTNPSS8gYa2pid2TkyKxtUm990e7AJ21dG7wjujtAnTWulPn0oCfjAQCI4EgjmT2h89RP5x01tK57Uv6np9X0VlL58rjFgTL2e+RR8/XOUZnre/5e19XVdeyzprP/6XjSp0etyAwEgiMBAIjgcBIIDASCIwEAiOBwEggMBIIPiN5tnONnq+z9nydVecf9R+dWTpr6Vx53IJgZCSz191R16XOWjqrHHWtztJZ606dHrcgMBIIjASCipGc/R57lM5at+l8Ab6JZwjCaizRAAAAAElFTkSuQmCC"
                            alt="musicWave" /></div>
                    <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer">
                        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-neutral-50 text-primary-500 cursor-pointer"><svg className="ml-0.5 w-9 h-9" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></div>
                    </div>
                </div>
                <div className="p-5 mt-5 bg-white dark:bg-neutral-900 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none flex flex-col flex-grow ">
                    <h2 className="nc-card-title block text-xl font-semibold text-neutral-900 dark:text-neutral-100 "><a className="line-clamp-1" title="How AI and Teams " href="/ncmaz/single-audio/this-is-single-slug">How AI and Teams </a></h2><span className="block text-sm text-neutral-500 dark:text-neutral-400 mt-3 mb-5"><span className="line-clamp-2">In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus.</span></span>
                    <div className="flex items-end justify-between mt-auto">
                        <div className="nc-PostCardLikeAndComment flex items-center space-x-2 relative" data-nc-id="PostCardLikeAndComment"><button className="nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors px-3 h-8 text-xs focus:outline-none text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500"
                                title="Liked" data-nc-id="PostCardLikeAction"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z" clipRule="evenodd"></path></svg><span className="ml-1 text-neutral-900 dark:text-neutral-200">3.0k</span></button>
                            <a className="nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 hidden sm:flex  px-3 h-8 text-xs focus:outline-none"
                                title="Comments" data-nc-id="PostCardCommentBtn" href="/ncmaz/single-audio/this-is-single-slug#comments"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9.5 11C9.5 11.2761 9.27614 11.5 9 11.5C8.72386 11.5 8.5 11.2761 8.5 11C8.5 10.7239 8.72386 10.5 9 10.5C9.27614 10.5 9.5 10.7239 9.5 11Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M12.5 11C12.5 11.2761 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.2761 11.5 11C11.5 10.7239 11.7239 10.5 12 10.5C12.2761 10.5 12.5 10.7239 12.5 11Z"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.5 11C15.5 11.2761 15.2761 11.5 15 11.5C14.7239 11.5 14.5 11.2761 14.5 11C14.5 10.7239 14.7239 10.5 15 10.5C15.2761 10.5 15.5 10.7239 15.5 11Z"></path></svg>
                            <span
                                className="ml-1 text-neutral-900 dark:text-neutral-200">13</span>
                                </a>
                        </div>
                        <div className="nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 relative" data-nc-id="PostCardSaveAction"><button className="nc-NcBookmark relative rounded-full flex items-center justify-center focus:outline-none h-8 w-8 bg-neutral-50 hover:bg-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700" data-nc-id="NcBookmark" data-nc-bookmark-post-id="DEMO_POSTS_AUDIO_2"
                                title="Save to reading list"><svg width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"></path></svg></button></div>
                    </div>
                </div>
            </div>
        </div>
        {/* Small videos containers*/}
        <div className="flex flex-col space-y-6  lg:space-y-3 sm:col-span-6 lg:col-span-2">




        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[5]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Quia sit blanditiis error accusamus</span></h3><span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 "><span className="line-clamp-1"><p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
</span></span>
        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[5]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Quia sit blanditiis error accusamus</span></h3><span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 "><span className="line-clamp-1"><p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
</span></span>
        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[5]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Quia sit blanditiis error accusamus</span></h3><span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 "><span className="line-clamp-1"><p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
</span></span>
        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>
        <div className="nc-Card17Podcast relative flex items-center justify-between p-2.5 space-x-5 rounded-full bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-30 hover:shadow-lg transition-shadow " data-nc-id="Card17Podcast">
           <a href="https://ncmaz.chisnghiax.com/quia-sit-blanditiis-error-accusamus/" className="flex items-center space-x-4 overflow-hidden">
        <div className="block flex-shrink-0 w-11 h-11 sm:w-16 sm:h-16 relative rounded-full overflow-hidden">
            <div className="nc-NcImage absolute inset-0 overflow-hidden z-0" data-nc-id="NcImage"><img src={imgs[5]} alt="Quia sit blanditiis error accusamus" className="object-cover w-full h-full " loading="lazy" 
                    sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
        <div className="flex flex-col flex-1 ">
            <h3 className="nc-card-title block font-semibold text-sm sm:text-lg"><span className="line-clamp-2 leading-5" title="Quia sit blanditiis error accusamus">Quia sit blanditiis error accusamus</span></h3><span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 "><span className="line-clamp-1"><p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
</span></span>
        </div>
           </a>
           <div className="nc-ButtonPlayMusicRunningContainer select-none " data-nc-id="ButtonPlayMusicRunningContainer"><span className="w-11 h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer"><svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.25 12L5.75 5.75V18.25L18.25 12Z"></path></svg></span></div>
        </div>





</div>
    </div>


        </div>
</>
);
    }
export default SectionWebcams2Main;
