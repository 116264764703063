export const hotelTabs = [
  {
  caption: "The Hotel",
  toUrl: '/hotel-page'
}, {
  caption: "Rooms",
  toUrl: '/hotel-page-rooms'
}, {
  caption: "Facilities",
  toUrl: '/hotel-page-facilities'
}, {
  caption: "Location",
  toUrl: '/hotel-page-location'
}, {
  caption: "Prices",
  toUrl: '/hotel-page-prices'
}];