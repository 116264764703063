import create from "zustand";
import produce, { Draft } from "immer";
// import { persist } from '../persist';
import { devtools, persist } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { PathsType } from "./pathsType";
import { defaultPathNames } from "./defaultPathNames";

type pathsStoreType = {
  paths: PathsType;
  setPaths: (localizedPaths: PathsType) => void;
};

export const usePathsStore = create<pathsStoreType>()(
  devtools(
    persist(
      (set): pathsStoreType => ({
        paths: defaultPathNames,
        setPaths: (localizedPaths: PathsType) =>
          set(
            produce((draft: Draft<pathsStoreType>) => {
              draft.paths = localizedPaths;
            })
          ),
      }),
      {
        name: "pathsStore",
      }
    )
  )
);

mountStoreDevtool("pathsStore", usePathsStore as any);
