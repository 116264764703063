import Input from 'ecomponents/ui/forms/Input/Input';
import ButtonCircle from 'ecomponents/ui/Button/ButtonCircle';
import { ArrowRightIcon } from '@heroicons/react/outline';
import ReviewListing from 'ecomponents/common/ReviewListing/ReviewListing';
import ButtonSecondary from 'ecomponents/ui/Button/ButtonSecondary';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import IconForRating from 'ecomponents/common/IconForRating/IconForRating';
import { ReviewToReviewListing } from 'data/dataTypes/adapters/ReviewToReviewListing';

const ReviewsSection = () => {
  const hotelFields = [
    'reviews_data',
    'rating_data',
    'rating_general',
    'rating_numrates',
    'rating_value_for_money',
    'rating_location',
    'rating_staff',
    'rating_rooms',
    'rating_food',
    'rating_fun',
  ];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
  //console.log('reviews data: ', hotel.reviews_data);

  const translationWords = [
    'VarHotelRating',
    'VarComments',
    'VarBasedOn',
    'RateValueMoney',
    'VarOutOf',
    'VarLocation',
    'RateStaffService',
    'VarRooms',
    'RateFood',
    'PicGroupEntertainment',
    'VarSubmitComment',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const reviewsForListing = hotel?.reviews_data
    ? Object.values(hotel?.reviews_data).map(review => ReviewToReviewListing(review))
    : [];

  return (
    <>
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className=" flex justify-between">
          <span className="text-2xl font-semibold  mt-1">Lion Bansko - Rating</span>
          <button className="-mt-1 mb-3 nc-Button relative h-auto inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonSecondary font-medium border bg-primary-6000 border-neutral-200 text-white !leading-none focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary-6000 ">
            <span>{translations?.VarSubmitComment}</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </button>
        </h2>

        {/* Content */}
        <div className="!mt-4 ">
          <div className="pb-4 pt-4 px-2  bg-neutral-100 border-t border-neutral-200 flex flex-col xs:flex-row items-center">
            <h3 className="pb-2 sm:pb-0 text-xl font-semibold capitalize inline-block w-[100%] xs:w-[40%] sm:text-right leading-5">
              {translations?.VarHotelRating}:
            </h3>
            <span className="inline-block !mt-0 flex pl-1">
              <IconForRating
                readOnly={true}
                iconClass="w-5 h-5"
                boldClass="font-bold"
                className="space-x-0.2  text-primary-6000"
                defaultPoint={hotel?.rating_general}
                reviewsNumber={hotel?.rating_numrates}
              />
            </span>
          </div>

          <div className="  px-2 xs:py-2 xs:pt-3 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.RateValueMoney}:
            </h3>
            <div className="inline-block pl-1">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_value_for_money}
                />
              </span>
            </div>
          </div>
          <div className="px-2 xs:py-2 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.VarLocation}:&nbsp;
            </h3>
            <div className="inline-block">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_location}
                />
              </span>
            </div>
          </div>
          <div className="px-2 xs:py-2 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.RateStaffService}:&nbsp;
            </h3>
            <div className="inline-block">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_staff}
                />
              </span>
            </div>
          </div>
          <div className="px-2 xs:py-2 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.VarRooms}:&nbsp;
            </h3>
            <div className="inline-block">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_rooms}
                />
              </span>
            </div>
          </div>
          <div className="px-2 xs:py-2 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.RateFood}:&nbsp;
            </h3>
            <div className="inline-block">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_food}
                />
              </span>
            </div>
          </div>
          <div className="px-2 xs:py-2 border-t border-neutral-200 mx-1">
            <h3 className="text-md font-medium capitalize inline-block w-[40%] text-right leading-4">
              {translations?.PicGroupEntertainment}:&nbsp;
            </h3>
            <div className="inline-block">
              <span className="inline-block !mt-0 flex">
                <IconForRating
                  readOnly={true}
                  iconClass="w-4 h-4"
                  className="  space-x-0.2  text-yellow-500"
                  defaultPoint={hotel?.rating_fun}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="listingSection__wrap">
        {/* HEADING Reviews*/}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* Content Reviews
          <div className="space-y-5">
            <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" defaultPoint={3}/>
            <div className="relative">
              <Input
                fontClass=""
                sizeClass="h-16 px-4 py-3"
                rounded="rounded-3xl"
                placeholder="Share your thoughts ..."
              />
              <ButtonCircle
                className="absolute transform -translate-y-1/2 right-2 top-1/2"
                size=" w-12 h-12 "
              >
                <ArrowRightIcon className="w-5 h-5" />
              </ButtonCircle>
            </div>
          </div>
          */}

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800 !mt-4">
          {reviewsForListing
            ? reviewsForListing.map((item, index) => (
                <ReviewListing className="-mx-4 py-4 px-4 bg-white even:bg-neutral-100" key={index} reviewData={item} />
              ))
            : ''}

          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>

      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold mt-1">Post a review:</h2>

        {/* Content */}
        <div>
          <div className="!mt-2 ">
            <div className="pb-4 pt-4 px-2  bg-neutral-100 border-t border-neutral-200 flex items-center">
              <h3 className="text-lg font-semibold inline-block w-[40%] text-right leading-4">Rate the hotel:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-5 h-5" className="space-x-0.2  text-primary-6000" defaultPoint={0} />
              </div>
            </div>

            <div className="py-3 px-2 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Value for money:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
            <div className="px-2 py-3 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Location:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
            <div className="px-2 py-3 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Staff/service:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
            <div className="px-2 py-3 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Rooms / accommodation:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
            <div className="px-2 py-3 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Food:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
            <div className="px-2 py-3 border-t border-neutral-200 mx-1 flex items-center">
              <h3 className="text-md inline-block w-[40%] text-right leading-4">Entertainment:</h3>
              <div className="inline-block  w-[60%] pl-1 flex">
                <IconForRating iconClass="w-4 h-4" className="space-x-0.2  text-yellow-500" defaultPoint={0} />
              </div>
            </div>
          </div>

          <div className="px-2 xs:py-2 !pt-4 border-t border-neutral-200 mx-1 flex items-center flex-col sm:flex-row">
            <h3 className="text-lg font-semibold inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-4 mt-2 sm:mt-0">
              Your name:
            </h3>
            <div className="inline-block  w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2">
              <input
                type="text"
                name="CName"
                id="CName"
                className="h-[38px] w-[100%] sm:w-[18em] border border-neutral-400"
                value=""
              />
            </div>
          </div>
          <div className="px-2 xs:py-2   mx-1 flex items-center flex-col sm:flex-row">
            <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 mt-2 sm:mt-0">
              Type of traveller (optional):
            </h3>
            <div className="inline-block  w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2">
              <select
                name="CTravellerType"
                id="CTravellerType"
                className="py-1  h-[38px] w-[100%] sm:w-[18em] border border-neutral-400"
              >
                <option value="0" selected>
                  please select
                </option>
                <option value="1">business traveller</option>
                <option value="2">alone on a holiday</option>
                <option value="3">a couple on a holiday</option>
                <option value="4">family with children</option>
                <option value="5">friends/company on a holiday</option>
                <option value="6">organised group</option>
                <option value="7">Other</option>
              </select>
            </div>
          </div>
          <div className="px-2 xs:py-2   mx-1 flex items-center flex-col sm:flex-row">
            <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 mt-2 sm:mt-0">
              Date of stay (optional)
            </h3>
            <div className="inline-block  w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2">
              <select
                name="CTravellerType"
                id="CTravellerType"
                className="py-1 h-[38px] w-[100%] sm:w-[18em] border border-neutral-400"
              >
                <option value="0" selected>
                  please select
                </option>
                <option value="2.2023">Feb 2023</option>
                <option value="1.2023">Jan 2023</option>
                <option value="12.2022">Dec 2022</option>
                <option value="11.2022">Nov 2022</option>
                <option value="10.2022">Oct 2022</option>
                <option value="9.2022">Sep 2022</option>
                <option value="8.2022">Aug 2022</option>
                <option value="7.2022">Jul 2022</option>
                <option value="6.2022">Jun 2022</option>
                <option value="5.2022">May 2022</option>
                <option value="4.2022">Apr 2022</option>
                <option value="3.2022">Mar 2022</option>
                <option value="2.2022">Feb 2022</option>
                <option value="1.2022">Jan 2022</option>
                <option value="12.2021">Dec 2021</option>
                <option value="11.2021">Nov 2021</option>
                <option value="10.2021">Oct 2021</option>
                <option value="9.2021">Sep 2021</option>
                <option value="8.2021">Aug 2021</option>
                <option value="7.2021">Jul 2021</option>
                <option value="6.2021">Jun 2021</option>
                <option value="5.2021">May 2021</option>
                <option value="4.2021">Apr 2021</option>
                <option value="3.2021">Mar 2021</option>
              </select>
            </div>
          </div>
          <div className="px-2 xs:py-2  mx-1 flex items-center flex-col sm:flex-row">
            <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 mt-2 sm:mt-0">
              Country (optional):
            </h3>
            <div className="inline-block  w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2">
              <select
                name="CCountry"
                id="CCountry"
                className="py-1  h-[38px] w-[100%] sm:w-[18em] border border-neutral-400"
              >
                <option value="0" selected>
                  please select
                </option>
                <option value="AL">Albania</option>
                <option value="AD">Andorra</option>
                <option value="AM">Armenia</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BR">Brazil</option>
                <option value="BG">Bulgaria</option>
                <option value="CA">Canada</option>
                <option value="CZ">Czech Republic</option>
                <option value="CN">China</option>
                <option value="HR">Croatia</option>
                <option value="CY">Cyprus</option>
                <option value="DK">Denmark</option>
                <option value="EE">Estonia</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GR">Greece</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="IQ">Iran</option>
                <option value="IE">Ireland</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JP">Japan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MK">Macedonia, Republic of</option>
                <option value="MT">Malta</option>
                <option value="MD">Moldova</option>
                <option value="MC">Monaco</option>
                <option value="ME">Montenegro</option>
                <option value="NL">Netherlands</option>
                <option value="NO">Norway</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="SM">San Marino</option>
                <option value="SA">Saudi Arabia</option>
                <option value="RS">Serbia</option>
                <option value="SK">Slovakia</option>
                <option value="SL">Slovenia</option>
                <option value="ZA">South Africa</option>
                <option value="ES">Spain</option>
                <option value="SE">Sweden</option>
                <option value="SZ">Switzerland</option>
                <option value="TH">Thailand</option>
                <option value="TR">Turkey</option>
                <option value="AE">UAE</option>
                <option value="UA">Ukraine</option>
                <option value="GB">United Kingdom</option>
                <option value="US">USA</option>
                <option value="VA">Vatican City</option>
                <option value="--">------</option>
                <option value="OO">Other country</option>
              </select>
            </div>
          </div>
          <div className="px-2 xs:py-2  mx-1 flex items-center flex-col sm:flex-row ">
            <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 mt-2 sm:mt-0">
              Email:
            </h3>
            <div className="inline-block  w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2">
              <input
                type="text"
                name="CEmail"
                id="CEmail"
                className="h-[38px] w-[100%] sm:w-[18em] border border-neutral-400 "
                value=""
              />
            </div>
          </div>
          <div className="pb-4 pt-4 px-4 sm:px-2 mt-4 sm:mt-2 bg-neutral-100">
            <div className="flex items-center flex flex-col sm:flex-row">
              <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 font-semibold">
                Review title (optional):
              </h3>
              <div className="inline-block w-[100%]  sm:w-[60%] mt-2 sm:mt-0 sm:pl-2 ">
                <input
                  type="text"
                  name="CTitle"
                  id="CTitle"
                  className="h-[38px] w-[100%] sm:w-[18em] border border-neutral-400"
                  value=""
                />
              </div>
            </div>
            <div className="flex items-top mt-4 flex-col sm:flex-row">
              <h3 className="text-md  inline-block w-[100%]  sm:w-[40%] text-left sm:text-right leading-5 font-semibold">
                Your review:
              </h3>
              <div className="inline-block  w-[100%]  sm:w-[60%] sm:pl-2 flex">
                <textarea
                  name="CContent"
                  id="CContent"
                  className="border border-neutral-400 h-[100px]  w-[100%] sm:w-[23em]"
                ></textarea>
              </div>
            </div>
            <div className="flex mt-4 ">
              <div className="w-[30px] sm:w-[40%] text-right">
                <input type="checkbox" id="CRecommendHotel" name="CRecommendHotel" value="1" />
              </div>
              <span className="pl-2 font-semibold">I would recommend to a friend</span>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button className="mt-3 mb-3 nc-Button relative h-auto inline-flex items-center justify-center rounded-full  text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonSecondary font-medium border bg-primary-6000 border-neutral-200 text-white !leading-none focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary-6000 ">
              <span>Submit your review</span>
              <i className="ml-3 las la-arrow-right text-xl"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsSection;
