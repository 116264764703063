export const DealToHotelListing = dealData => {
  if (!dealData) return null;

  return {
    id: dealData.id,
    title: dealData?.hotel_data?.name,
    locationTitle: 'TODO VILLAGE',
    mealsIncluded:
      dealData?.min_price_data?.min_price_board_details ??
      dealData?.board_details ??
      dealData?.hotel_data?.board_details,
    galleryImgs: Array.isArray(dealData?.hotel_data?.photos) ? dealData.hotel_data.photos.map(photo => photo.url) : [],
    distances: dealData.distances_line_parts,
    accent: dealData.accent + (dealData?.accent_addon ? ' ' + dealData?.accent_addon : ''),
    description: dealData?.hotel_data?.general,
    location: dealData?.hotel_data?.location,
    displayPrice: dealData?.min_price_data?.display_price,
    displayNodiscountPrice: dealData?.min_price_data?.display_nodiscount_price,
    displayCurrency: dealData?.min_price_data?.display_min_price_currency,
    displayPricePer: '/night',
    displayPriceReplacement: dealData?.short_title,
    recommended: dealData?.hotel_data?.recommended,
    rating: dealData?.hotel_data?.rating_general,
    ratingNumberOfVotes: dealData?.hotel_data?.rating_numrates,
    map: {
      lat: +dealData?.hotel_data?.latitude,
      lng: +dealData?.hotel_data?.longitude,
    },
    address: dealData?.hotel_data?.address_full,
    url: 'todo-deal-url',
  };
};
