import React, { FC, Fragment, useState } from "react";
import { LinkType } from "data/types";

import { hotelTabs } from "./hotelTabs";

import RoomsComponent from "ecomponents/common/RoomsComponent/RoomsComponent";

import RightBookingForm from "./partials/RightBookingForm";
import RightSection from "ecomponents/layout/RightSection/RightSection";
import BackgroundSection from "ecomponents/common/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe from "ecomponents/partials/SectionSubscribe/SectionSubscribe";
import ExpandableText from "ecomponents/ui/ExpandableText/ExpandableText";
import StickyFooterMobileHotel from "ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel";

import TabsLinks from "ecomponents/ui/TabsLinks/TabsLinks";
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { RoomModel } from "features/rooms/room";
import RelatedOffersSection from "./partials/RelatedOffersSection";

export interface HotelPageRoomsProps {
  className?: string;
  isPreviewMode?: boolean;
  tabs?: LinkType[];
    activeTab?: string;
}

const HotelPageRooms: FC<HotelPageRoomsProps> = ({
  className = "",
  isPreviewMode,
  tabs = hotelTabs,
  activeTab = "Rooms"
}) => {

  const translationWords = [
    'VarHotelDetails',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
  console.log('translations babe: ', translations);

  interface VillageLocal {
    caption: string;
  }

  interface HotelLocal {
    name: string;
    children: string;
    rooms_data: RoomModel[]; 
    village_data: VillageLocal;
    accommodation: string;
  }

  const hotelId = 819;

  const hotelFields = [
    'name',
    'children',
    'rooms_data',
    'accommodation',
    { village_data: ['caption'] },
  ];

  const { data: hotel, status: hotelStatuss }: { data: HotelLocal; status: string } = useFetchHotelSelective(hotelId, hotelFields);

  console.log('rooms_data: ', hotel.rooms_data);
  




const [tabActive, setTabActive] = useState<string>(activeTab);
const onClickTab = (item: string) => {
    setTabActive(item);
  };




  return (
    <div
      className={`nc-HotelPageRooms  ${className}`}
      data-nc-id="HotelPageRooms"
    >
     <div className="container relative z-10 mt-11">
     
     <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab}/>
     </div>
      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mt-6 lg:flex-row ">
      
        {/* CONTENT */}
        <div className="w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pr-10">
      
        <div className="listingSection__wrap">
           <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Lion Bansko - Rooms</h2>
           <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

           <div className=" ">
           <p className="text-neutral-700 font-semibold">


          <ExpandableText>
          {hotel?.accommodation}
          </ExpandableText>
           
           </p>
           </div>


           <div className="grid grid-cols-1 gap-6 md:gap-8 mt-8">
              <RoomsComponent />
           </div>
        </div>
       </div>

        

        {/* SIDEBAR */}
        <RightSection >
            <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819}/>

      {/* OTHER SECTION */}
      <RelatedOffersSection/>

    </div>
  );
};

export default HotelPageRooms;
