import {DEMO_CAR_LISTINGS,} from "data/listings";
import DealCard from "ecomponents/common/DealCard/DealCard";
import OfferCard from "ecomponents/common/DealCard/OfferCard";
import ButtonPrimary from "ecomponents/ui/Button/ButtonPrimary";

import { DealModel } from 'features/deals/deal';

import pic1 from 'assets/images/dealspics/1p.jpg';
import pic2 from 'assets/images/dealspics/2p.jpg';
import pic3 from 'assets/images/dealspics/3p.jpg';
import pic4 from 'assets/images/dealspics/4p.jpg';
import pic5 from 'assets/images/dealspics/5p.jpg';
import pic6 from 'assets/images/dealspics/6p.jpg';
import pic7 from 'assets/images/dealspics/7p.jpg';
import pic8 from 'assets/images/dealspics/8p.jpg';
import pic9 from 'assets/images/dealspics/9p.jpg';
import pic10 from 'assets/images/dealspics/10p.jpg';
import pic11 from 'assets/images/dealspics/11p.jpg';
import pic12 from 'assets/images/dealspics/12p.jpg';
import pic13 from 'assets/images/dealspics/13p.jpg';
import pic14 from 'assets/images/dealspics/14p.jpg';
import pic15 from 'assets/images/dealspics/15p.jpg';
import pic16 from 'assets/images/dealspics/16p.jpg';

const dealsImages = [
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    pic11,
    pic12,
    pic13,
    pic14,
    pic15,
    pic16,
  ];

interface hotelDealsProps {
    hotelDeals?: DealModel[],
}

const DealsSection : React.FC<hotelDealsProps> = (props: hotelDealsProps) => {

    console.log('hotelDealsPropss:', props.hotelDeals);

    let dealsProcessed=[];

    if (props?.hotelDeals) {

        dealsProcessed = props.hotelDeals.map( (deal, i, allDeals) => {
           return {
            id: deal?.id,
            deal_type: deal?.deal_type,
            accent: deal?.accent,
            accent_addon: deal?.accent_addon,
            short_title: deal?.short_title,
            overview: deal?.overview,
            period_stay: deal?.period_stay,
            price_overview: deal?.price_overview,
            price_type_id: deal?.pricetype_id,
          };
          })
          
      }

      console.log('dealsProcessed:', dealsProcessed);
   
  return (
   
     
          <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {dealsProcessed.map((deal) => (
                    <OfferCard key={deal.id} dealData={deal} />
                  ))}
                </div>
                
   
  );
};

export default DealsSection;