import axios from 'axios';
import qs from 'qs';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? '/api/',
  paramsSerializer: {
    serialize: params => {
      if (params.filters) params.filters = JSON.stringify(params.filters);
      return qs.stringify(params, { encode: false });
    },
  },
});

export const endPoints = {
  translations: 'translations',
  hotels: 'hotels',
  villages: 'villages',
  htmlPages: 'htmlpages',
  location: 'location',
  transfers: 'transfers',
  skiServices: 'skiservices',
  accommodationPrices: 'accommodationprices',
  bookingPrices: 'bookingprices',
  bookingsHotel: 'hotelbookings',
  bookingsTransfer: 'transferbookings',
  bookingsSki: 'skibookings',
  bookingsTour: 'tourbookings',
};

export const reactQuerySlowChangingConfig = {
  staleTime: 1000 * 60 * 10, // 10 minutes
  cacheTime: 1000 * 60 * 10, // 10 minutes
};
