import React, { FC, Fragment, useState } from 'react';
import { LinkType } from 'data/types';
import { hotelTabs } from './hotelTabs';
import RightBookingForm from './partials/RightBookingForm';
import RightSection from 'ecomponents/layout/RightSection/RightSection';
import StickyFooterMobileHotel from 'ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel';
import ReviewsSection from './partials/ReviewsSection';
import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import { uppercaseFirstLetter } from 'utils';

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import RelatedOffersSection from './partials/RelatedOffersSection';

export interface HotelPageReviewsProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
}

const HotelPageReviews: FC<HotelPageReviewsProps> = ({
  className = '',
  activeTab = '',
  isPreviewMode,
  tabs = hotelTabs,
}) => {
  const translationWords = ['ModeButtonAddress'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['name'];

  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);

  const [tabActive, setTabActive] = useState<string>(activeTab);
  const onClickTab = (item: string) => {
    setTabActive(item);
  };

  return (
    <div className={`nc-HotelPageRooms style-location-page ${className}`} data-nc-id="HotelPageRooms">
      <div className="container relative z-10 mt-11">
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mt-6 lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full  lg:w-3/5 xl:w-2/3  lg:pr-10">
          <div className="listingSection__wrap !border-none">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">LION BANSKO - Rating and Reviews</h2>
            <br />
            <ReviewsSection />
          </div>
        </div>

        {/* SIDEBAR */}
        <RightSection>
          <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819} />

      {/* OTHER SECTION */}
      <RelatedOffersSection />
    </div>
  );
};

export default HotelPageReviews;
