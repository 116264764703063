import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ebNanoId from 'utils/ebNanoId';
import { HotelToHotelListing } from 'data/dataTypes/adapters/HotelToHotelListing';
import useFetchVillageSelective from 'features/villages/hooks/useFetchVillageSelective';

import { DEMO_HOTEL_LISTINGS } from 'data/listings';

import BackgroundGlassmorphism from 'ecomponents/common/BackgroundGlassmorphism/BackgroundGlassmorphism';
import SectionHeroWithSearch from 'ecomponents/partials/SectionHeroWithSearch/SectionHeroWithSearch';
import SectionWebcams2Main from 'ecomponents/partials/SectionWebcams2Main/SectionWebcams2Main';
import ModalScreen from 'ecomponents/common/ModalScreen/ModalScreen';
import ResortInfoSection from './partials/ResortInfoSection';
import SkiInfoSection from './partials/SkiInfoSection';
import HotelListHorizontalWithMap from 'ecomponents/common/HotelListHorizontalWithMap/HotelListHorizontalWithMap';
import { DealToHotelListing } from 'data/dataTypes/adapters/DealToHotelListing';
import HotelListGridCards from 'ecomponents/common/HotelListGridCards/HotelListGridCards';

import './ResortPage.scss';

const DEMO_STAYS = DEMO_HOTEL_LISTINGS.filter((_, i) => i < 12);
export interface ResortPageProps {
  className?: string;
  titlewebcams?: string;
  subtitlewebcams?: string;
}

const ResortPage: FC<ResortPageProps> = ({ className = '' }) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

  const [modalShown2, toggleModal2] = useState<boolean>(false);

  const villageFields = ['hotels_data', 'deals_data', 'url'];
  const { data: village, status: villageStatus } = useFetchVillageSelective(7, villageFields);

  console.log('village hotels_data', village.hotels_data);
  console.log('village deals_data', village.deals_data);

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const hotelsForListing = village?.hotels_data
    ? Object.values(village.hotels_data)
        .filter((_, i) => i < 12)
        .map(hotel => HotelToHotelListing(hotel))
    : [];

  const dealsForListing = village?.deals_data
    ? Object.values(village.deals_data)
        .filter((_, i) => i < 12)
        .map(hotel => DealToHotelListing(hotel))
    : [];

  return (
    <div className={`nc-ListingStayPage relative ${className}`} data-nc-id="ListingStayMapPage">
      <Helmet>
        <title>Bulgaria-Hotels.Com | Resort</title>
      </Helmet>
      <BackgroundGlassmorphism className="md:top-[-6rem] xl:top-[-6rem]" />
      <div className=" px-3  md:px-5  lg:px-7">
        <div className="container">
          {/* SECTION HERO */}
          <SectionHeroWithSearch currentPage="Hotels" currentTab="Hotels" className="pb-12  pt-2 " />
        </div>

        {/* SECTION */}
        <div className="container">
          <HotelListGridCards
            className="pb-24 lg:pb-32"
            headingData={{
              heading: {
                caption: 'Top deals in Pamporovo',
                toUrl: '/resort-page-deals',
              },
              links: [
                {
                  id: ebNanoId(),
                  caption: 'Deals & discounts',
                  toUrl: '/resort-page-deals',
                },
                {
                  id: ebNanoId(),
                  caption: 'Hotels',
                  toUrl: '/resort-page-hotels',
                },
                {
                  id: ebNanoId(),
                  caption: 'All Inclusive',
                  toUrl: '/resort-page-all-inclusive',
                },
                {
                  id: ebNanoId(),
                  caption: 'Apartments',
                  toUrl: '/resort-page-apartments',
                },
                {
                  id: ebNanoId(),
                  caption: 'Early booking',
                  toUrl: '/resort-page-early-booking',
                },
                {
                  id: ebNanoId(),
                  caption: 'Last minute',
                  toUrl: '/resort-page-last-minute',
                },
                {
                  id: ebNanoId(),
                  caption: 'Resort Gallery',
                  toUrl: '/resort-gallery',
                },
              ],
              accentLinks: [
                {
                  id: ebNanoId(),
                  caption: 'Ski packages',
                  toUrl: '/resort-page-ski-packs',
                },
                {
                  id: ebNanoId(),
                  caption: 'Transfers',
                  toUrl: '/resort-page-transfers',
                },
              ],
            }}
            hotelsData={dealsForListing}
          />
        </div>

        {/* SECTION */}
        <div className="container pb-12 lg:pb-16">
          <SectionWebcams2Main
            title="Pamporovo webcams"
            subtitle="7 ski webcams in Pamporovo - Tha Wall slope, Mount Snezhanka, Stoykite, The Dam, The Dam2, Snezhanka 2"
          />
        </div>

        <div className="container  pb-12 lg:pb-15  ">
          <HotelListHorizontalWithMap hotelsData={hotelsForListing} />
        </div>

        <div className="container">
          <button
            onClick={e => {
              toggleModal2(!modalShown2);
              document.body.classList.add('bg-salmon');
            }}
          >
            Show Modal Content
          </button>
          <ModalScreen
            //size="big-modal"
            size="small-modal"
            shown={modalShown2}
            close={() => {
              toggleModal2(false);
              document.body.classList.remove('bg-salmon');
            }}
            modaltitle="Малък Диалогов Прозорец"
          >
            <div className="small-dialog-window">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet dignissim enim. Donec dictum magna
                sit amet odio sagittis, vel auctor nulla commodo. Nam sed est porttitor, dapibus mauris aliquet, gravida
                turpis. Nunc commodo lorem varius nibh volutpat, quis fermentum ligula condimentum. Maecenas maximus
                odio eget suscipit gravida. Etiam imperdiet fermentum lorem non elementum. Donec ligula ipsum, eleifend
                a arcu nec, mollis cursus dui. Morbi vehicula, velit ut maximus iaculis, est magna aliquet ipsum, quis
                porta nunc velit eu lectus. In ac lectus in nibh volutpat tincidunt vitae vitae felis. Donec pharetra
                enim vel felis tincidunt, pellentesque lobortis nisl aliquet. Proin volutpat orci eget pretium
                consequat. Nam semper, libero ut rhoncus mollis, lorem ex tristique ex, eleifend tincidunt felis nunc
                nec dui. Nullam hendrerit, risus sed egestas faucibus, velit diam gravida dolor, non posuere tellus
                purus et enim. Etiam dignissim turpis id sem condimentum, vel lacinia enim facilisis. Fusce vestibulum
                purus et nunc porttitor, in blandit purus consequat.
              </p>
              <div className="bookbutton">
                <Link to="">BOOK NOW</Link>
              </div>
            </div>
          </ModalScreen>
        </div>

        {/* SECTION */}
        <SkiInfoSection />

        <br />
        <br />
        <br />

        <ResortInfoSection />
      </div>
    </div>
  );
};

export default ResortPage;
