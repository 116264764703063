import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';

import { RoomModel } from "features/rooms/room";


import RoomCardHorizontalNew from './RoomCardHorizontalNew';


const RoomsComponent = () => {
    
      interface VillageLocal {
        caption: string;
      }
    
      interface RoomLocal {
        name: string;
        children: string;
        rooms_data: RoomModel[]; 
        village_data: VillageLocal;
        price_includes_free_use_of: string;

    }
    
    const hotelId = 819;

      const hotelFields = [
        'name',
        'children',
        'rooms_data',
        'price_includes_free_use_of',
        { min_price: ['min_price_any']},
        { photos: ['url']},
        { village_data: ['caption'] },
        
      ];
    
      const { data: hotel, status: hotelStatuss }: { data: RoomLocal; status: string } = useFetchHotelSelective(hotelId, hotelFields);
    
      // console.log('rooms_dataaa: ', hotel.rooms_data[0].min_price);

  return  (
<>
{hotel?.rooms_data ? Object.values(hotel.rooms_data).map((item, index) => (
          <RoomCardHorizontalNew 
          key={index} 
          room={item}
          hotelfreeuse={hotel.price_includes_free_use_of}
         
          />
         

        )): ''}

</>
  );
};

export default RoomsComponent;
