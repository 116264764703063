import { useMemo } from 'react';
import useFetchTranslations from './useFetchTranslations';
import { useLanguageStore } from 'store/languageStore/useLanguageStore';
import { pickFromObject } from 'utils';

/**
 * Helper hook that loads only specific words from tranlations data
 * so that we don't store entire tranlations object in every component's state
 * @returns something
 */
export default function useFetchTranslationsSelective(selectedFields = []) {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const { data, status } = useFetchTranslations();
  // const dataPicked = status === 'success' ? pickFromObject(data, selectedFields) : data;
  const dataPicked = useMemo(() => {
    return pickFromObject(data, selectedFields);
  }, [status, activeLanguage]);
  return { data: dataPicked, status };
}
