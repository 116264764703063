import { FC } from 'react';
import StayCardNoUse from 'ecomponents/common/StayCardNoUse/StayCardNoUse';

import { HotelListingDataType } from 'data/dataTypes/HotelListingDataType';
import { HeadingWithLinksType } from 'data/dataTypes/HeadingWithLinksType';
import { dealsImages } from 'const';
import TabFilters from 'ecomponents/common/TabFilters/TabFilters';
import HeadingWithLinks from 'ecomponents/ui/HeadingWithLinks/HeadingWithLinks';
import ButtonPrimary from 'ecomponents/ui/Button/ButtonPrimary';
import HotelCard from '../HotelCard/HotelCard';

export interface SectionGridFilterCardProps {
  className?: string;
  hotelsData?: HotelListingDataType[];
  headingData?: HeadingWithLinksType;
}

const HotelListGridCards: FC<SectionGridFilterCardProps> = ({ className = '', hotelsData = [], headingData = {} }) => {
  return (
    <div className={`nc-HotelListGridCards ${className}`} data-nc-id="HotelListGridCards">
      <HeadingWithLinks {...headingData} />

      {hotelsData.length ? (
        <>
          <div className="mb-8 lg:mb-11 md:-mt-4 mt-4">
            <TabFilters />
          </div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {hotelsData.map((stay, i) => (
              <HotelCard key={stay.id} hotelData={stay} src={dealsImages[i % dealsImages?.length]} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <ButtonPrimary>Show me more</ButtonPrimary>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HotelListGridCards;
