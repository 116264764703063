import { GrCheckmark } from 'react-icons/gr';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { FacilityModel } from 'features/hotels/facility';
import FacilityList from './FacilityList';
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import { Link } from 'react-router-dom';

const FacilitiesSection = () => {
  const translationWords = [
    'ModeButtonKeyFacts',
    'VarHotelFacilities',
    'VarFacilities',
    'VarIn',
    'VarRoomCleaning',
    'VarFoodDining',
    'VarRecreation',
    'VarEntertainment',
    'VarChildServices',
    'VarBusinessFacilities',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  interface VillageLocal {
    caption: string;
  }
  interface HotelLocal {
    name: string;
    hotel_facilities: string;
    food_dining: string;
    business_facilities?: string;
    recreation?: string;
    entertainment?: string;
    children: string;
    hotel_facilities_data?: FacilityModel[];
    village_data: VillageLocal;
  }

  const hotelFields = [
    'name',
    'hotel_facilities',
    'food_dining',
    'business_facilities',
    'recreation',
    'entertainment',
    'children',
    'photos',
    'hotel_facilities_data',
    { village_data: ['caption'] },
  ];
  const { data: hotel, status: hotelStatus }: { data: HotelLocal; status: string } = useFetchHotelSelective(
    819,
    hotelFields,
  );
  // const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
  console.log('hotel facilities:', hotel);

  return (
    <div className="listingSection__wrap">
      <h2 className="text-2xl font-semibold">
        <Link to={'/hotel-page-facilities'}>Facilities in the hotel</Link>
      </h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <p
        className="font-bold"
        dangerouslySetInnerHTML={{
          __html: hotel?.hotel_facilities,
        }}
      />
      <div>
        <FacilityList
          facilities={
            hotel?.hotel_facilities_data
              ? Object.values(hotel?.hotel_facilities_data)
                  .filter(facility => {
                    return facility.section_id === 2;
                  })
                  .filter(f => f.yesno || !f.has_yesno)
              : []
          }
          caption={uppercaseFirstLetter(translations?.VarFacilities) + ' ' + translations?.VarIn + ' ' + hotel?.name}
        />
      </div>
    </div>
  );
};

export default FacilitiesSection;
