import React, { FC, useState } from "react";
import "react-dates/initialize";
import ExperiencesSearchForm from "./ExperiencesSearchForm";
import StaySearchForm from "./StaySearchForm";
import RentalCarSearchForm from "./RentalCarSearchForm";
import FlightSearchForm from "./FlightSearchForm";

export type SearchTab = "Hotels" | "Ski Packages" | "Transfers" | "Flights";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Hotels" | "Ski Packages" | "Transfers" | "Flights";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Hotels",
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Hotels", "Ski Packages", "Transfers", "Flights"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul className="ml-3 flex space-x-1 sm:space-x-1 lg:space-x-2 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 px-3 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? "bg-[#111d4d]  py-2 rounded-3xl text-white"
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              
              <span className="text-base">{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Hotels":
        return <StaySearchForm haveDefaultValue={isArchivePage} />;
      case "Ski Packages":
        return <ExperiencesSearchForm haveDefaultValue={isArchivePage} />;
      case "Transfers":
        return <RentalCarSearchForm haveDefaultValue={isArchivePage} />;
      case "Flights":
        return <FlightSearchForm haveDefaultValue={isArchivePage} />;

      default:
        return null;
    }
  };

  return (
    <div

      className={`nc-HeroSearchForm  md:mt-0 lg:mt-4 xl:mt-12   py-5 sm:py-0 relative z-10 ${ className=='nomarginright' ? "w-full" : "w-full max-w-6xl"} ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
