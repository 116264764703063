import create from "zustand";
import produce, { Draft } from "immer";
import { LanguageType } from "./languageType";
// import { persist } from '../persist';
import { devtools, persist } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

export const defaultLanguage = "en";

export type LanguageStoreType = {
  language: LanguageType;
  setLanguage: (language: string) => void;
};

export const useLanguageStore = create<LanguageStoreType>()(
  devtools(
    persist(
      (set): LanguageStoreType => ({
        language: {
          activeLanguage: defaultLanguage,
        },
        setLanguage: (language: string) =>
          set(
            produce((draft: Draft<LanguageStoreType>) => {
              draft.language.activeLanguage = language;
            })
          ),
      }),
      {
        name: "languageStore",
      }
    )
  )
);

mountStoreDevtool("languageStore", useLanguageStore as any);
