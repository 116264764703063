import { FC, Fragment, useEffect, useState } from 'react';
import { LinkType } from 'data/types';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { hotelTabs } from './hotelTabs';

import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from '_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories';
import StickyFooterMobileHotel from 'ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel';

import RightBookingForm from './partials/RightBookingForm';
import RightSection from 'ecomponents/layout/RightSection/RightSection';

import HotelNameSection from './partials/HotelNameSection';
import GallerySection from './partials/GallerySection';

import DealsSectionNew from './partials/DealsSectionNew';
import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import OverviewSection from './partials/OverviewSection';
import RoomsSection from './partials/RoomsSection';
import FacilitiesSection from './partials/FacilitiesSection';
import ReviewsSection from './partials/ReviewsSection';
import MapSection from './partials/MapSection';
import RelatedOffersSection from './partials/RelatedOffersSection';

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import useFetchHotelsFiltered from 'features/hotels/hooks/useFetchHotelsFiltered';

import './HotelPage.scss';
export interface HotelPageProps {
  className?: string;
  isPreviewMode?: boolean;
  tabs?: LinkType[];
  activeTab?: string;
  hotelName?: string;
}

export interface HotelPageParams {
  resortUrl?: string;
  hotelUrl?: string;
}

const HotelPage: FC<HotelPageProps> = ({
  className = '',
  isPreviewMode,
  tabs = hotelTabs,
  activeTab = 'The Hotel',
  hotelName = '',
}) => {
  const { resortUrl, hotelUrl } = useParams<HotelPageParams>();
  let hotelId = 0;

  const hotelFromUrlFilters = resortUrl && hotelUrl ? { url: hotelUrl, village_url: resortUrl } : null;
  if (hotelFromUrlFilters) {
    const { data: selectedHotelData, status: selectedHotelStatus } = useFetchHotelsFiltered(hotelFromUrlFilters);

    console.log('selectedHotelData: ', selectedHotelData?.data);
    if (selectedHotelData?.data) {
      const selectedHotelId = Object.keys(selectedHotelData.data).reduce((_, hotelId) => {
        return +hotelId;
      }, 0);
      if (selectedHotelId) hotelId = selectedHotelId;
    }
  } else {
    hotelId = 819;
  }

  const translationWords = ['VarHotelDetails', 'AdminDeals'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = [
    'name',
    'photos',
    'rating_general',
    'rating_numrates',
    'hotel_hotel_facilities_data',
    'deals_data',
    'reviews_data',
    'general',
    { village_data: ['caption'] },
  ];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(hotelId, hotelFields);
  console.log('reviews data: ', hotel?.reviews_data);

  const [tabActive, setTabActive] = useState<string>(activeTab);
  const onClickTab = (item: string) => {
    setTabActive(item);
  };

  return (
    <div className={`nc-HotelPage style-hotel-page ${className}`} data-nc-id="HotelPage">
      <div className="container relative z-10 mt-11">
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mt-6 lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pr-10">
          {hotel ? (
            <HotelNameSection
              hotelName={hotel?.name}
              resort={hotel?.village_data?.caption}
              rating={hotel?.rating_general}
              facilities={hotel?.hotel_facilities_data}
              ratingNumrates={hotel?.rating_numrates}
            />
          ) : (
            ''
          )}
          {hotel ? <GallerySection hotelPhotos={hotel?.photos} glalleryGridMode={false} /> : ''}

          {/* HOTEL DEALS */}
          <div></div>

          {hotel?.deals_data ? (
            <>
              <h2 className="text-2xl md:text-3xl font-semibold">
                <Link to={'/hotel-page-prices'}>
                  {hotel?.name} - {translations?.AdminDeals}
                </Link>
              </h2>
              <DealsSectionNew hotelDeals={Object.values(hotel.deals_data)} />
            </>
          ) : (
            ''
          )}

          <RoomsSection />
          <FacilitiesSection />
          <MapSection />
          <ReviewsSection />
        </div>

        {/* SIDEBAR */}
        <RightSection>
          <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819} />

      {/* OTHER SECTION */}
      <RelatedOffersSection />
    </div>
  );
};

export default HotelPage;
