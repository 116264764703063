import GoogleMapReact from "google-map-react";
import MapLocationMarker from "ecomponents/common/MapLocationMarker/MapLocationMarker";

const MapSection = () => {
  return (
    <div className="listingSection__wrap">
    {/* HEADING */}
    <div>
      <h2 className="text-2xl font-semibold">Location</h2>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
      Bulgaria, Bansko 2770, Gramadeto area
      </span>
    </div>
    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

    {/* MAP */}
    <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
      <div className="rounded-xl overflow-hidden">
      <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={15}
          defaultCenter={{
            lat: 41.8252153,
            lng: 23.4809107,
          }}
        >
          <MapLocationMarker lat={41.8252153} lng={23.4809107} />

        </GoogleMapReact>
      </div>
    </div>
  </div>
  );
};

export default MapSection;