import { useQuery } from 'react-query';
import { endPoints, reactQuerySlowChangingConfig } from 'api/api.config';
import { getAllApiLang } from 'api/api.calls';
import { HotelModel } from '../hotel';
import { useLanguageStore } from 'store/languageStore/useLanguageStore';

export default function useFetchHotelsFiltered(filters) {
  console.log('filters: ', filters);

  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  return useQuery(
    'hotels-filtered-' + activeLanguage,
    () => getAllApiLang<HotelModel[]>(endPoints.hotels, activeLanguage, filters),
    reactQuerySlowChangingConfig,
  );
}
