import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CurrencySign } from 'const';
import { apiStatusesCombine, hotelTopAccent } from 'utils';
import { usePathsStore } from 'store/pathsStore/usePathsStore';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';




const EarlyBookingDiscount = () => {
  const localizedPaths = usePathsStore(state => state.paths);
  const [selectedOffer, setSelectedOffer] = useState({
    title: '',
    description: '',
    overview: '',
    accent: '',
    morelink: '',
  });

  const translationWords = [
    'EarlyBookingDiscount',
    'ReadMore',
    'VarOnlyNowHere',
    'VarBetterOfferTellUs',
    'VarLowPriceGuarantee',
    'VarFromMinPrice',
    'VarPerPerson',
    'VarPerNight',
    'VarBookNow',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = [
    'standalone_initiative',
    'standalone_initiative_data',
    'min_price_data',
    'deals_data',
    'morelink',
  ];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);

  useEffect(() => {
    if (hotel && translations) {
      const { offer } = hotelTopAccent(hotel, translations);
      if (offer) offer.morelink = localizedPaths.booking + offer.morelink;
      if (offer) setSelectedOffer(offer);
    }
  }, [hotelStatus, translationsStatus]);

  console.log('selected offer overview', selectedOffer);

  if (apiStatusesCombine(translationsStatus, hotelStatus) !== 'success') return <></>;

  return (



<div className="p-5 ml-0 sm:mr-4 bg-[#111d4d] text-white flex flex-col items-center justify-center text-center space-x-3 rounded-2xl">
                  
                  <span className="text-2xl  font-semibold pt-2">{selectedOffer?.overview}</span>
                    
                    <span>{translations?.VarFromMinPrice}{' '}<b className="inline text-4xl" dangerouslySetInnerHTML={{ __html: hotel?.min_price_data?.display_price }}/> 
                    {' '}{CurrencySign[hotel?.min_price_data?.display_currency]}
                    </span>
  
                    /{translations?.VarPerPerson} {translations?.VarPerNight}
   <br />
  <br />
  <hr />
  <Link to={selectedOffer?.morelink} className="bg-[#f35656] px-3 py-1 rounded-lg font-semibold">{translations?.VarBookNow}
  </Link>
  </div>

  );
};

export default EarlyBookingDiscount;
