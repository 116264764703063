import RoomCardHorizontal, { RoomCardHorizontalProps } from 'ecomponents/common/RoomCardHorizontal/RoomCardHorizontal';
import RoomsComponent from 'ecomponents/common/RoomsComponent/RoomsComponent';
import { Link } from 'react-router-dom';

const DEMO_DATA: RoomCardHorizontalProps['data'][] = [
  {
    id: '1',
    price: '$40',
    roomtype: 'Single Room',
    roomimg: 'https://misha.krusharski.com/bhpics/sr.jpg',
  },
  {
    id: '2',
    price: '$58',
    roomtype: 'Double Room',
    roomimg: 'https://misha.krusharski.com/bhpics/dbr.jpg',
  },
  {
    id: '3',
    price: '$62',
    roomtype: 'Family Room',
    roomimg: 'https://misha.krusharski.com/bhpics/fr.jpg',
  },
  {
    id: '4',
    price: '$89',
    roomtype: 'Apartment',
    roomimg: 'https://misha.krusharski.com/bhpics/app.jpg',
  },
];

const RoomsSection = () => {
  return (
    <div className="space-y-6">
      {/* HEADING */}

      <h2 className="text-3xl font-semibold py-4">
        <Link to={'/hotel-page-rooms'}>Lion Bansko Hotel - Rooms</Link>
      </h2>

      <RoomsComponent />
    </div>
  );
};

export default RoomsSection;
