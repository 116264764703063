import React, { FC, useState } from 'react';
import MapItem from 'ecomponents/common/MapItem/MapItem';
import HotelCardHorizontal from 'ecomponents/common/HotelCardHorizontal/HotelCardHorizontal';
import GoogleMapReact from 'google-map-react';
import Pagination from 'ecomponents/ui/Pagination';
import TabFilters from 'ecomponents/common/TabFilters/TabFilters';
import { HotelListingDataType } from 'data/dataTypes/HotelListingDataType';

export interface HotelListHorizontalWithMapProps {
  hotelsData?: HotelListingDataType[];
  heading?: string;
}

const HotelListHorizontalWithMap: FC<HotelListHorizontalWithMapProps> = ({ hotelsData = [], heading }) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

  if (!hotelsData.length) return <></>;

  return (
    <div>
      <div className=" relative flex min-h-screen mt-5 flex-col xl:flex-row ">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[800px] 2xl:w-[800px] flex-shrink-0  ">
          <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-10 text-neutral-900 dark:text-neutral-50">
            <div>
              <h2 className="text-3xl md:text-4xl font-semibold">{heading}</h2>
            </div>
          </div>
          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>

          <div className="grid grid-cols-1 gap-8 xl:mr-8">
            {hotelsData.map(item => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID(_ => item.id)}
                onMouseLeave={() => setCurrentHoverID(_ => -1)}
              >
                <HotelCardHorizontal hotelData={item} />
              </div>
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
        </div>

        {/* MAPPPPP */}
        <div className={`xl:flex-grow xl:static xl:block`}>
          <div className="block xl:sticky mt-14 xl:top-[88px] left-0 w-full h-[calc(100vh-200px)] xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={hotelsData[0].map}
              bootstrapURLKeys={{
                key: 'AIzaSyAen9pR0g-NvlwajmBCHE50ikCVlHm0Q2E',
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {hotelsData.map(item => (
                <MapItem
                  isSelected={currentHoverID === item.id}
                  key={item.id}
                  lat={item.map.lat}
                  lng={item.map.lng}
                  hotel={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelListHorizontalWithMap;
