
import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from '_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories';

const RelatedOffersSection = () => {

  return (
    
    <div className='container py-24 lg:py-32'>
          {/* SECTION 1 */}
          <div className='relative py-16'>
            <BackgroundSection />
            <SectionSliderNewCategories
              heading='Explore by types of stays'
              subHeading='Explore houses based on 10 types of stays'
              categoryCardType='card5'
              itemPerRow={5}
              sliderStyle='style2'
              uniqueClassName={'HotelPageRooms1'}
            />
          </div>

    </div>

  );
};

export default RelatedOffersSection;
