import React, { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkType } from 'data/types';
import { hotelTabs } from './hotelTabs';
import RightBookingForm from './partials/RightBookingForm';
import RightSection from 'ecomponents/layout/RightSection/RightSection';
import StickyFooterMobileHotel from 'ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel';
import { FacilityModel } from 'features/hotels/facility';
import { HotelModelPhotos } from 'features/hotels/hotel';
import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import RelatedOffersSection from './partials/RelatedOffersSection';
import FacilityList from './partials/FacilityList';
import GallerySection from './partials/GallerySection';
import { photoGroup } from 'utils';
import { PicGroup } from 'const';

import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
export interface HotelPageFootAndDiningProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
}

const HotelPageFootAndDining: FC<HotelPageFootAndDiningProps> = ({
  className = '',
  activeTab = 'Facilities',
  isPreviewMode,
  tabs = hotelTabs,
}) => {
  const translationWords = ['VarHotelFacilities', 'VarFacilities', 'VarIn', 'VarFoodDining'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  interface VillageLocal {
    caption: string;
  }
  interface HotelLocal {
    name: string;
    hotel_facilities: string;
    food_dining: string;
    business_facilities: string;
    children: string;
    photos?: HotelModelPhotos[];
    hotel_facilities_data?: FacilityModel[];
    village_data: VillageLocal;
  }
  const hotelFields = [
    'name',
    'hotel_facilities',
    'food_dining',
    'photos',
    'hotel_facilities_data',
    { village_data: ['caption'] },
  ];
  const { data: hotel, status: hotelStatus }: { data: HotelLocal; status: string } = useFetchHotelSelective(
    250,
    hotelFields,
  );
  console.log('all hotel photos', hotel.photos);

  const [tabActive, setTabActive] = useState<string>(activeTab);
  const onClickTab = (item: string) => {
    setTabActive(item);
  };

  return (
    <div className={`nc-HotelPageRooms facilities-page ${className}`} data-nc-id="HotelPageRooms">
      <div className="container relative z-10 mt-11">
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mt-6 lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full  lg:w-3/5 xl:w-2/3  lg:pr-10">
          <div className="listingSection__wrap ">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">LION BANSKO - Food and Dining</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 3;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
              />
              <section
                className="facilities-section"
                dangerouslySetInnerHTML={{
                  __html: hotel?.food_dining,
                }}
              />
              <br />
              {hotel?.photos ? (
                <GallerySection hotelPhotos={photoGroup(hotel.photos, PicGroup.FOOD)} glalleryGridMode={true} />
              ) : (
                ''
              )}
              <div className="inline-block mt-6">
                <Link
                  to="/hotel-page-facilities"
                  className="sm:mt-0 text-sm py-1 px-3 text-white bg-primary-6000 font-semibold cursor-pointer rounded-full min-w-max h-[30px] flex items-center"
                >
                  Back to All Hotel Facilities
                </Link>
              </div>
            </div>
            <br />
          </div>
        </div>

        {/* SIDEBAR */}
        <RightSection>
          <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819} />

      {/* OTHER SECTION */}
      <RelatedOffersSection />
    </div>
  );
};

export default HotelPageFootAndDining;
