import { useQuery } from "react-query";
import { endPoints, reactQuerySlowChangingConfig } from "api/api.config";
import { getAllApiLang } from "api/api.calls";
import { TransfersModel } from "../transfers";
import { useLanguageStore } from "store/languageStore/useLanguageStore";

export default function useFetchTransfers() {
  const activeLanguage = useLanguageStore((state) => state.language.activeLanguage);
  return useQuery("transfers-" + activeLanguage, () => GetTransfers(activeLanguage), reactQuerySlowChangingConfig);
}

/**
 * Helper function that returns response.data only
 * @returns something
 */
async function GetTransfers(language: string) {
  const promise = getAllApiLang<TransfersModel>(endPoints.transfers, language);

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
}
