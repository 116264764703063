import React, { FC, ReactNode, useState } from "react";
import CardCategoryBox1 from "_unused-components-delete/components/CardCategoryBox1/CardCategoryBox1";
import Heading from "ecomponents/ui/Heading/HeadingTemp";
import { TaxonomyType } from "data/types";
import TabsFilter from "ecomponents/ui/TabsFilter/TabsFilter";



export interface SectionGridResortsBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
  tabs?: string[];
  activeTab?: string;

}

const DEMO_CATS: TaxonomyType[] = [

  {
    id: "1",
    href: "/resort-page",
    name: "Bansko",
    taxonomy: "category",
    count: 188288,
    thumbnail: 'https://misha.krusharski.com/bhpics/bansko.jpg',
    shortText: '72 hotels',
    priceFrom: 'from 39 euro',
  },
  {
    id: "2",
    href: "/resort-page",
    name: "Borovets",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://misha.krusharski.com/bhpics/borovets5.jpg",
      shortText: '72 hotels',
      priceFrom: 'from 72 euro',
  },
  {
    id: "2",
    href: "/resort-page",
    name: "Pamporovo",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://misha.krusharski.com/bhpics/pamporovo-zima.jpg",
      shortText: '72 hotels',
      priceFrom: 'from 41 euro',
  },
  {
    id: "2",
    href: "/resort-page",
    name: "Chepelare",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://misha.krusharski.com/bhpics/chepelare5.jpg",
      shortText: '72 hotels',
      priceFrom: 'from 44 euro',
  },
  {
    id: "2",
    href: "/resort-page",
    name: "Dobrinishte",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://misha.krusharski.com/bhpics/dobrinishte1.jpg",
      shortText: '72 hotels',
      priceFrom: 'from 57 euro',
  },
];

const SectionGridResortsBox: React.FC<SectionGridResortsBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
  tabs = ["Bansko", "Borovets", "Pamporovo", "Chepelare"],
  activeTab = "Bansko"

}) => {


  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  const [tabActive, setTabActive] = useState<string>(activeTab);
  const onClickTab = (item: string) => {
    setTabActive(item);
  };


  return (
    <div className={`nc-SectionGridResortsBox relative ${className}`}>
      <Heading
        desc="23 resorts with more than 700 accomodations"
        isCenter={headingCenter}
      >
        Resorts in Bulgaria
      </Heading>

      <div className="flex flex-col items-center justify-between mb-8">
        
        
      <TabsFilter  tabActive={tabActive} tabs={tabs} onClickTab={onClickTab}/>
      

      </div>
      



      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {categories.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridResortsBox;
