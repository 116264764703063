import React, { FC, Fragment, useState } from 'react';
import { LinkType } from 'data/types';

import { hotelTabs } from './hotelTabs';

import ImageSlider from 'ecomponents/ui/ImageSlider/ImageSlider';
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';
import FacilityList from './partials/FacilityList';
import StickyFooterMobileHotel from 'ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel';
import GallerySection from './partials/GallerySection';
import RightBookingForm from './partials/RightBookingForm';
import RightSection from 'ecomponents/layout/RightSection/RightSection';
import { Link } from 'react-router-dom';
import { apiStatusesCombine } from 'utils/apiStatusesCombine';

import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { FacilityModel } from 'features/hotels/facility';
import { HotelModelPhotos } from 'features/hotels/hotel';
import RelatedOffersSection from './partials/RelatedOffersSection';

export interface HotelPageFacilitiesProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
}

const HotelPageFacilities: FC<HotelPageFacilitiesProps> = ({
  className = '',
  activeTab = 'Facilities',
  isPreviewMode,
  tabs = hotelTabs,
}) => {
  const translationWords = [
    'ModeButtonKeyFacts',
    'VarHotelFacilities',
    'VarFacilities',
    'VarIn',
    'VarRoomCleaning',
    'VarFoodDining',
    'VarRecreation',
    'VarEntertainment',
    'VarChildServices',
    'VarBusinessFacilities',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  interface VillageLocal {
    caption: string;
  }
  interface HotelLocal {
    name: string;
    hotel_facilities: string;
    food_dining: string;
    business_facilities?: string;
    recreation?: string;
    entertainment?: string;
    children: string;
    photos?: HotelModelPhotos[];
    hotel_facilities_data?: FacilityModel[];
    village_data: VillageLocal;
  }

  const hotelFields = [
    'name',
    'hotel_facilities',
    'food_dining',
    'business_facilities',
    'recreation',
    'entertainment',
    'children',
    'photos',
    'hotel_facilities_data',
    { village_data: ['caption'] },
  ];
  const { data: hotel, status: hotelStatus }: { data: HotelLocal; status: string } = useFetchHotelSelective(
    250,
    hotelFields,
  );
  // const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);
  console.log('hotel facilities:', hotel);
  const [tabActive, setTabActive] = useState<string>(activeTab);
  const onClickTab = (item: string) => {
    setTabActive(item);
  };

  const sliderScenes = hotel?.photos
    ? Object.values(hotel?.photos)
        .filter(photo => {
          return parseFloat(photo.priority) > 0 && photo.group_id > 100;
        })
        .map(photo => {
          return photo.url;
        })
    : [];

  if (apiStatusesCombine(translationsStatus, hotelStatus) !== 'success') return <></>;
  return (
    <div className={`nc-HotelPageRooms facilities-page ${className}`} data-nc-id="HotelPageRooms">
      <div className="container relative z-10 mt-11">
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <main className="container relative z-10 flex flex-col mt-6 lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full  lg:w-3/5 xl:w-2/3  lg:pr-10">
          <div className="listingSection__wrap">
            {hotel ? (
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {hotel?.name} - {translations?.ModeButtonKeyFacts}
              </h2>
            ) : (
              ''
            )}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <span className="inline-block text-neutral-500  dark:text-neutral-400 mt-3">
              <Link
                to={'/hotel-page-facilities-food-dining'}
                className={'inline-block underline hover:text-primary-6000'}
              >
                Food and dining
              </Link>
              <span className="mx-2">·</span>
              <Link to={'/hotel-page-facilities-business'} className={'inline-block underline hover:text-primary-6000'}>
                Business Facilities
              </Link>
              <span className="mx-2">·</span>
              <Link
                to={'/hotel-page-facilities-recreation'}
                className={'inline-block underline hover:text-primary-6000'}
              >
                Recreation
              </Link>
              <span className="mx-2">·</span>
              <Link
                to={'/hotel-page-facilities-entertainment'}
                className={'inline-block underline hover:text-primary-6000'}
              >
                Entartainment
              </Link>
              <span className="mx-2">·</span>
              <Link
                to={'/hotel-page-facilities-night-life'}
                className={'inline-block underline hover:text-primary-6000'}
              >
                Night Life
              </Link>
              <span className="mx-2">·</span>
              <Link to={'/hotel-page-photos'} className={'inline-block underline hover:text-primary-6000'}>
                Gallery
              </Link>
              <span className="mx-2">·</span>
            </span>

            {hotel?.photos ? (
              <GallerySection
                hotelPhotos={hotel?.photos.filter(photo => photo.group_id > 100)}
                glalleryGridMode={false}
              />
            ) : (
              ''
            )}

            <div className="">
              {/*<div className="flex justify-center">
                <span className="inline-block w-[100%] sm:w-[400px]">
                  <ImageSlider
                    ratioClass=" aspect-w-6 aspect-h-5"
                    galleryImgs={sliderScenes}
                    uniqueID={`RoomCardHorizontalOld`}
                  />
                </span>
              </div>
              <br />
              <br />*/}
              <p
                className="font-bold"
                dangerouslySetInnerHTML={{
                  __html: hotel?.hotel_facilities,
                }}
              />

              <div className="ml-0 mt-6 sm:ml-8 sm:mt-0 flex justify-center items-center"></div>
            </div>
            <div className="">
              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 2;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                url={'/hotel-page-facilities'}
                caption={
                  uppercaseFirstLetter(translations?.VarFacilities) + ' ' + translations?.VarIn + ' ' + hotel?.name
                }
              />

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 4;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={uppercaseFirstLetter(translations?.VarRoomCleaning)}
              />

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 3;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={uppercaseFirstLetter(translations?.VarFoodDining)}
                url={'/hotel-page-facilities-food-dining'}
              />

              <section
                className="facilities-section"
                dangerouslySetInnerHTML={{
                  __html: hotel?.food_dining,
                }}
              />
              {hotel?.food_dining ? <hr className="my-6" /> : ''}

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 7;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={uppercaseFirstLetter(translations?.VarRecreation)}
                url={'/hotel-page-facilities-recreation'}
              />
              <section
                className="facilities-section"
                dangerouslySetInnerHTML={{
                  __html: hotel?.recreation,
                }}
              />
              {hotel?.recreation ? <hr className="my-6" /> : ''}

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 8;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={uppercaseFirstLetter(translations?.VarEntertainment)}
                url={'/hotel-page-facilities-entertainment'}
              />
              <section
                className="facilities-section"
                dangerouslySetInnerHTML={{
                  __html: hotel?.entertainment,
                }}
              />
              {hotel?.entertainment ? <hr className="my-6" /> : ''}

              {hotel?.business_facilities ? (
                <>
                  <h2 className="text-2xl font-semibold mb-4">
                    <Link to="/hotel-page-facilities-business">
                      {uppercaseFirstLetter(translations?.VarBusinessFacilities)}
                    </Link>
                  </h2>
                  <section
                    dangerouslySetInnerHTML={{
                      __html: hotel?.business_facilities,
                    }}
                  />
                </>
              ) : (
                ''
              )}
              {hotel?.business_facilities ? <hr className="my-6" /> : ''}

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 5;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={uppercaseFirstLetter(translations?.VarChildServices)}
              />

              <section
                className="facilities-section"
                dangerouslySetInnerHTML={{
                  __html: hotel?.children,
                }}
              />
              {hotel?.children ? <hr className="my-6" /> : ''}

              <FacilityList
                facilities={
                  hotel?.hotel_facilities_data
                    ? Object.values(hotel?.hotel_facilities_data)
                        .filter(facility => {
                          return facility.section_id === 6;
                        })
                        .filter(f => f.yesno || !f.has_yesno)
                    : []
                }
                caption={'Pets'}
              />

              <br />
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <RightSection>
          <RightBookingForm />
        </RightSection>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819} />

      {/* OTHER SECTION */}
      <RelatedOffersSection />
    </div>
  );
};

export default HotelPageFacilities;
