import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import { Link } from "react-router-dom";
import  { FC, useState } from "react";
import { Facility, facilityIcons } from 'const/facilities';
import { uppercaseFirstLetter } from 'utils/uppercaseFirstLetter';
import { singleOrPlural, lowercaseFirstLetter } from 'utils';
import {  GiFlowerPot } from 'react-icons/gi';
import {  IoWifiOutline } from 'react-icons/io5';
import {  IoMdCheckmark } from 'react-icons/io';
import { RiParkingBoxLine } from "react-icons/ri";
import { extractFacility } from 'utils/datahelpers/extractFacility';
import { RoomModel } from "features/rooms/room";
import { CurrencySign } from 'const';



export interface RoomCardHorizontalNewProps {
    className?: string;
    key?: number;
    room: RoomModel;
    hotelfreeuse?: string;
   
  }

const RoomCardHorizontalNew: FC<RoomCardHorizontalNewProps> = ({ className = "", room, hotelfreeuse }) => {
  const [isOpen, setIsOpen] = useState(false);

  const translationWords = [
    'VarAdults',
    'VarAdult',
    'VarChild',
    'VarChildren',
    'VarRoomFacilities',
    'VarPriceInclFreeUse',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
  console.log('room-all-data: ', room);

    const viralFacilities = {
    size: null,
    balcony: null,
    airconditioning: null,
    television: null,
    minibar: null,
  };

  if (room?.facilities) {
    viralFacilities.size = extractFacility(room.facilities, Facility.ROOM_SIZE);
    viralFacilities.balcony = extractFacility(room.facilities, Facility.BALCONY);
    viralFacilities.airconditioning = extractFacility(room.facilities, Facility.AIRCONDITIONING);
    viralFacilities.television = extractFacility(room.facilities, Facility.TELEVISION);
    viralFacilities.minibar = extractFacility(room.facilities, Facility.MINIBAR);
  }

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
 
      <div className="flex flex-col justify-center border-t-[1px] pt-6">

                  <div className="room-facilities-icons">
   
                  {viralFacilities.size?.details ? (
                <span className="inline-block mr-2">
                  <i className="inline-block align-middle">{facilityIcons[viralFacilities.size.id]}</i> {uppercaseFirstLetter(viralFacilities.size?.details)}
                  {'m²;'}
                </span>
              ) : (
                ''
              )}
              {viralFacilities.balcony ? (
                <span className="inline-block mr-2">
                  <i className="inline-block align-middle">{facilityIcons[viralFacilities.balcony.id]}</i> {uppercaseFirstLetter(viralFacilities.balcony?.caption)}
                  {';'}
                </span>
              ) : (
                ''
              )}
              {viralFacilities.airconditioning ? (
                <span className="inline-block mr-2">
                  <i className="inline-block align-middle">{facilityIcons[viralFacilities.airconditioning.id]}</i>{' '}
                  {uppercaseFirstLetter(viralFacilities.airconditioning?.caption)}
                  {';'}
                </span>
              ) : (
                ''
              )}

              {viralFacilities.television ? (
                <span className="inline-block mr-2">
                   <i className="inline-block align-middle">{facilityIcons[viralFacilities.television.id]}</i>{' '}
                  {uppercaseFirstLetter(viralFacilities.television?.caption)}
                  {';'}
                </span>
              ) : (
                ''
              )}

              {viralFacilities.minibar ? (
                <span className="inline-block mr-2">
                  <i className="inline-block align-middle">{facilityIcons[viralFacilities.minibar.id]}</i> {uppercaseFirstLetter(viralFacilities.minibar?.caption)}
                  {';'}
                </span>
              ) : (
                ''
              )}

 <span className="inline-block mr-2">
   <GiFlowerPot  className="inline-block align-middle"/> Garden view;
 </span>

 <span className="inline-block mr-2">
   <IoWifiOutline  className="inline-block align-middle"/> Free WiFi;
 </span>

 <span className="inline-block mr-2">
   <RiParkingBoxLine  className="inline-block align-middle"/> Free parking
 </span>
</div>
<div className="mt-6">        
                     <h2 className="text-xl font-bold mb-4">{translations?.VarRoomFacilities}</h2>
                     <p className="font-bold"> {translations?.VarPriceInclFreeUse}{': '} {hotelfreeuse}
                     </p>
                     <br />
                     <p className='leading-6 font-semibold'>
     {room?.facilities
                ? room.facilities.map((facility, i) => {
                    if (facility.yesno || (!facility.has_yesno && facility.has_type && Number(facility.type_caption) !== 0)) {
                      return (
                        <span className="inline-block">
                            <i className="inline-block">
                          <IoMdCheckmark />
                          </i>{' '}
                            {uppercaseFirstLetter(facility?.caption)}{' '}{facility.type_caption ? ' (' + facility.type_caption.toString().toLowerCase() + ')' : ''},
                            
                         
                            {' '}</span>
                      );
                    }
                  })
                : ''}
            
                     </p>
                    </div>
                    <div className="flex justify-center mt-5 mb-1">
                    <Link to="/hotel-page-rooms-room" className="rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  ">Room Full Info</Link>
                    </div>
      </div>

                 
    );
  };

  return (

    
    <div
      className={`nc-RoomCardHorizontalgroup p-4 sm:p-6 relative  border border-neutral-200
      rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 bg-white hover:bg-neutral-100 ${className}`}
      data-nc-id="RoomCardHorizontal"
    >
      <div
        className={` ${className}`}
        data-nc-id="RoomCardHorizontal"
      >


        

        <div className="flex  flex-col sm:flex-row sm:items-center  space-y-6 sm:space-y-0"  >
          {/* LOGO IMG */}



          <div className="w-full flex items-center ">
          <div className="w-2/6 flex-shrink-0" onClick={() => setIsOpen(!isOpen)}>
          <span className="block h-0 aspect-w-1 aspect-h-1 sm:aspect-w-12 sm:aspect-h-9 relative rounded-full sm:rounded-2xl overflow-hidden shadow-lg " >
            <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                {room.photos[0]?.url ? <img src={room.photos[0].url} className="object-cover w-full h-full " alt=""/>: 'NO PIC'}
            </div>
            </span>
          
           
          </div>
          <div className="flex  w-full flex-col justify-between ">
          <div  className="flex flex-col w-full sm:flex-row sm:mt-8">
             <span className="flex flex-col flex-grow  cursor-pointer ml-4" onClick={() => setIsOpen(!isOpen)}>
         
       <h2 className="nc-card-title block font-semibold text-2xl leading-6 mb-2 hover:text-black capitalize">{room.name}</h2>
            <div className="sm:grid grid-cols-1 gap-2 ">
                <div className="space-y-1 sm:space-y-3">
                <div className="flex items-center space-x-1">
                   <i className="las la-user text-lg "></i>
                   <span className="text-sm ">
                   {room.max_adults}{' '}
                {singleOrPlural(
                  lowercaseFirstLetter(translations?.VarAdult),
                  lowercaseFirstLetter(translations?.VarAdults),
                  room.max_adults,
                )}{' '}
                {room.max_children > 0
                  ? ' + ' +
                  room.max_children +
                    ' ' +
                    singleOrPlural(
                      lowercaseFirstLetter(translations?.VarChild),
                      lowercaseFirstLetter(translations?.VarChildren),
                      room.max_children,
                    ) +
                    '  '
                  : ' '}
                {room.max_adults_only != room.max_adults
                  ? ' ' +
                    translations?.VarOr +
                    ' ' +
                    room.max_adults_only +
                    ' ' +
                    singleOrPlural(
                      lowercaseFirstLetter(translations?.VarAdult),
                      lowercaseFirstLetter(translations?.VarAdults),
                      room.max_adults_only,
                    )
                  : ' '}
                   </span>
                </div>
                </div>
            </div>

             </span>
             {/* PRICE */}
             <span className="flex whitespace-nowrap sm:text-right cursor-pointer pl-4"  onClick={() => setIsOpen(!isOpen)}>
            <div className="flex flex-row items-center sm:flex-col">
           
         
            <span className="!inline-block sm:!hidden"><i className="las la-money-bill text-lg "></i>&nbsp;</span>
         <span className="text-sm ">
         from <span className="hidden sm:inline" style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>
            {room.min_price.display_price !== Number(room.min_price.display_nodiscount_price) ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          ' ' +
                          room.min_price.display_nodiscount_price +
                          CurrencySign[room.min_price.display_currency] +
                          ' ',
                      }}
                    />
                  ) : (
                    ''
                  )}</span> 
         </span>
       
              <span className="text-xl font-semibold text-red-600">
            
               
              <span
                    dangerouslySetInnerHTML={{
                      __html: ' ' + room.min_price.display_price + CurrencySign[room.min_price.display_currency] + ' ',
                    }}
                  /> 
                 
                
                 
         
              </span>
              <span className="text-xs sm:text-sm font-normal mt-0.5">
               {' '}/ night
            </span>
            </div>
            
             </span>
          </div>
          <div className="ml-4 flex  items-center">
            <span className="text-primary-6000 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
              { isOpen ? '' : 'read more'}
              </span>
          <span
          className={`ml-2 w-10 h-10  rounded-full flex items-center justify-center cursor-pointer bg-primary-6000 text-neutral-50 ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down "></i>
           </span>
           </div>
           
          </div>




          </div>









          
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default RoomCardHorizontalNew;
