import rightImg from 'images/about-hero-right.png';
import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionQuickFacts from 'ecomponents/partials/SectionQuickFacts/SectionQuickFacts';
import { Helmet } from 'react-helmet';
import SectionSubscribe from 'ecomponents/partials/SectionSubscribe/SectionSubscribe';
import BackgroundGlassmorphism from 'ecomponents/common/BackgroundGlassmorphism/BackgroundGlassmorphism';
import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionHero from './SectionHero';
import SectionClientSay from '_unused-components-delete/components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet>
        <title>About || Bulgaria-Hotels.com</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BackgroundGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionQuickFacts />

        <SectionSubscribe />
      </div>
    </div>
  );
};

export default PageAbout;
