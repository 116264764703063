import { useQuery } from "react-query";
import { endPoints, reactQuerySlowChangingConfig } from "api/api.config";
import { getApiLang } from "api/api.calls";
import { VillageModel } from "../village";
import { useLanguageStore } from "store/languageStore/useLanguageStore";
import { usePersonalDataStore } from "store/personalDataStore/usePersonalDataStore";

/*This function won't send an http request if not necessary.
 * So we can use this function to sync states in different components
 * */
export default function useFetchVillage(id: number = -1) {
  const activeLanguage = useLanguageStore((state) => state.language.activeLanguage);
  const personalData = usePersonalDataStore((state) => state.personalData);

  const r = useQuery("village-" + id + "-" + activeLanguage + (personalData?.myCountryCode ? "-" + personalData?.myCountryCode : ""), () => GetVillage(id, activeLanguage, personalData?.myCountryCode), reactQuerySlowChangingConfig);

  return r;
}

/**
 * Helper function that returns response.data only
 * @returns something
 */
async function GetVillage(id: number, language: string, myCountryCode?: string) {
  const promise = getApiLang<VillageModel>(
    endPoints.villages,
    id,
    language,
    "*",
    myCountryCode
      ? {
          myCountryCode,
        }
      : null
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
}
