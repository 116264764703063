import { locationTypeIcons } from 'const/locationtypes';
import { uppercaseFirstLetter } from 'utils';


interface HotelDistansesProps {
    generalInfo?: string,
    facilities?: object,
}


const HotelDistanses : React.FC<HotelDistansesProps> = (props: HotelDistansesProps) => {

console.log('props facilities loc2', props.facilities)
    return (


           
           <>
            
      {props.facilities
        ? Object.entries(props.facilities)
            .filter(([locationId, location]) => {
              return location.section_id === 1;
            })
            .map(([locationId, location]) => <span key={locationId}>
             {locationTypeIcons[location.id] ?? locationTypeIcons[0]}{' '}{uppercaseFirstLetter(location?.caption)}:{' '}{location?.details}
           <br />
         </span>)
        : ''}{' '}
    </>



      );
};

export default HotelDistanses;