import React, { FC, useState } from 'react';
import { ReactNode } from "react";
import './ExpandableText.scss';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';



export interface ExpandableTextProps {
    className?: string;
    children?: ReactNode;
  }

const ExpandableText : FC<ExpandableTextProps> = ({
    className = '',
    children
  }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="read-more">
      <div
        className={isReadMore ? 'not-expanded-text ' : 'expanded-text'} 
      >{children}</div>
      <p className="read-more-text grow ">
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? ( <> ... 
            <span className="block read-more-button">
              
             
              <button className={className === 'redbutton'? "my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4": "mt-2 inline-flex items-center justify-center rounded-lg py-0.5 px-1.5  text-sm   bg-primary-6000  text-neutral-50"}>Read More</button>
             
            </span>
            </>
          ) : (
            <span className="block  read-more-button">
             
             
              <button className={className === 'redbutton'? "my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4": "mt-2 inline-flex items-center justify-center rounded-lg py-0.5 px-1.5  text-sm   bg-primary-6000  text-neutral-50"}>Read Less</button>
              
            </span>
          )}
        </span>
      </p>
    </div>
  );
};

export default ExpandableText;
