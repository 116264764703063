import React, { FC, useEffect, useState } from "react";
import { LinkType } from "data/types";
import Nav from "ecomponents/layout/Nav/Nav";
import NavItem from "ecomponents/layout/NavItem/NavItem";
import NavItemLink from "ecomponents/layout/NavItemLink/NavItemLink";

export interface TabsLinksProps {
  tabActive: string;
  tabs: LinkType[];
  onClickTab: (item: string) => void;
}

const TabsLinks: FC<TabsLinksProps> = ({
  tabActive,
  tabs,
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  console.log('TabsFilter tabs: ', tabs);
  

  return (
        <Nav
          className="!flex-col sm:!flex-row sm:space-x-2"
          containerClassName=""
        >
          {tabs.map((item, index) => (
            <NavItemLink
              key={index}
              isActive={tabActiveState === item.caption}
              onClick={() => handleClickTab(item.caption)}
              toUrl={item.toUrl}
            >
              {item.caption}
            </NavItemLink>
          ))}
        </Nav>
  );
};

export default TabsLinks;
