import  { FC, ReactNode, useState, useEffect   } from "react";

import { Helmet } from "react-helmet";

import BackgroundGlassmorphism from "ecomponents/common/BackgroundGlassmorphism/BackgroundGlassmorphism";

import { apiStatusesCombine } from 'utils';
import { transfersTableContent, defaultTransfersTableContent } from 'utils';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import useFetchTransfers from 'features/transfers/hooks/useFetchTransfers';

import ExpandableText from "ecomponents/ui/ExpandableText/ExpandableText";
import RightSection from "ecomponents/layout/RightSection/RightSection";
import RightSearchFormTransfers from "ecomponents/forms/HeroSearchForm/RightSearchForms/RightSearchFormTransfers";
import RightBookingForm from "../HotelPage/partials/RightBookingForm";

import HeroSearchForm, {
    SearchTab,
  } from "ecomponents/forms/HeroSearchForm/HeroSearchForm";
import ResponsiveTable from "ecomponents/common/ResponsiveTable/ResponsiveTable";


export interface ResortTransfersPageProps  {
    className?: string;
    listingType?: ReactNode;
    currentPage: "Hotels" | "Ski Packages" | "Transfers" | "Flights";
    currentTab: SearchTab;
    rightImage?: string;
  }
  
  const ResortTransfersPage: FC<ResortTransfersPageProps > = ({
    className = "",
    listingType,
    currentPage,
    currentTab,

  }) => {

    const translationWords = ['VarTransfer', 'VarTransfers', 'VarAirportHotelTransfers', 'Varask'];
    const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

    const hotelFields = ['name', { village_data: ['caption', 'transfer_location_id'] }];
    const { data: hotel, status: hotelStatus } = useFetchHotelSelective(819, hotelFields);


    const { data: transfers, status: transfersStatus } = useFetchTransfers();
    console.log('transfers notes: ', transfers?.['transferprice-notes']?.html);

    const [responsiveTableContent, setResponsiveTableContent] = useState(defaultTransfersTableContent);
  
    useEffect(() => {
    if (apiStatusesCombine(hotelStatus, translationsStatus, transfersStatus) === 'success') {
        const responsiveTableContentTransfers = transfersTableContent(transfers, hotel, translations);
        setResponsiveTableContent(responsiveTableContentTransfers);
      }
    }, [transfersStatus, hotelStatus, translationsStatus]);

  return (
    <div
    className={`nc-ListingStayPage relative ${className}`}
    data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Bulgaria-Hotels.Com | Resort</title>
      </Helmet>
      {/*<BackgroundGlassmorphism className="md:top-[-10rem] xl:top-[-10rem]"/>*/}
      <div className=" px-3  md:px-5  lg:px-7">
      <div className="container">
        {/* SECTION HERO */}
       
 
        <div className="flow-root w-full">
        <div className="z-10  w-full">
            
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} className="nomarginright"/>
        </div>
        <br />
      </div>
    

        </div>

      </div>

      <main className="container relative flex flex-col mt-6 lg:flex-row ">
        <div className="w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pr-10">
            <div className="listingSection__wrap transfer-page-text">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Bansko Transfers</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <ExpandableText className="">
                    <p>Our transfers are <b>private and exclusive with clean, comfortable, up to standard and insured vehicles with well experienced drivers</b>.</p>
                    <p>The return pick up time is set automatically by our reservation system according to the flight details you supplied. Your transfer pick up time may be changed by our representatives in case of force major situations like blocked roads, heavy snowfall etc. You will be contacted in advance  in case we are forced to change the pick up time. <b>Please, supply a working mobile phone number</b>.</p>
                    <p>The transfer price is based on the number of passengers + 1 standard suitcase ( 90 /70 cm) + 1 holdall per passenger. <b>In case you have more luggage than 1 suitcase per person, such as ski/snowboard equipment, please, book a bigger vehicle for your comfort</b> and let us know the number of suitcases and ski/snowboard equipment. Child seats are available for free if specially requested. Please, specify the age and weight of the child.</p>
                </ExpandableText>

                <br />
              
                <ResponsiveTable {...responsiveTableContent} EmptyCellContent={translations?.Varask} />
                

                <div className="transfer-notes" dangerouslySetInnerHTML={{
          __html: transfers?.['transferprice-notes']?.html,
        }}/>
                 <br /> 
            </div>
        </div>
         {/* SIDEBAR */}
         <RightSection >
            <RightSearchFormTransfers />
        </RightSection>
        
      </main>
      <br /><br /><br /><br />
    </div>
  );
};

export default ResortTransfersPage;
