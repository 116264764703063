import React, { FC } from "react";
import Badge from "ecomponents/ui/Badge/Badge";
import SaleOffBadge from "ecomponents/ui/SaleOffBadge/SaleOffBadge";
import NcImage from "ecomponents/ui/NcImage/NcImage";
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';




interface DealData {
    id: string;
    deal_type: string;
    accent: string;
    accent_addon: string;
    short_title: string;
    overview: string;
    period_stay: string;
    price_overview: string;
    price_type_id: string;
    pic: string;
}
interface OfferCardProps {
    dealData?: DealData;
    size?: "default" | "small",
}

const OfferCard: FC<OfferCardProps> = (
   props: OfferCardProps
  ) => {
    
    const translationWords = [
        'VarBookNow',
      ];
      const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

 
 const size = "default";


  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
      
          <div className="flex items-center justify-center space-x-2">
          
            <h2
              className={` pt-4  capitalize text-white text-center ${
                size === "default"
                  ? "text-2xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className=" text-white">{props?.dealData?.short_title} </span>
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center text-neutral-500 text-md space-x-2">
            <span className="text-white text-center">{props?.dealData?.period_stay}</span>
            <span className="text-white text-center" dangerouslySetInnerHTML={{ __html: props?.dealData?.price_overview }}></span>
            
          </div>
        </div>
        
        <div className="flex justify-center items-center ">
          
          <a href="#" className="my-2 bg-[#f35656] px-3 py-2 rounded-lg font-semibold text-white text-center leading-4 uppercase" >{translations?.VarBookNow}</a>
          
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-DealCard group relative border border-neutral-200 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow  will-change-transform bg-primary-6000`}
      data-nc-id="DealCard"
    >
      <a href="" className="flex flex-col">
   
        {renderContent()}
      </a>
    </div>
  );
};

export default OfferCard;
