import { useMemo } from "react";
import useFetchHotel from "./useFetchHotel";
import { useLanguageStore } from "store/languageStore/useLanguageStore";
import { pickFromObject } from "utils";

/**
 * Helper hook that loads only specific fields from hotel data
 * so that we don't store entire hotel object in every component's state
 * @returns something
 */
export default function useFetchHotelSelective(id: number = -1, selectedFields = []) {
  const activeLanguage = useLanguageStore((state) => state.language.activeLanguage);
  const { data, status } = useFetchHotel(id);

  // useMemo to avoid infinite recreation of hotel object
  const dataPicked = useMemo(() => {
    return pickFromObject(data, selectedFields);
  }, [status, activeLanguage]);

  return { data: dataPicked, status };
}
