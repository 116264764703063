import { GrClose, GrFormClose } from 'react-icons/gr';

import './ModalScreen.scss';

const ModalScreen = ({ children, shown, close, size = 'big-modal', modaltitle }) => {
  const handleOutsideClick = () => {
    close();
    document.body.classList.remove('bg-salmon');
  };

  const handleInsideClick = e => {
    e.stopPropagation();
  };

  return shown ? (
    <div
      className="modal-outer"
      onClick={() => {
        handleOutsideClick();
      }}
    >
      <div
        className={`modal-screen ${size}`}
        onClick={e => {
          handleInsideClick(e);
        }}
      >
        <div className="outer-close-button">
          <summary>{modaltitle}</summary>
          <button onClick={close} className="close-modal-button">
            <GrFormClose />
          </button>
        </div>
        <section>{children}</section>
      </div>
    </div>
  ) : null;
};

export default ModalScreen;
