import { GiResize } from 'react-icons/gi';
import { IoSnowOutline, IoWifiOutline } from 'react-icons/io5';
import { IoMdTv, IoMdCheckmark } from 'react-icons/io';
import { MdOutlineMeetingRoom, MdOutlineBalcony, MdOutlineVoiceOverOff, MdOutlineFoodBank } from 'react-icons/md';

export const Facility = Object.freeze({
  ROOM_SIZE: 92,
  MINIBAR: 45,
  BALCONY: 42,
  AIRCONDITIONING: 47,
  TELEVISION: 43,
});

export const Distance = Object.freeze({
  SKI_LIFT: 66,
  CENTER: 1,
  BEACH: 65,
});

export const facilityIcons = {
  0: <IoMdCheckmark />,
  [Facility.ROOM_SIZE]: <GiResize />,
  [Facility.MINIBAR]: <MdOutlineFoodBank />,
  [Facility.BALCONY]: <MdOutlineBalcony />,
  [Facility.AIRCONDITIONING]: <IoSnowOutline />,
  [Facility.TELEVISION]: <IoMdTv />,
};
