import React, { FC, Fragment, useState } from 'react';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';

import { LinkType } from 'data/types';
import { RoomModel } from "features/rooms/room";

import { hotelTabs } from "./hotelTabs";

import RightSection from "ecomponents/layout/RightSection/RightSection";
import RightBookingForm from "./partials/RightBookingForm";
import SectionSliderNewCategories from '_unused-components-delete/components/SectionSliderNewCategories/SectionSliderNewCategories';

import BackgroundSection from 'ecomponents/common/BackgroundSection/BackgroundSection';
import SectionSubscribe from 'ecomponents/partials/SectionSubscribe/SectionSubscribe';
import RoomsComponent from "ecomponents/common/RoomsComponent/RoomsComponent";

import StickyFooterMobileHotel from "ecomponents/common/StickyFooterMobileHotel/StickyFooterMobileHotel";

import TabsLinks from 'ecomponents/ui/TabsLinks/TabsLinks';

import RoomMainInfo from './partials/RoomMainInfo';
import RelatedOffersSection from './partials/RelatedOffersSection';


export interface HotelPageRoomDetailsProps {
  className?: string;
  activeTab?: string;
  tabs?: LinkType[];
  isPreviewMode?: boolean;
}


const HotelPageRoomDetails: FC<HotelPageRoomDetailsProps> = ({
     className = '',
     activeTab = "Rooms",
     isPreviewMode,
     tabs = hotelTabs,
    }) => {
  const translationWords = ['VarHotelDetails', 'VarChild', 'VarChildren', 'VarAdult', 'VarAdults', 'VarOr', 'VarPerPerson', 'VarFromMinPrice'];
  const { data: translations, status: translationsStatus } =
    useFetchTranslationsSelective(translationWords);
  console.log('translations babe: ', translations);

  interface VillageLocal {
    caption: string;
  }
  interface HotelLocal {
    name: string;
    general: string;
    food_dining: string;
    business_facilities: string;
    children: string;
    accomodation: string;
    hotel_facilities_data: RoomModel[]; 
    rooms_data: RoomModel[]; 
    village_data: VillageLocal;
  }

  const hotelId = 819;
  const roomId = 3;

  const hotelFields = [
    'name',
    'general',
    'photos',
    'rooms_data',
    'accomodation',
    { village_data: ['caption'] },
  ];

  const { data: hotel, status: hotelStatus }: { data: HotelLocal; status: string } = useFetchHotelSelective(hotelId, hotelFields);
  let filterHotelRoom = [];
  if (hotel?.rooms_data)  
     filterHotelRoom = Object.values(hotel.rooms_data).filter(room => room.id === roomId);
 // if (hotel?.rooms_data) console.log('hotel room data babe: ', filterHotelRoom?.[0]);

const [tabActive, setTabActive] = useState<string>(activeTab);
const onClickTab = (item: string) => {
    setTabActive(item);
  };











  if (hotelStatus !== 'success') return <></>;

  return (


    <div
      className={`nc-HotelPageRooms  ${className}`}
      data-nc-id='HotelPageRooms'
    >
      <div className='container relative z-10 mt-11'>
        <TabsLinks tabActive={tabActive} tabs={tabs} onClickTab={onClickTab} />
      </div>
      {/* MAIn */}
      <div className='container relative z-10 flex flex-col mt-6 lg:flex-row '>
        {/* CONTENT */}
        <div className='w-full  lg:w-3/5 xl:w-2/3  lg:pr-10'>
        <div className="listingSection__wrap">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold capitalize">{hotel.name} - {filterHotelRoom?.[0].name}</h2>
           <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>


           <RoomMainInfo />

           <h2 className="nc-card-title block font-semibold text-2xl leading-10">
All Rooms:
</h2>
           <div className="grid grid-cols-1 gap-6 md:gap-8 mt-4">

           <RoomsComponent />
</div>
           


           </div>
        </div>

        {/* SIDEBAR */}
        <RightSection >
            <RightBookingForm />
        </RightSection>
      </div>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobileHotel hotelId={819}/>

      {/* OTHER SECTION */}
      <RelatedOffersSection/>
    </div>
    );

      
};

export default HotelPageRoomDetails;
