import React, { FC, ReactNode } from "react";
import imagePng from "images/BH-hero-zima.png";
import HeroSearchForm, {
  SearchTab,
} from "ecomponents/forms/HeroSearchForm/HeroSearchForm";

let imgs = [
    'https://misha.krusharski.com/bhpics/ski-season-banner.jpg',
    'https://misha.krusharski.com/bhpics/ski-packs.jpg',
    'https://misha.krusharski.com/bhpics/baner-ski-hotels.jpg',
    'https://misha.krusharski.com/bhpics/ski-appartments.jpg',
    'https://misha.krusharski.com/bhpics/summer-banner.jpg',
    'https://misha.krusharski.com/bhpics/yastrebets.jpg',
    'https://misha.krusharski.com/bhpics/pamporovo_2.jpg',
];
export interface SectionHeroWithSearchProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Hotels" | "Ski Packages" | "Transfers" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroWithSearch: FC<SectionHeroWithSearchProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
}) => {
  return (
    <>
    
    <div
      className={`nc-SectionHeroWithSearch flex flex-col relative ${className} `}
      data-nc-id="SectionHeroWithSearch"
    >
      <div className="flex flex-col lg:flex-row pic-and-header">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-4   xl:pr-14 lg:mr-10 xl:mr-0 mt-4 mb-6">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-5xl leading-[110%] mt-12">
            Pamporovo
          </h2>
          <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            
          
            {listingType ? (
              listingType
            ) : (
              <>
                <i className="text-2xl las la-home"></i>
                <span className="ml-2.5">345 hotels and appartments</span>
              </>
            )}
          </div>
        </div>
        <div className="flex-grow">
        <div className=" group relative">
        <a className="block w-full h-0 pt-[310px] rounded-xl overflow-hidden" href="/ncmaz/single/this-is-single-slug">
            <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage"><img src={imgs[6]} className="object-cover w-full h-full rounded-3xl" alt=""/></div>
            <div className="flex absolute left-2 lg:-left-6 top-[90%]  md:top-[90%] lg:top-60 xl:top-52 z-20 w-[300px]">
                <span className="rounded-full flex  items-center justify-center text-md bg-red-500 text-red-50  w-14 h-14 z-20" style={{marginTop: '-2px'}}>
                    -<b className=" text-xl  font-bold">20</b>%
                    </span>
                    <span className="bg-[#111d4d]  px-6 pl-10 -ml-6 py-2 rounded-3xl text-white h-10 mt-[6px] flex items-center"><b>Early Booking </b><i className="ml-3 las la-arrow-right text-xl mt-1"></i></span>
                 </div>

        </a> 
        </div>
          
          <br /><br />
        </div>
      </div>

      <div className="flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div>
    </div>
    </>
  );
};

export default SectionHeroWithSearch;
