import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageSlider from 'ecomponents/ui/ImageSlider/ImageSlider';
import { DEMO_HOTEL_LISTINGS } from 'data/listings';
import { ExperiencesDataType } from 'data/types';
import { DEMO_EXPERIENCES_LISTINGS } from 'data/listings';
import StarRating from 'ecomponents/ui/StarRating/StarRating';
import { FaInfoCircle } from 'react-icons/fa';
import BtnLikeIcon from 'ecomponents/ui/BtnLikeIcon/BtnLikeIcon';
import SaleOffBadge from 'ecomponents/ui/SaleOffBadge/SaleOffBadge';
import ModalScreen from 'ecomponents/common/ModalScreen/ModalScreen';
import RoomMainInfo from 'ecomponents/pages/HotelPage/partials/RoomMainInfo';
import './HotelCardHorizontal.scss';
import { HotelListingDataType } from 'data/dataTypes/HotelListingDataType';

export interface HotelCardHoriznotalProps {
  className?: string;
  hotelData?: HotelListingDataType;
  data?: ExperiencesDataType;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const HotelCardHoriznotal: FC<HotelCardHoriznotalProps> = ({ className = '', hotelData }) => {
  const {
    id,
    title,
    locationTitle,
    mealsIncluded,
    distances,
    galleryImgs,
    accent,
    description,
    location,
    displayPrice,
    displayNodiscountPrice,
    displayCurrency,
    displayPricePer,
    recommended,
    rating,
    ratingNumberOfVotes,
    map,
    address,
    listingCategory,
    url,
  } = hotelData;

  const [like, setLike] = useState(false);

  const [modalShown1, toggleModal1] = useState<boolean>(false);
  const [modalShown2, toggleModal2] = useState<boolean>(false);

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-72 ">
        <ImageSlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`HotelCardHoriznotal_${id}`}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {accent && <SaleOffBadge className="absolute left-3 top-3" caption={accent} />}
      </div>
    );
  };

  const renderHotelInfo = () => {
    return (
      <div className="flex mt-3 mb-4 items-center">
        <div className="flex flex-col  w-full sm:w-4/6 ">
          <span className="flex justify-between text-md text-neutral-900 dark:text-neutral-400 flex-wrap">
            <span>
              <i className="las la-map-marker-alt text-xl"></i> {locationTitle}
            </span>

            <span>
              <i className="las la-map-marker-alt text-xl"></i> {mealsIncluded}
            </span>

            <span className=" flex sm:hidden  items-center">
              <span className="text-sm text-right">
                from{' '}
                <span
                  className="hidden sm:inline"
                  style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
                >
                  <span>
                    {' '}
                    {displayNodiscountPrice} {displayCurrency}{' '}
                  </span>
                </span>
              </span>
              <span className="text-xl font-semibold text-red-600">
                <span>
                  &nbsp;{displayPrice} {displayCurrency}{' '}
                </span>
              </span>
              <span className="text-xs sm:text-sm font-normal mt-0.5">&nbsp;/ night</span>
            </span>
          </span>
          <Link
            to={url}
            className="mt-2 text-md text-neutral-900 hotel-text leading-5"
            dangerouslySetInnerHTML={{
              __html: description + location,
            }}
          />
        </div>
        <div className=" hidden sm:flex  items-end flex-col sm:w-2/6">
          <span className="text-sm text-right leading-4">
            <Link to={url}>
              from{' '}
              <span
                className="hidden sm:inline"
                style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
              >
                <span>
                  {' '}
                  {displayNodiscountPrice} {displayCurrency}{' '}
                </span>
              </span>
            </Link>
          </span>
          <span className="text-xl font-semibold text-red-600 leading-4">
            <span>
              <Link to={url}>
                {' '}
                {displayPrice} {displayCurrency}{' '}
              </Link>
            </span>
          </span>
          <span className="text-xs sm:text-sm font-normal mt-0.5 leading-4">
            {' '}
            <Link to={url}> {displayPricePer}</Link>
          </span>
          <span className="text-sm py-1 px-3 text-white bg-primary-6000 font-semibold cursor-pointer rounded-full flex items-center mt-3">
            {' '}
            <Link to={url}>Details</Link>
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow  p-3 sm:p-4 flex flex-col ">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span
              dangerouslySetInnerHTML={{
                __html: distances?.join(' &bull; '),
              }}
            />
          </div>
          <div className="flex items-center space-x-2">
            {recommended === 2 ? (
              <span className="inline-flex px-2.5 py-1 rounded-full font-medium text-sm relative text-green-800 bg-green-100  relative">
                TOP
                <i className="las la-thumbs-up !text-lg !leading-5" />
              </span>
            ) : (
              ''
            )}
            <h2 className="text-2xl font-medium capitalize">
              <Link to={url} className="line-clamp-1">
                {title}
              </Link>
            </h2>
          </div>
        </div>

        {renderHotelInfo()}

        <div className="flex justify-between items-end">
          <StarRating reviewCount={ratingNumberOfVotes} point={rating} />

          <span
            className="text-sm text-neutral-500 dark:text-neutral-400 font-normal cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {'read more >'}
          </span>

          <span className="inline-block sm:hidden text-sm py-1 px-3 text-white bg-primary-6000 font-semibold cursor-pointer rounded-full flex items-center mt-3">
            {' '}
            <Link to={url}>Detailss</Link>
          </span>
        </div>
      </div>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="HotelCardHoriznotal">
        <div className="bg-neutral-100  px-4 py-2 flex flex-col sm:flex-row ">
          <div className="flex flex-col basis-full items-center mb-2 sm:mb-0 sm:basis-4/12 sm:border-r sm:border-neutral-300 pr-2  items-center sm:items-start justify-start ">
            <div className="flex items-center  ">
              <div className="w-[20px]">
                <FaInfoCircle
                  className="inline cursor-pointer"
                  onClick={e => {
                    toggleModal1(!modalShown1);
                    document.body.classList.add('bg-salmon');
                  }}
                />
              </div>
              &nbsp;
              <span className="text-lg !leading-4">Double Room</span>&nbsp;&nbsp;
              <i className="las la-image  "></i>&nbsp;32
            </div>
            <div>
              <div className="flex items-center justify-start">
                <span className="text-xs py-0.5 px-3 bg-[#111d4d] text-white rounded-full cursor-pointer mt-1">
                  free cancelation
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-1 sm:mb-0 flex-col xs:flex-row sm:flex-col basis-3/12 sm:border-r sm:border-neutral-300">
            <div className="flex items-center justify-center text-center leading-4 px-2">
              breackfast and dinner&nbsp;
            </div>
            <div className="flex items-center justify-center">
              <span className="text-xs py-0.5 px-2 text-white bg-primary-6000  cursor-pointer rounded-full flex items-center mt-1 xs:mt-0 sm:mt-1  ">
                <FaInfoCircle className="inline text-sm" />
                &nbsp;&nbsp;free extras{' '}
              </span>
            </div>
          </div>
          <div className="flex sm:flex-col basis-2/12 sm:border-r sm:border-neutral-300 items-center justify-center">
            <span className="inline" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
              {' '}
              134.95lv{' '}
            </span>
            <span className="text-xl font-semibold text-red-600">&nbsp;108lv</span>
          </div>

          <div className="flex justify-center items-center basis-full sm:basis-3/12">
            <a
              href="#"
              className="my-2 bg-red-700 px-3 py-2 rounded-lg font-semibold text-white text-center leading-4 uppercase"
            >
              book now
            </a>
          </div>
        </div>
        <div className="bg-neutral-100  px-4 py-2 flex flex-col sm:flex-row ">
          <div className="flex flex-col basis-full items-center mb-2 sm:mb-0 sm:basis-4/12 sm:border-r sm:border-neutral-300 pr-2  items-center sm:items-start justify-start ">
            <div className="flex items-center  ">
              <div className="w-[20px]">
                <FaInfoCircle
                  className="inline cursor-pointer"
                  onClick={e => {
                    toggleModal1(!modalShown1);
                    document.body.classList.add('bg-salmon');
                  }}
                />
              </div>
              &nbsp;
              <span className="text-lg !leading-4">Double Room</span>&nbsp;&nbsp;
              <i className="las la-image  "></i>&nbsp;32
            </div>
            <div>
              <div className="flex items-center justify-start">
                <span className="text-xs py-0.5 px-3 bg-[#111d4d] text-white rounded-full cursor-pointer mt-1">
                  free cancelation
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-1 sm:mb-0 flex-col xs:flex-row sm:flex-col basis-3/12 sm:border-r sm:border-neutral-300">
            <div className="flex items-center justify-center text-center leading-4 px-2">
              breackfast and dinner&nbsp;
            </div>
            <div className="flex items-center justify-center">
              <span className="text-xs py-0.5 px-2 text-white bg-primary-6000  cursor-pointer rounded-full flex items-center mt-1 xs:mt-0 sm:mt-1  ">
                <FaInfoCircle className="inline text-sm" />
                &nbsp;&nbsp;free extras{' '}
              </span>
            </div>
          </div>
          <div className="flex sm:flex-col basis-2/12 sm:border-r sm:border-neutral-300 items-center justify-center">
            <span className="inline" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
              {' '}
              134.95lv{' '}
            </span>
            <span className="text-xl font-semibold text-red-600">&nbsp;108lv</span>
          </div>

          <div className="flex justify-center items-center basis-full sm:basis-3/12">
            <a
              href="#"
              className="my-2 bg-red-700 px-3 py-2 rounded-lg font-semibold text-white text-center leading-4 uppercase"
            >
              book now
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-HotelCardHoriznotal group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
        data-nc-id="HotelCardHoriznotal"
      >
        <div className="flex flex-col sm:flex-row sm:items-center ">
          {renderSliderGallery()}
          <div className="flex flex-col sm:flex-row sm:items-center w-full sm:w-[calc(100%_-_18rem)] ">
            {renderContent()}
          </div>
        </div>
        {renderDetail()}
      </div>

      <ModalScreen
        shown={modalShown1}
        close={() => {
          toggleModal1(false);
          document.body.classList.remove('bg-salmon');
        }}
        modaltitle="Double Room"
      >
        <RoomMainInfo />
      </ModalScreen>
    </>
  );
};

export default HotelCardHoriznotal;
