import React, { FC, useEffect, useState } from "react";
import Nav from "ecomponents/layout/Nav/Nav";
import NavItem from "ecomponents/layout/NavItem/NavItem";

export interface TabsFilterProps {
  tabActive: string;
  tabs: string[];
  onClickTab: (item: string) => void;
}

const TabsFilter: FC<TabsFilterProps> = ({
  tabActive,
  tabs,
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  console.log('TabsFilter tabs: ', tabs);
  

  return (
        <Nav
          className="sm:space-x-2"
          containerClassName=""
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
  );
};

export default TabsFilter;
