export const extractFacility = (facilities, extractFacilityId) => {
  if (!(facilities && extractFacilityId)) return null;
  return Object.values(facilities).reduce((facilityFound, facility) => {
    if (facility.id === extractFacilityId)
      facilityFound = {
        id: facility.id,
        caption: facility.caption,
        details: facility.details,
      };
    return facilityFound;
  }, null);
};
