import React, { FC } from "react";
import Heading from "ecomponents/ui/Heading/Heading";
import ButtonSecondary from "ecomponents/ui/Button/ButtonSecondary";
import { ReactNode } from "react";
import TabsFilter from "../../../ecomponents/ui/TabsFilter/TabsFilter";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <span className="hidden sm:block flex-shrink-0 self-end -mt-20 ">
          <ButtonSecondary className="!leading-none">
            <span>View all</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
      <div className="flex flex-col items-center justify-between mt-5">
      
      <TabsFilter 
        tabActive={tabActive}
        tabs={tabs}
        onClickTab={onClickTab}
      />
      </div>
    </div>
  );
};

export default HeaderFilter;
