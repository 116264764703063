import  { FC, } from 'react';
import useFetchHotelSelective from 'features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from 'features/translations/hooks/useFetchTranslationsSelective';
import ButtonPrimary from 'ecomponents/ui/Button/ButtonPrimary';
import { CurrencySign } from 'const';


export interface StickyFooterMobileHotelProps {
    className?: string;
    hotelId?: number;
    isPreviewMode?: boolean;
  }
  
const StickyFooterMobileHotel: FC<StickyFooterMobileHotelProps> = ({ className = "", hotelId, isPreviewMode }) => {
  
      const translationWords = [
          'VarFromMinPrice',
          'VarPerPerson',
          'VarPerNight',
      ];
      const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
      const hotelFields = [
  
          'min_price_data',
        ];
        const { data: hotel, status: hotelStatus } = useFetchHotelSelective(hotelId, hotelFields);
        if (hotel?.min_price_data) console.log('hotel min price: ', hotel?.min_price_data);
  
   return (
        <>
        {!isPreviewMode && (
          <div className="fixed inset-x-0 bottom-0 z-20 block py-4 bg-white border-t lg:hidden text-neutral-900 border-neutral-200">
            <div className="container flex items-center justify-between">
              <span className="text-2xl font-semibold leading-4">
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">{translations?.VarFromMinPrice}{' '}</span>
                {hotel?.min_price_data?.display_price}{CurrencySign[hotel?.min_price_data?.display_currency]}
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  /{translations?.VarPerPerson} <i className="not-italic hidden sm:inline-block">{translations?.VarPerNight}</i>
                </span>
              </span>
  
              <ButtonPrimary href="#">Reserve</ButtonPrimary>
            </div>
          </div>
        )}
        </>
  )
  };
  
  export default StickyFooterMobileHotel;