import React, { FC } from "react";
import Logo from "ecomponents/layout/Logo/Logo";
import Navigation from "ecomponents/layout/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "ecomponents/ui/Button/ButtonPrimary";
import MenuBar from "ecomponents/layout/MenuBar/MenuBar";
import LangDropdown from "../../../_unused-components-delete/components/Header/LangDropdown";
import SwitchDarkMode from "_unused-components-delete/shared/SwitchDarkMode/SwitchDarkMode";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  return (
    <div
      className={`nc-MainNav1 relative px-3  md:px-5 md:py-5 lg:px-7   ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container  relative flex justify-between items-center space-x-2 xl:space-x-4 ">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-5 2xl:space-x-10">
          <Logo />
          <div className="xl:px-3 " />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1 relative" >
        <SearchDropdown />
          <div className="flex items-center ">
          <div className="hidden sm:block ">
          <LangDropdown /></div>
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
